.tos-heading{
    background-color : var(--primary-color-blue__dark);
    color:var(--primary-color-white);
    position: relative;
}
.tos-heading svg{
    height: 100%;
    position: absolute;
    right:7rem;
    top:0rem;
    opacity: 0.2;
}
.terms-container{
    padding-top: 2rem;
}

ul.information-ul{
    list-style:disc !important;
    
}





.terms-heading{
    font-weight: 800px;
    
}

.unordered-list{
    padding-top: 1rem;
    list-style: disc !important;
}
.ordered-list{
    padding-top: 1rem;
    padding-left: 0rem;
}
.list-items{
    display: list-item;
    padding-bottom: 1rem;
}



#singleBlogContainer{
    background-color: white;
    height: fit-content;
    max-height: fit-content;
    padding-top: 0;
    display: grid;
    grid-template-columns: 1fr;
    max-width: 100vw;
    word-break:break-word;
    margin-right: auto;
    margin-left: auto;
    padding: 0;
    
}

#singleBlogContainer p,#singleBlogContainer ol,#singleBlogContainer ul,#singleBlogContainer li {
    font-size: 20px !important;
    position: relative;
    letter-spacing: 0!important;
    margin-bottom: 0rem !important;
}
#singleBlogContainer a{
    color: var(--primary-color-blue);
    
    font-weight: 600;
    padding: 3px;
    border-bottom: 1.5px solid var(--primary-color-green);
}

.author_name{
    font-weight: 600;
    padding-left: 0.5rem;
}
#singleBlogContainer ul>li,#singleBlogContainer ol>li{
    padding-bottom: 0.8rem!important;
    padding-left: 0.4rem;
    position: relative;
    display: block;
}

#singleBlogContainer ul>li::before , #singleBlogContainer ol>li::before{
    content: "\ea39";
    font-family: 'boxicons'!important;
    position: absolute;
    top: 0;
    left: -0.8rem;
    font-size: 8px;
    color: var(--primary-color-blue);
}
.blogCover{
    display: grid;
    grid-template-columns: 1fr 1fr;
    background-size: cover;
    background-position: center;
    padding: 4rem 0rem;
    padding-bottom: 0;
    width:100%;
    height: 70vh;
    position: relative;
}
.blog_desc{
    background-color: var(--secondary-color-blue__dark);
    position: relative;
    padding: 0rem 0rem;
    z-index: 300;
    order: 4;
    padding-left: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;

}
.blog-type{
    background-color: transparent;
    font-weight: 600;
   
    padding: 0px;
    margin: 0px;
    width: fit-content;
    font-size: smaller;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    border-bottom: none!important;
}
.blog-type p{
    margin-bottom: 0px;
    margin-right: 0.5rem;
   
    padding: 1px;
    text-transform: uppercase;
    font-size: small;
    color: var(--primary-color-green);
    font-weight: 600;

}
.blog_title{
    color: white;
    position: relative;
    z-index: 300;
    padding: 0rem 0rem;
    background-color: transparent;
    font-family: 'Manrope';
    font-weight: 800;
    text-align: start;
    
}
.blog_date{
    color: white;
    display: flex;
    align-items: center;
    z-index: 300;
    position: relative;
    
    font-weight: 100;
    
    padding-left: 0rem;
    padding-bottom: 1rem;
}
.blog_subtitle{
    font-size: 2rem !important;
    padding-left: 1rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    text-align: start;
    font-style: italic;
    font-weight: 500!important;
    opacity: 0.5;
    line-height: 3.4rem;
}

.blog-center{
    max-width: 50vw;
    margin-right: auto;
    margin-left: auto;
}
.section_hrefs{
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    margin-top: 0rem;
    text-transform: capitalize;
    font-weight: 600;
    padding:1rem 1rem;
}
.hrefs:hover{
    text-decoration: none;
}

.blog_content{
    display: flex;
    flex-direction: column;
    width: 100%;
}
.sectionImg{
    width: 100%;
    object-fit: contain;
    order: 30;
}
.sectionHead{
    font-weight: 600;
    padding: 1rem;
    padding-bottom: 0.5rem;
    font-size: 1.4rem;

    
}
.section_desc{
    padding: 1rem;
    padding-top: 0;
    font-size: 20px !important;
    font-weight: 300;

    line-height: 1.7rem;
    color: black;
    width:100% !important;
  
    line-height: 31px;
}
.blog_share{
    color: white;
    display: flex;
    gap: 1rem;
    align-items: center;
    padding-top: 3rem;
}
.blog_share a{
    border-bottom: none!important;
}
.blog-share p{
    font-size: smaller!important;
}
.blog_share a i{
    color: white;
    font-size: 1.5rem;
}
#singleBlogContainer ul,#singleBlogContainer ol{
    padding-left: 1rem;
    padding-top: 0px;
}
.blogCover .blogImageContainer{
    height: 100%;
}
.blogCover img{
    height: calc(70vh - 4rem);
    width: 100%;
    object-fit: cover;

   

}
.twitter-share-button{
    background-color: none;
}
.commentsContainer {
    border-top: 1px solid lightgray;
    padding: 1rem;
    width:100%;
    margin-top: 50px;
}
.allComments ul{
    list-style: none;
    padding : 0px;
    margin: 20px 0px;

}
.allComments ul .commentCard {
    border-bottom: 1px solid rgb(235, 235, 235);
    padding: 0.5rem;
    padding-bottom: 1rem;
    flex-direction: column;
    justify-content: start;
    align-items: flex-start;
}
.commentor_data {
    display: flex;
    width: 100%;
    justify-content: space-between;
}
.comment_author {
    font-weight: 600;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 0.6rem;
    color: #3c3c56;
    font-size: 19px;
    
}

.comment_content{
    font-weight: 300;
    font-size: 14px;
}
.alert_fail {
    padding: 0.5rem;
    border-left: 5px solid #adb;
    background: #e9fff1;
    box-shadow: 1px 1px 6px lightgrey;
    border-radius: 6px;
    color: #3a754e;
    display: none;
}
.addComment {
    margin-top: 2rem;
    background: #e6e6e6;
    padding: 2rem 1rem;
    position: relative;
    overflow: hidden;
    border-radius: 5px;
}
.addComment h4 {
    font-size: 1.1rem;
    position: relative;
    z-index: 20;
   
    text-transform: uppercase;
    color: var(--primary-color-blue__dark);
    font-weight: 700;
    padding-left: 0.7rem;
    margin-bottom: 1rem;
}
.addComment h4::before{
    content: '';
    height:100%;
    width:0.5rem;
    background-color:var(--primary-color-green);
    position: absolute;
    top:0;
    left: 0;
}
.addComment form {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 1rem;
}
.addComment form input {
    width: 100%;
    height: 3rem;
    grid-column: 1 / 3;
    outline: none;
    border: none;
    position: relative;
    z-index: 20;
    padding-left: 0.5rem;
    border-radius: 5px;
   
}
.addComment form textarea{
    grid-column: 1 / 3;
    outline: none;
    border-radius: 5px;
    height: 8rem;
    position: relative;
    z-index: 20;
    border: none;
    overflow: auto;
    resize: vertical;
    padding-left: 0.5rem;
    
}
.addComment .actionBtn {
    font-size: smaller;
    text-transform: uppercase;
  
    justify-content: center;
    height: 3rem;
    width: fit-content;
    padding: 1rem;
}
/* .addComment::after {
    clip-path: circle(50% at 50% 50%);
    content: ' ';
    width: 8rem;
    height: 8rem;
    background: #e5b595;
    position: absolute;
    top: -4rem;
    left: -3rem;
    z-index: 0;
} */
.allComments ul{
    padding-left: 0 !important;
}
.commentCard::before{
    content:''!important;
}
.section_hrefs a{
    color: var(--secondary-color-blue__dark);
    /* font-weight: 800; */
    border-bottom: none;
    position: relative;
    width: -moz-fit-content;
    width: fit-content;
    padding-right: 8px;
}

/* references */
#references a{
    color: var(--secondary-color-blue__dark);
    border-bottom: none;
    font-weight: 500;
}
@media(max-width:570px){

    #singleBlogContainer{
        padding-left: 0;
        padding-right: 0;
        grid-template-columns: 100vw;
        max-width: unset;
        margin-right: unset;
        margin-left: unset;
    }
    #singleBlogContainer p,#singleBlogContainer ol,#singleBlogContainer ul,#singleBlogContainer li {
        font-size: 17px !important;
    }
    .sectionImg img{
        height:30rem;
       
        margin: 1rem;
    }
    .section_desc{
        
        font-size: 17px !important;
        line-height: 27px;
    }
    
    .blog-center{
        max-width: 100vw;
    }
    .blogCover{
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 40vh auto;
        height: fit-content;
        
    }
    .blog_desc{
        padding: 1rem;
    }
    .blogCover .blogImageContainer,.blogCover img{
        height: 100%;
        object-fit: cover;
    }
}
.career-page-main-container{
    min-height: 100vh;
}
.career-page-main-container .header-container {
    background-color: var(--primary-color-blue__dark);
    color: white;
    height: 40vh;
    display: flex;
    flex-direction: column;
    justify-content: end;
    padding-left: var(--padding-lr__desktop);
    
}
.career-category{
    padding: 3rem var(--padding-lr__desktop);
    
}

.career-roles__one__heading{
    font-size: 2rem;
    font-family: var(--primary-typeface);
    font-weight: 600;

}
.about-neodocs-culture{
    width: 70%;
    padding: var(--padding-tb__desktop) var(--padding-lr__desktop);
}
.career-category__roles{
    
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 3rem;
}
.career-roles__one__categories{
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    text-transform: capitalize;
    padding-bottom: 1rem;
}
.career-roles__one__categories div{
    background-color: rgb(219, 219, 219);
    border-radius: 25px;
    padding: 0rem 1rem;
    font-size: smaller;

}
.career-roles__one{
    color: var(--primary-color-blue__dark);
}
.career-category__heading{
    text-transform: uppercase;
    font-size: 1rem;
    font-weight: 600;
    color: #9e9e9e;
}
@media screen and (max-width:900px) {
    .career-page-main-container .header-container {
        height: 25vh;
    }
    .career-category__roles{
        display: grid;
        grid-template-columns: 1fr;
        gap: 1rem;
    }
    .about-neodocs-culture{
        width: 100%;
       
    }
}
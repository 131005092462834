.panelInfoDetails{
    background-color: white;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    width:100%;
    border-bottom-right-radius: 0px;
    border-top-right-radius: 0px;
    position: relative;
    
}
.panelInfoDetails .wrap::after{
    content: '';
    position: absolute;
    top:0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(255,255,255,0.9);
}
.panelInfoDetails ul{
    z-index: 3;
}
.panelInfoDetails .wrap>*{
    position: relative;
    z-index: 3;
}

.panelWrapper{
    display: flex;
    margin-top: 1rem;
}
.panelContainer{
    margin:1rem;
}

.panelWrapper > div{
    width:100%;
}
.panelElement:last-of-type{
    margin-bottom: 6rem;
    
}
/* .panelWrapper_risk::after{
    content: '';
    position: relative;
    width:7px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;

    background-color:#F65E5B ;
} */
.panelWrapper_Safe::after{
    content: '';
    position: relative;
    width:7px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;

    background-color: #23E5AF;
}
.wrap{
    padding:1.2rem;
}
.panelHead{
    display: flex;
    font-family: 'Raleway','sans-serif';
    justify-content: space-between;

}
.bio_read_more{
    padding-top: 1rem;
    text-transform: uppercase;
    font-weight: 900;
    color: #000646;
    margin-left: auto;
    display: flex;
    justify-content: flex-end;
}
.biomarker-title{
    font-weight:700;
    font-size: large;
    color:#212121;
    font-size: 21px;
}
.panelIcon{
    width:3rem;
    height: 3rem;
    margin-right: 1rem;
}
.date{
    color:#888888;
    margin-top:2px;
}
.note{
    text-transform:uppercase;
    font-weight: 600;
    margin-top:4px;
    display: flex;
    align-items: center;
}


.small{
    font-size: small;
}


.note_borderline{
    text-transform: uppercase;
    width: fit-content;
    padding: 1px 6px;
    background-color: #f8dca9;
 
    
}
.note_borderline::before{
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background-color: #FFB429;
    content: "";
    margin-right:4px;
}
.note_risk{
    background: #f6d0d0;
    width: fit-content;
    padding: 1px 6px;
}
.note_risk::before{
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background-color: #f96b68;
    content: "";
    margin-right:4px;
}
.note_safe{
    background: #c6edc6;
    width: fit-content;
    padding: 1px 6px;
}
.note_safe::before{
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background-color: #23E5AF;
    content: "";
    margin-right:4px;
  
}
.getRecommendations{
  
    color:#0457b0;
    font-weight: bold;
    
    
}
.panelDetailCont{
    display: flex;
    align-items: center;
}
.panelScore{
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.panelScore>p{
    font-size: x-small;
}
.panelScore>h3{
    font-weight: 800;
    margin-bottom: 0px;
}
.qualitativeScore{
    border-radius: 50%;
    
}

.score_risk{
    color:#FF5F45
}
.score_borderline{
    color:#FFB429
}
.score_good{
    color:rgb(35, 229, 175)
}
.summary{
    margin-top:1rem;
    margin-bottom: 0.5rem;
}

.summaryHead{
    
    font-size: 13px;
    color: #000646;
    font-weight: 800!important;
}
.summaryHead span{
    padding-left: 0rem;
}
.panelSummary{
    font-size:smaller;
    color:#333333;
    font-weight: 400;
}
.markerDetail{
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-top: 2px dashed #d3d3d3cf;
}
.markerDetail:first-child{
    border-top:none;
    padding-top: 0.5rem;
}
.markerDetail .reference-range{
    
    padding-top: 1rem;
    color:#000646;
    font-weight: 900;
}

/* TABS */
.nav-tabs .nav-link:hover {
    border-color: white;
}
a:hover{
    color:#888888;
}
.nav-tabs{
    display: flex;
   
}
.nav-link{
    font-size: 14px;
    border:none;
    padding:0.2rem;
    color:#888888;
    font-weight: 400;
    width:32%;
    text-align: center;
    
}
.nav-tabs{
    border-bottom: 4px solid rgb(230, 240, 250);
    
    justify-content: space-between;
    padding-top: 0;
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
    
}
.nav-item button{
    margin-right: 0;
    width: 100%;
    background-color: white;
    padding: 0.5rem;
    text-transform: uppercase;
    font-weight: 900;
}
.nav-tabs .nav-link.active{
    border-color: white;
    border-bottom: 4px solid rgb(71, 82, 141);
    background: white;
    
}


/* historic trends */
.trendsContainer{
    margin-top:1rem;
    font-family: 'Raleway','sans-serif';
}

.chartContainer{
    border:1px solid #d6d4df;
    border-radius: 10px;
    margin-top:1rem;
}
/* all recms */
.getAllRecms{
    position: fixed;
    bottom: 6px;
    right: 0px;
    left: 0px;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 2rem;
    width: 15rem;
    background-color: rgb(71, 82, 141);
    border: 1px solid rgb(71, 82, 141);
    padding: 1rem;
    color: white;
    font-weight: 900;
    text-align: center;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    z-index: 999;
    text-transform: capitalize;
    box-shadow: 2px 2px 10px grey;
}
/* marker */
.panelBarValueWrapper{
    display: grid;
    grid-template-columns: 25% 66%;
    grid-column-gap: 1rem;
    column-gap: 8%;
    padding-top: 1rem
}
.panelBarValueWrapper .summaryHead{
    font-size: small;
}
.panelBarValueWrapper span.biomarker-value{
    font-size: 2rem!important;
    padding-left: 0;
    font-weight: 900;
}
/* summary */
.parameter-summary-container{
padding: 0.5rem;
background-color: #f5f5fa;
display: grid;

align-items: center;

}
.parameters-summary .parameters-container{
    display: flex;
    flex-wrap: wrap;
    column-gap: 1rem;
    font-weight: 500;
    font-size: small;
    width: unset;
    padding-top: unset;
    row-gap: 0;
}

.parameters-summary .parameters-container p{
    position: relative;
    display: flex;
    align-items: center;
    font-size: small!important;
    
}
.abnormal-parameters-summary{
    border-bottom: 1px solid rgb(208, 208, 247);
    padding-bottom: 0.5rem;
}
.normal-parameters-summary{
    padding-top: 0.5rem;
}
.abnormal-parameters-summary .parameters-container p::before{
    content:'';
    height:8px;
    width: 8px;
    border-radius: 50%;
    background-color: #f96b68;
    margin-right: 0.2rem;
}
.normal-parameters-summary .parameters-container p::before{
    content:'';
    height:8px;
    width: 8px;
    border-radius: 50%;
    background-color: #23E5AF;
    margin-right: 0.2rem;
}
.normal-parameters-summary{
    padding-bottom: 1rem;
}
.biomarker-value_0{
    color: #23E5AF;
}
.biomarker-value_1,.biomarker-value_-1{
    color: #fec760;
}
.biomarker-value_2,.biomarker-value_-2{
    color: #f65e5b;
}
@media screen and (max-width:320px) {
    .getAllRecms{
        margin-bottom: 1rem;
    }
    .PatientInfo .greetingMsgBox{
        padding: 2rem 1rem;
    }
}
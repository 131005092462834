.partners{
    min-height: 100vh;
    
    padding: 7rem;
    padding-top: 8rem;
    background-color: var(--primaryColor);
}
.one-partner{
    border-bottom: 1px solid rgb(243, 243, 243);
    padding: 1rem;
    padding-left: 0;
    cursor: pointer;
}
.partner-type{
    font-size: 24px;
    font-weight: 500;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 0.5rem;
}
.partner-desc{
    letter-spacing: 0.5px;
}
.dropdown-content.hide{
    display: none;
}
@media screen and (max-width:900px) {
    .partners{
        padding: 1rem;
        padding-top: 5rem;
    }
}
#testimonials{
    min-height: 85vh;
    background-color: var(--primary-color-blue);
    color: var(--primary-color-white);
}
.testimonial-card-scroller{
    display: grid;
    grid-auto-flow: column;
    gap: 1rem;
    overflow-x: auto;
    width: 100%;
}
.testimonial-card{
    width: 20rem;
    background-color: var(--primary-color-blue__light);
    padding: 1rem;
    border-radius: 10px;
    
    display: grid;
    
}
.testimonial-quote{
    font-style: italic;
    font-size: small;
    font-weight: 300;
}
.testimonial-quote i{
    font-size: 2rem;
    filter: opacity(0.4);
}
.testimonial-author-details{
    padding-top: 0.5rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    align-self: end;
    
}
.testimonial-autor-img{
    height: 2rem;
    width: 2rem;
    border-radius: 50%;
    background-color: gray;
}
.testimonial-autor-img img{
    height: 2rem;
    width: 2rem;
    border-radius: 50%;
    object-fit: cover;
}
.testimonial-autor-designation{
    color:var(--secondary-color-purple);
    font-weight: 800;
    font-size: x-small;
}
.community-illustration-wrapper{
    height: 300px;
    position: relative;
    margin-top: 2rem;
    margin-bottom: 2rem;
}
.community-illustration__person{
    height: 4rem;
    width: 4rem;
    border-radius: 50%;
    border: 3px solid var(--primary-color-blue);
}
.community-illustration__person.you_div{
 
    border: 3px dotted var(--primary-color-green);
    top: 35%;
    left: 50%;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 300;
    background: var(--primary-color-blue);
    z-index: 3;
    
}
.community-illustration-wrapper img{
    position: absolute;
}
.community-illustration-wrapper img.wave-patten{
    left:-12rem;
    top:0;
    bottom: 0;
    
    
}
@media screen and (max-width:450px) {
    .testimonial-card{
        width: 16.5rem;
    }
   
    .community-illustration__person.additional_pc{
        display: none;
    }
}
.category-title-container{
    padding: 7rem;
    padding-top:7rem;
    padding-bottom: 0;
    position: relative;
    background-size: cover ;
    background-position: center;
    color:white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: var(--primary-color-blue__dark);
    min-height: 50vh;
}
.header-quote{
    font-style: italic;
    opacity: 1;
    
    color: rgb(180, 178, 178);
    text-align: start;
    width: 100%;
    line-height: 5rem;
    font-weight: 700;
    letter-spacing: 0.5px;
    padding-bottom: 1rem;
   
}
.header-quote i{
    opacity: 0.5;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    position: relative;
    top:-0.5rem;
    color:var(--primary-color-green)
}


.category-title-container *{
    position:relative;
    z-index: 4;
}
.all-category-blogs{
    display:grid;
  
    padding-top: 2rem;
    grid-gap: 1.5rem;
}
.all-category-blogs > a:first-child{
    grid-column: 1 / 3;
    grid-row: 1 / 3;
    height: 100%;
}
.category-blogs-page{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1rem;
}
.category-blog-carousel{
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 1rem;
}
.category-blog-carousel .carousel__pages span{
    color:gray;
    padding-left: 0.4rem;
}
.category-blog-carousel .carousel__pages span.active{
    color: var(--primary-color-blue);
}
.carousel__btn{
    height: 2rem;
    width: 2rem;
    border-radius: 50%;
    background-color: var(--primary-color-blue);
    color:var(--primary-color-green);
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
@media screen and (max-width:900px) {
    .category-title-container{
        padding: 2rem;
        min-height: 60vh;
        padding-top:4rem;
        padding-bottom:0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .all-category-blogs {
        display: grid;
        grid-template-columns: 1fr;
       
    }
    .header-quote{
        font-style: italic;
        margin-top: auto;
        opacity: 1;
       
        line-height: 3.5rem;
        color: rgb(186 186 186);
        text-align:start;
        width: 100%;
        
    }
    .category-blogs-page{
        grid-template-columns: 1fr;
    }
}


.blog-card .blog-image{
    overflow: hidden;
}
.blog-card{
    gap:0.4rem
}
.blog-category{
    text-transform: uppercase;
    font-size: small;
    
    color: var(--secondary-color-blue__dark);
    width: -moz-fit-content;
    width: fit-content;
    font-weight: 700;
    padding-right: 0.3rem;
}
.blog-date{
    font-size:small;
    text-transform:uppercase;
    color:#727070;
    font-weight: 500;
}

/*verticle blog card */
.vertical-blog-card{
    display: grid;
    color:black;
    padding-top:0.5rem;
    position: relative;
    height: 100%;

}
.vertical-blog-card img{
    filter:brightness(0.8)
}

.vertical-blog-card  img{
    width: 100%;
    height: 17rem;
    max-height: 17rem;
    object-fit: cover;
    align-self: flex-end;
    /* border-radius: 8px */
}
.vertical-blog-card .blog-title{
    
    color: var(--primary-color-blue__dark);
    font-size: 1.5rem;
    font-weight: 600;
    font-family: var(--primary-typeface);
}
.vertical-blog-card .blog-image{
    display: grid;
}
.vertical-blog-card .blog-date{
    font-size: smaller;
}
/* horizontal blog card */
.horizontal-blog-card{
    display: grid;
    grid-template-columns: 8rem auto;
    color:black; 
    height: 8rem;
    gap: 1rem;

}
.horizontal-blog-card .blog-image{
    order: -3;
    height: 100%;
    /* border-radius: 8px; */
}

.horizontal-blog-card .blog-title{ 
    color:var(--primary-color-blue__dark);
    font-size: 18px;
    font-weight: 500;
    line-height: unset;
}
.horizontal-blog-card .blog-image img{
    height: 100%;
    width: 100%;
    object-fit: cover;
    /* border-radius: 8px; */
}
/* latest blog card */
.latest-blog-card{
    width: 100%;
    height: 25rem;
    position: relative;
    display: grid;
    grid-template-columns: 1.5fr 1fr;
    gap:1rem
}
.latest-blog-card .blog-metadata{
    display: grid;
}
.latest-blog-card .blog-image{
    order: -2;
}
.latest-blog-card .blog-desc{
    color:var(--primary-color-blue);
    padding-bottom: 0.5rem;
}
.latest-blog-card .blog-date{
    color: rgb(41, 40, 40);
    font-size: 15px;

}
.latest-blog-card .blog-title{
    font-size: 2rem;
    color:var(--primary-color-blue__dark);
    font-weight: 700;
}

.latest-blog-card img{
    width: 100%;
    height: 25rem;
    object-fit: cover;
}
.blog-author{
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 4rem auto;
}
.blog-author .author-image{
    height: 3.5rem;
    width: 3.5rem;
    border-radius: 50% ;
    background-color: lightgray;
}
.blog-author .author-image img{
    object-fit: cover;
    height: 3.5rem;
    width: 3.5rem;
    border-radius: 50%;
}
.blog-author .author-name{
    color:var(--primary-color-blue__dark);
    font-weight: 600;
    font-size: large;
}
.blog-author .author-role{
    color: gray;
}
/* hover */
a.blog-card:hover img:not(.author-image img){
    transform: scale(1.05);
    transition: transform 0.2s ease;
}
@media screen and (max-width:500px) {
    .vertical-blog-card{
        height: 100%;
        max-height: 24rem;
    }
    .vertical-blog-card img{
        max-height: 12rem;
    }
    .horizontal-blog-card{
        height: 7rem;
        grid-template-columns: 7rem auto;
    }
    .horizontal-blog-card img{
        max-height: 7rem;
    }
    .latest-blog-card{
        height: fit-content;
        grid-template-columns: 1fr;
        gap:1rem;
       
    }
    .latest-blog-card img{
        height: 18rem;
    }
    .latest-blog-card .blog-image{
        order: 3;
    }
    .vertical-blog-card .blog-title{
        font-size: 1.5rem;
    }
    
    
}





.red_bar{
    background-color: #f65e5b;
}
.yellow_bar{
    background-color: #fec760;
}
.green_bar{
    background-color: #23e5af;
}
.test_bars {
   
    position: relative;
    margin-bottom: 20px;
}
.percentage-bar{
   
        margin-top: 10px;
        margin-bottom: 27px;
        display: flex;
        width: 100%;
        border-radius: 10px;
        overflow: hidden;
    
}
.bar_pointer {
    position: absolute;
    width: 0;
    height: 0;
    z-index: 2;
    transform: translate(-50%, 0%);
    top: 24px;
    /* transform: translate(-50%, 78%); */
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid rgb(74, 136, 218);
}
.percentage-bar__bar{
    height: 12px;
    position: relative;
}
.pointer__value {
    position: absolute;
    bottom: -30px;
    font-size: 12px;
    font-weight: 500;
    left: 50%;
    transform: translateX(-50%);
    color: black;
}
.one-career-page-main-container{
    min-height: 100vh;
    padding: var(--padding-tb__desktop) calc(var(--padding-lr__desktop) + 3rem);
    display: grid;
    gap: 1.5rem;
}
.one-career-page-main-container .big-header-h1{
    font-size: 1.8rem;
    color: var(--primary-color-blue);
}
.one-career__small-header{
    font-weight: 600;
    color: var(--secondary-color-purple__dark);
}

.job-desc__one-block p li{
 padding-left: 0.3rem;
}
.job-desc__one-block .big-header-h1{
    padding-bottom: 0.5rem;
}
.career-illus{
    
   
    position: relative;
    height: 40vh;
    object-fit: cover;
    overflow: hidden;
    width: 100%;
}
@media screen and(max-width:800px) {
    .one-career-page-main-container{

        padding: var(--padding-tb__desktop) var(--padding-lr__desktop);
        
    }
}
#hcg-hero{
    background-color: var(--secondary-color-purple);
    color: #e5e5e5;
    min-height: 80vh;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
}
.hero-img{
    position: relative;
    padding-left: 2rem;
}
#hcg-hero img {
   max-height: 70vh;
   position: relative;
   top: var(--padding-tb__desktop);
}
.hcg-test-properties{
    padding-top: 4rem;
    display: grid;
    grid-auto-flow: column;
    color: var(--secondary-color-purple__dark);
    gap: 1rem;
    text-align: center;
    font-size: large;
    font-weight: 600;
}
.one-property{
    display: grid;
}
.one-property__image{

    height: 7rem;
  
    align-self: center;
    justify-self: center;
}
.one-property__image img{
   height: 6rem; 
}
.one-hcg-step{
    display: grid;
    grid-template-columns: auto auto;
    align-items: center;
    font-size: 1.5rem;
    font-weight: 400;
    gap: 1rem;
    padding-top: 1rem;
    justify-content: center;
}

.one-hcg-step__image{
    width: 15rem;
    display: flex;
    align-items: center;
    justify-content: center;
}
.one-hcg-step__image img{
    height: 12rem;
}
.hcg-steps{
    background-color: var(--secondary-color-purple);
    color: white;
}
.step-hcg-no{
    font-weight: 700;
    font-size: 3rem;
    padding-right: 1rem;
    color: var(--primary-color-green);
}
@media screen and (max-width:700px) {
    .hcg-test-properties{
        grid-auto-flow: row;
        gap: 1rem;
      
       
    }
    #hcg-hero{
        min-height: 100vh;
        grid-template-columns: 1fr;
    }
    h2{
        font-size: 1.2rem;
    }
    h1:not(.big-header-h1){
        font-size: 1.5rem;
        /* color: var(--primary-color-blue__dark); */
    }
    .one-hcg-step{
        grid-template-columns: 1fr;
        font-size: 1.2rem;
    }
    .one-hcg-step__image img{
        height:9rem;
    }
    .hero-img{
        padding-left: 0;
    }
    .hero-img img{
        padding-left: 0;
    }
    #hcg-hero img {
       height: 50vh;
        top: 3rem;
    }
    .step-hcg-no{
        font-size: 2.2rem;
    }
}
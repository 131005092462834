 
 .navbar{
     background-color:rgb(71, 82, 141);
     color:white;
     justify-content: flex-start;
     align-items: center;
     position: fixed;
     top:0;
     left:0;
     height: 3.5rem;
     width: 100vw;
     z-index: 999;
 }
 .bx-left-arrow-alt{
     font-size: 36px;
     color:white
 }
.eachRecmConatiner{
    display: flex;
    border-radius: 5px;
    border:none;
    margin: 1rem;
    background-color: white;


}
.eachRecmImage{
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    width:140px;
    background-color:lightgray ;
}
.whyRecommended{
   
    font-weight: 200;
}
.eachRecmDescription{
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
    width:80%;
}
.eachRecmTitle{
    color:rgb(71, 82, 141);
    font-size: 17px;
    font-weight: 900;
}
.eachRecmImage img{
    width: 139px;
    height: 100%;
    object-fit: cover;
}
.eachRecmTime{
    display: flex;
    gap:0.5rem;
    font-weight: 400;
}
.practiseNow{
    color: #e75e34;
    letter-spacing: 0.1px;
    font-size: 14px;
    margin-top:1rem;
    font-weight: 900;
    text-transform: uppercase;
}
/* practise yoga componeny*/
.recmDescription{
    padding:1rem;
}
ul{
    list-style: none;

}

hr{
    background-color: rgb(71, 82, 141);
    width: 80%;
    height: 2px;
    border: none;
}
.recmImage{
    width: 100%;
    background-color: gray;
    height:fit-content;
    
}
.recmImage img{
    object-fit: cover;

    width: 100%;
    margin-top: 3.5rem;
}
.recmPanel_desc{
    font-size: smaller;
    /* font-weight: lighter; */
    padding: 1rem;
    background: lavender;
    color: rgb(71, 82, 141);
    /* font-style: italic; */
    letter-spacing: 0.2px;
    line-height: 20px;
    font-weight: bold;
}
.pills{
    display: flex;
    align-items: center;
}

.pills div{
    display: flex;
    gap:0.5rem;
    align-items: center;
    margin-right: 0.5rem;
    border-radius: 25px;
    padding:2px;
    padding-left:8px;
    padding-right: 8px;
    color: white;
    font-size: smaller;
}
.pills .duration{
    background-color: #f0e1f7;
    color: black;
}
.pills .difficulty{
    background-color:#FFB429;
}

.recmTitle{
    color:rgb(71, 82, 141);
}
.sectionTitle{
    color:#000646;
    font-weight: 900;
    letter-spacing: 0.5px;
    text-transform: uppercase;
}
.sectionList{
    padding-top: 1rem;
}
.eachInstructionContainer{
    background-color: lavender;
    padding: 1rem;
    margin-bottom: 1rem;
}
.eachInstructionContainer.benefits{
    background-color: #c1f4d6;
}
.eachInstructionContainer.precautions{
    background-color: #f0e0b2;
    padding: 1rem;
    margin-bottom: 1rem;
}
.sectionList li{
    display: flex;
    align-items:baseline;
    font-weight: 300;
    font-size: 15px;
    margin-bottom: 0.3rem;
    
}
.sectionList li::before{
    display: inline;
   content: "";
   height: 5px;
   width: 5px;
   border-radius: 50%;
   background-color:rgb(71, 82, 141);
   font-size:7px;
   margin-right:10px;
  
}
.stepsWrapper{
    background-color: lavender;
    margin-top: 1rem;
    padding: 1rem;
}
.step{
    display: flex;
   
    position: relative;
}
.stepDesc{
    margin-bottom: 1rem;
}

.step:last-child .line{
    display: none;
}

.stepNo{
    background-color:white;
    position: relative;
    margin-bottom:3rem ;
    border:2px solid #664277;
    padding: 14px;
    height: 41px;
    border-radius:50% ;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 1rem;
    color: rgb(71, 82, 141);
    font-weight: bold;
}
.psuedostepNo{
    position:absolute;
    top:0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index:3;
}
.line{
    position: absolute;
    height: 100%;
    border-right: 1px dashed black;
    left:20px
}

/* superfood component */
.instructionWrapper{
    margin-top:1rem;
}

/*recioe */
.recipesWrapper{
    padding:1rem;
    background-color: white;
    margin-bottom: 1rem;
}
.recipeScroll{
    display: flex;
    overflow-x: scroll;
   
}
.recipeContainer{
    display: flex;
  
    width: 100%;
    padding:0.5rem;
    
}

.eachRecipe{
    background-color: white;
    padding: 1rem;
    margin-right: 1rem;
    width:100px;
    display: flex;
    box-shadow: 2px 2px 3px lightgray;
    border-radius: 6px;
}
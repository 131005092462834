#order-details-container{
    padding:inherit 16rem;
    display: grid;
    grid-template-columns: 0.75fr 0.3fr;
    min-height: 70vh;
    gap:2rem;
    color: var(--primary-color-blue);
    background-color: rgb(241, 243, 243);
}
#order-details-container div[class*="action-btn"]:hover{
    color:unset;
    opacity: 1;
}
.error-msg{
    color:red;
    font-size: smaller;
}
#order-details-container h1{
    font-size: 2rem;
    padding-bottom: 0.5rem;
}
.white-box{
    padding: 1rem;
    background-color: white;
}
.order-meta{
    font-weight: 700;
}
.order-meta span{
    background-color: var(--primary-color-green__light);
    padding: 3px;
    font-weight: 400;
}
.order-price{
    display: flex;
    align-items: center;
}
.order-price .old-price{
    text-decoration: line-through;
    color: gray;
    font-size: small;
}
.order-price .new-price{

    padding-left: 0.5rem;
}

.shipment-details__form{
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;
    max-width: 100%;
    
}

.shipment-details__form input {
    height: 3rem;
    padding-left: 1rem;
    width: 100%;
    border: 1px solid lightgray;
    border-radius: 5px;
}
label.input-container,input[name='email']{
    grid-column: 1 / 3;
    position: relative;
}
label.input-container input{
    padding-left: 3.5rem;
}
label.input-container span{
    position: absolute;
    left: 1px;
    top:1px;
    height: calc(100% - 2px);
    width: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(243, 243, 243);
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}
input[placeholder="Address"]{
    height: 7rem;
    grid-column: 1 / 3;
}
.total-price {
    font-size: large;
    border-top: 1px solid var(--primary-color-blue);
    padding-top: 0.5rem;
    margin-top: 0.5rem;
}
.total-price span{
    font-weight: 800;
}
.total-price-calculations{
    margin-top: 1rem;
}
#total-price-calculations__table p{
    display: grid;
    grid-template-columns: 1fr 1fr;
}
#total-price-calculations__table p span{
    justify-self: flex-end;
}
#order-coupons{
    margin-top: 1rem;
}
#coupon-addition-form{
    
    display: flex;
    align-items: center;
    gap: 0.5rem;

}

#coupon-addition-form{
    display: flex;
    align-items: center;
    gap: 1rem
}
#coupon-addition-form input{
    height: 3rem;
    padding-left: 1rem;
    border:1px solid rgb(201, 201, 201);
    border-radius: 8px;
}
@media screen and (max-width:500px){
    #order-details-container{
        grid-template-columns: 1fr;
    }
    .package-details{
        order: -2;
    }
    .shipment-details__form{
        max-width: 100%;
        gap:0.8rem
    }

}





#tech_hero{
    background-attachment: fixed;
    background-size: cover;
    background-repeat: no-repeat;
    color: var(--primary-color-white);
   position: relative;
    height: fit-content;
    display: flex;
   justify-content: center;
   align-items: center;
   height: 100vh;
  

}
#tech_hero::after{
    
    position: absolute;
    right: 0rem;
    left: 0;
    width: 100vw;

    bottom: 0;
    /* top: 0; */
    /* left: 0; */
    height: 100vh;
    
    z-index: 8;
    background-color: rgba(0,0,0,0.9);

}
#tech_hero .big-header-h1{
    font-size: 6em;
}

#tech_hero h1,#tech_hero .sub-header{
   
    text-align: center;
    position: relative;
    z-index: 9;
}
.kicker{
    font-size: 18px;
    font-weight: 300;
    text-transform: uppercase;
}

#how_recommendations{
   
    padding: 7rem 2rem;
}
.how_recms_illus{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--secondary-color-purple);
    
}
.how_recms_illus img{
    width: 100%;
}
.mobile_howrecm{
    display: none;
}
#our_tech{
    position: relative;
    margin-left: auto;
    z-index: 5;
    
    display: grid;
    grid-template-columns:  1fr;
    color: var(--secondary-color-purple__light);
    background-color: var(--primary-color-blue__dark);
}
#built-for{
    display: grid;
    justify-items: center;
}
#built-for h1{
    text-align: center;
    
}
.built-for-container{
    padding: 2rem 5rem;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-items: center;
}
.built-for-container .built-for-one{
    display: grid;
    width: 13rem;
    height: 13rem;
    border-radius: 50%;
    background-color: var(--secondary-color-purple__light);
    align-content:  center;
    justify-items: center;
}
.built-for-container .built-for-one img{
    height: 5rem;
}
.goal{
    transition: all 0.1s linear;
    font-size: 1.8rem;
}
.goal.active{
    border-bottom: 2px solid rgb(252, 207, 190) ;
    font-weight: 700;
    color: black;
}
.param_list{
    padding-top: 2rem;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}
#desktop_howrecm{
    
    margin-top: 2rem;
}
img.mobile_howrecm{
    display: none;
}
#param_goals{
    background-color: white;
}
.bubble_svg{
    display: flex;
    justify-content: center;
    align-items: center;
}
.bubble_svg svg{
    width: 80%;
    margin-top: 2rem;
}
.bubble {
    opacity: 0.5;
    fill: lightgray;
    transition: all 1s cubic-bezier(0.075, 0.82, 0.165, 1);
}
.bubble.active_bubble {
    opacity: 1;
    fill: var(--primary-color-green__light);
    
}
.goal_list{
    width: 80%;
    padding-top: 2rem;
    justify-content: space-between;
    display: flex;
    flex-wrap: wrap;
    
    text-transform: capitalize;
    letter-spacing: 1px;
    margin-right: auto;
    margin-left: auto;
}
.goal{
    cursor: pointer;
    color: gray;
}



/* .parallax>section{
    height: 100vh;
    
    padding:7rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

} */
 .only_header{
   font-size: 3rem;
   text-align: center;  
    
}
.opacity_text{
    text-align: unset;
}

#our_tech .section_desc{
    overflow: hidden;
   display: flex;
   justify-content: center;
   align-items: center;
   width: 100%;

}

#our_tech .section_desc img{
    width:50%;
    padding-top: var(--padding-tb__desktop);
    object-fit: cover;
    position: relative;
  
}
#getapp .content{
    background-color: #d7f8ff;
    display: flex;
    flex-direction: row;
    width: 100%;
    padding-right: 14rem;
    padding-left: 14rem;
    justify-content: space-between;
}

#getapp .content img{
    height: 39rem;
    position: relative;
    top: -3rem;
}
.app_btn a{
    display: flex;
    align-items: center;
}
.app_btn>div{
    background: black;
    border-radius: 14px;
    color: white;
    display: flex;
    
    justify-content: center;
    align-items: center;
    padding: 0.1rem;
    margin-top: 1rem;
    padding: 0.4rem 0rem;
}
.app_btn p{
    margin-bottom: 0px;
}
#getapp{
    padding: 0;
}
#getapp h1{
    font-size: 40px;
    letter-spacing: 4px;

}
#getapp .content>div{
    width: 50%;
}
#getapp .desc{
    font-size: 22px;

    
}
#getapp .app_btn>div{
    width: 15rem;
}
#getapp .app_btn i{
    font-size: 2rem;
    margin-right: 1rem;
}
footer,#footer {
    background-color: #d7f8ff;
    font-family: 'Work Sans', sans-serif;
    color: white;
    padding-bottom: 0px;
}

#footer>.footer-top .footer-info p, #footer>.footer-top ul, #footer>.footer-top a, #footer .footer-top h4, #footer .footer-top .footer-links ul a,footer>.footer-top .footer-info p, footer>.footer-top ul, footer>.footer-top a, footer .footer-top h4, footer .footer-top .footer-links ul a {
    color: #ccc8c8;
}

#footer .copyright, #footer .credits {
    text-align: start;
    padding-bottom: 2rem;
}

#footer .footer-top, #footer .container {
    background-color: #252525;
    border-bottom: none;
}

#footer .container {
    margin: 0px
}
#footer .footer-top .social-links a {
    background: unset;
}

.footer-top.mobile {
    display: none;
}
.papers_popup_container{
    position: absolute;
    padding: 2rem;
    background-color: rgba(0,0,0,0.9);
    right: 0;
    width: 100vw;
    top:0;
    transition: all 0.2s linear;
}
.papers_popup_container .papers{
    height: 90vh;
    overflow-y: scroll;
    width: 50%;
}
.one_paper{
    padding: 2rem;
    border-bottom: 1px solid lightgray;
}
.one_paper a{
    color: white;
    font-weight: 300;
}
.papers_popup_container.hide{
    right: 100%;
}
.all_categories{
    margin-left: 6rem;
}
.all_categories a{
    display: block;
    padding-bottom: 0.2rem;
}
.all_categories .head{
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 600;
    margin-bottom: 5px;
}
#param_goals{
    position: relative;
   
   
}
 /* upcmoming prod */
 #upcoming-products{
    background-color: var(--primary-color-blue__dark);
    color: var(--primary-color-white);

 }
 .all-upcoming-products__container{
    padding-top: var(--padding-tb__desktop);
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 20rem;
    transition: all 0.2s cubic-bezier(0.19, 1, 0.22, 1);
    max-height:30rem;
}
 .one-upcoming-product{
    padding: 1rem;
    display: grid;
 }
 .one-upcoming-product.active{
    color: white;
 }
 .one-upcoming-product__desc, .one-upcoming-product.active .read-more-btn{
    display: none;
 }
 
 .one-upcoming-product__title{
    font-family: var(--primary-typeface);
    font-weight: 600;
    font-size: 1rem;
    transition: all 0.3s ease;
 }
 .one-upcoming-product.active  .one-upcoming-product__title{
    font-size: 2rem;
 }
 .read-more-btn{
    align-self: flex-end;
    cursor: pointer;
    font-weight: 700;
    color: var(--primary-color-green);
 }
@media screen and (max-width:970px) {
    .kicker{
        font-size: small;
        letter-spacing: 3px;
    }
    #tech_hero .big-header-h1{
        font-size: 3.5em;
    }
    #header{
        position: fixed;
        background-color: transparent;
        padding: 1rem;
        width: 100%;
    }
    
    #hero_img{
        position: absolute;
        right: 0rem;
        opacity: 0.4;
        bottom: 0;
        top: 0;
        left: 0;
        height: 90vh;
        z-index: 2;
        transition: all 0.1s cubic-bezier(0.075, 0.82, 0.165, 1);

    }
    #tech_hero h1{
        font-size: 50px;
    }
    #how_recommendations{
        padding: var(--padding-tb__desktop);
    }
    .how_recms_illus{
        width: 100%;
    }
    #param_goals{
        position: relative;
        padding:0rem ;
        padding-top: 3rem;
       
    }
    .param_goal_map{
       
        padding: 2rem;
        padding-top: 2rem;
        padding-bottom: 0;
        
    }
    /* .parallax>section{
        padding: 3rem;
    } */
 .only_header{
        font-size: 2rem;
        text-align: center;  
       
     }
    .param_list{
        grid-column-start: 3;
    }
    .goal_list{
        width: 100%;
        padding-top: 0rem;
    }
    .goal{
        font-size: 1rem;
    }
    .goal_list .goal,.param_list .param{
        padding:0.3rem;
        background-color: rgba(255,255,255,0.5);
        display: flex;
        justify-content: center;
        align-items: center;
        color: gray;
    }
    .goal.active{
        color: black;
        transform: scale(1.1);
    }
    #desktop_howrecm{
        display: none;
    }
    #param_goals{height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    }
    img.mobile_howrecm{
        display: block;
        
        width: 100%;
    }
    .bubble_svg svg{
        width: 100%;
    }
    #our_tech{
        padding: 3rem 1rem;
    }
    #our_tech .section_desc img {
        width: 100%;
        object-fit: cover;
        position: relative;
        
    }
    #our_tech h1{
        font-size: 1.4rem;
    }
    #getapp{
        padding: 0;
    }
    #getapp .content{
        padding: 1rem;
        flex-direction: column;
    
    }
    #getapp .content img{
        height: 15rem;
    }
    #getapp .content img{
        height: 21rem;
        position: relative;
        top: -3rem;
    }
    #getapp .app_btn>div {
        width: 10rem;
    }
    #getapp p.desc{
        display: none;
    }
    #getapp .content>div{
        width: 100%;
    }
    
    .footer-top.desktop {
        display: none;
    }
    .footer-top.mobile {
        display: block;
    }
    #footer .footer-top.mobile .social-links a {
        width: 20px;
        margin-right: 0px;
        height: 30px;
    }
    .section_desc{
        line-height: 25px;
        font-size: 17px;
        width: 100%;
    }
    .papers_popup_container .papers{
       
        width: 100%;
    }
    .all_categories{
        font-size: small;
    }
    .all_categories{
        margin-left: 0;
    }
    .social-links a{
        font-size: 1.5rem;
        margin-right: 1rem;
    }
    .all-upcoming-products__container{
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
        max-height: fit-content;
    }
    .web_howrecm{
        display: none;
    }
    
    
}

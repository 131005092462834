.personalised_pricing_quiz{
    box-sizing: border-box;
    height: 100vh;
    background-color: var(--primary-color-blue__dark);
    padding: 3rem 0rem;
    overflow-x: hidden;
    color:var(--primary-color-white)
}
.personalised_pricing_quiz .quiz-slider{
    display: grid;
    /* overflow-x: hidden; */
    grid-auto-flow: column;
    grid-auto-columns: 100%;
    transform: translateX(0);
    position: relative;
    left: 0%;
}
.button-container{
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
    grid-gap: 2rem;
    width: 25%;
    margin-left: auto;
    margin-right: auto;
    justify-items: center;
    align-items: center;
}


.page{
   
    width: 100vw;
    min-height: 50vh;
    box-sizing: border-box;
    display: flex;
    justify-content: start;
    align-items: center;
    flex-direction: column;
    padding: 0rem 27rem;
}
img.questionInfographic{
    max-height: 5rem;
}
.oneQuestion{
    margin-bottom: 1rem;
}
.question{
    font-size: 2rem;
    text-align: center;
    padding-bottom: 1rem;
}
.inputWrap p{
    margin-top: 0rem !important;
}

@media screen and (max-width:900px) {
    .personalised_pricing_quiz{
        padding: 0;
    }
    .button-container{
        width: 100%;
    }
    .page{
        padding: 1rem;
    }
}
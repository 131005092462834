@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Sen:wght@400;700&display=swap);
#website-footer{
    background-color: var(--primary-color-blue__dark);
    padding: 6rem var(--padding-lr__desktop);
    color:rgb(216, 215, 215);
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 1fr 1fr 1fr 1fr ;
    padding-bottom: 3rem;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    font-family: var(--primary-typeface);
    
}
/* #website-footer>div:nth-child(2){
    margin-left: auto;
    display: grid;
    row-gap: 2rem;
} */
#website-footer a{
    color: rgb(216, 215, 215) !important;
    font-weight: 200;
}
.footer-section-title{
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: 900;
    color:rgb(216, 215, 215)
}
#footer-company-name img{
    height: 2rem;
    margin-bottom: 1rem;
}
#footer-company-address{
    
    letter-spacing: 1px;
}
.footer-section-list{
    display: grid;
    grid-auto-flow: row;
    grid-gap: 0.2rem;
    
    padding-top: 0.5rem;
    letter-spacing: 1px;
}
.footer-section-list a:hover{
    text-decoration: none;
    color:rgb(216, 215, 215)
}
#copyright-para{
    grid-row-start: 2 ;
    grid-column: 1 / 5;
    padding-top: 2rem;
}
#copyright-para p{
    font-size: small !important;
    padding-top: 4rem;
}
@media screen and (max-width:900px){
    #website-footer{
        padding: 2rem var(--padding-lr__mobile);
    }
}

@media screen and (max-width:500px) {
    #website-footer{
        grid-template-columns: 1fr;
        grid-auto-flow: row;
        padding: 2rem;
        grid-gap: 1rem;
    }
    #website-footer>div:nth-child(2){
        margin-left: unset;
    }
    .footer-section-list, #footer-company-address{
        grid-gap: 0.2rem;
        font-size:smaller;
    }
    #copyright-para{
        grid-row-start: 5 ;
        grid-column: 1 ;
        padding-top: 0;
    }
}

#header .nav{
  text-decoration: none;
  color: white!important;
  text-decoration: none!important;
  text-transform: uppercase;
  font-size: 13px;
}
a{
  text-decoration: none!important;
  
}
#header{
  padding: 1rem var(--padding-lr__desktop);
  position: fixed;
  top:0;
  
  width: 100%;
  display: flex;
  justify-content: space-between;
  z-index: 900;
  align-items: center;
  background-color: var(--primary-color-blue__dark);
}
#neodocs_logo{
  display: flex;
  align-items: center;
}
#neodocs_logo img{
  height: 2.7rem;
  cursor: pointer;
}
#navs{
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
  font-weight: bold;
  letter-spacing: 0.5px;
}
#inner-navs{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: auto;
  margin-left: auto;
}
#inner-navs .nav{
  margin-right: 1.5rem;
  text-decoration: none;
  height: 100%;
  align-items: center;
}
#mobile_navs{
  display: none;
  left: 100vw;
}
.hamburger{
  display: none;
  position: relative;
  z-index: 901;
}
/* products nav */
.products-nav{
  width: 100%;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

}

.products-nav .dropdown-container{
  display: none;
  position: absolute;
  top: 4rem;
  left: 0;
  width: 100vw;
  z-index: 88;
  background-color: var(--secondary-color-purple__light);
  flex-wrap: wrap;
  grid-gap: 2rem;
  gap: 2rem;
  color: white;
  padding: 2rem var(--padding-lr__desktop);
  transition: all 0.5s ease;
  
}
.products-nav:hover .dropdown-container{
  display: flex;
  transition: all 0.5s ease;
  justify-content: center;
  align-items: center;
  
}

.one-product{
  display: grid;
  grid-template-columns: 5rem auto;
  grid-gap: 1rem;
  gap: 1rem;
  align-items: center;
  background-color: white;
  box-shadow: 1px 2px 13px rgba(233, 233, 233, 0.644);
  border-radius: 8px;
  padding-right: 1rem;
  padding-left: 1rem;
}
.one-product:hover{
  background-color: rgb(245, 245, 253);
  transition: all 0.2s ease;
}
.one-product__image img{
  height: 5rem;
  border-radius: 5px;
}
.one-product__name{
  order: -1;
  color: var(--primary-color-blue);
  font-size: 18px;
  text-transform: capitalize;
  font-weight: 600;
  font-family: var(--primary-typeface);
}
@media screen and (max-width:900px) {
  #header{
    padding: 1rem var(--padding-lr__mobile);
    background-color: var(--primary-color-blue__dark);
  }
  #neodocs_logo{
  position: relative;
    z-index: 4444;}
  #header .nav{
    color: white !important;
  }
  #navs,#inner-navs{
    display: none;
  }
  #neodocs_logo img{
    height: 2.5rem;
  }
  #mobile_navs{
    box-sizing: border-box;
    position: absolute;
    height: 100vh;
    width: 100vw;
    top: 0;
    bottom: 0;
    left: 100vw;
    right: 0;
   
    background-color: var(--primary-color-blue__dark);
    display: flex;
  flex-direction: column;
    /* justify-content: center; */
    color: white;
    font-size: 1.8rem;
    z-index: 900;
    grid-gap: 3rem;
    gap: 3rem;
  }
  .mobile_navs__primary{
    padding-top: 7rem;
  }
  
  .mobile_navs__bottom{
    justify-self: end;
    margin-top: auto;
    background-color: var(--secondary-color-purple);
   padding: 2rem;
  }
  #mobile_navs .nav{
    color:rgb(224, 224, 224);
    margin-bottom: 1rem;
    font-size: 1.5rem;
    padding-left: 2rem;
    text-transform: uppercase;
    font-weight: 600;
  }
  #mobile_navs .secondary-nav{
    color: white;
    font-size: 20px;
    padding-left: 2rem;
    font-weight: 300;
  }
  .products-nav{
    align-items: flex-start;
  }
  .hamburger{
    height: 0.8rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .hamburger div{
    height: 3px;
    width: 25px;
    
    
    background-color: white;
  }
  .hamburger.active div{
    background-color:white;
    transition: all 0.9s cubic-bezier(0.075, 0.82, 0.165, 1);
  }
  .hamburger.active .line1{
    width: 1.3em;
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
    -webkit-transform-origin: 14px 12px;
            transform-origin: 14px 12px;
  }
  
  .hamburger.active .line2{
    width: 1.3em;
    -webkit-transform: rotate(
45deg);
            transform: rotate(
45deg);
    -webkit-transform-origin: 13px -7px;
            transform-origin: 13px -7px;
  }
  .products-nav .dropdown-container,.products-nav:hover .dropdown-container{
    display: grid;
    grid-template-columns: 1fr 1fr;
    position: relative;
    top: unset;
    left: -2rem;
    width: 100vw;
    z-index: 88;
    
  
    grid-gap: 1rem;
    
  
    gap: 1rem;
 
    padding: 2rem var(--padding-lr__mobile);
    
  }
  .one-product{
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    align-items: center;
    box-shadow: 1px 1px 16px #9f9fbd;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  
  .one-product__name{
    font-size: 13px;
  }
  .one-product__image img{
    height: 3.2rem;
   
  }
  
  
}

.common-plans {
    background-color: var(--primary-color-blue__dark);
    color: var(--primary-color-white);
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.common-plans svg{
    position: absolute;
    top:-10rem;
    left:-7rem;
    opacity: 0.3;
    height: 25rem;
    pointer-events: none;
}
#products-pricing svg *{
    stroke: var(--secondary-color-blue__dark);
    fill: var(--secondary-color-blue__dark);
}
.common-plans>h1{
    position: relative;
    z-index: 9;
}

.all-plans-container {
  
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
    min-height:26rem
   
    
    
    
}
.plan_card{
    border-radius: 10px;
    border:1px solid var(--secondary-color-blue__dark);
    background-color:var(--primary-color-blue__dark);
    text-align: center;
    height: 85% ;
    display: grid;
    align-items: center;
    
    position: relative;
    
}
.plan_card::before{
    content:"Sold Out";
    border-radius: 10px;
    position: absolute;
    background-color: rgba(0,0,0,0.6);
    font-size: 1.5rem;
    top:0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    color:rgb(234, 149, 149);
    z-index: 88;
    font-weight: 700;

}
.plan_card a{
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    pointer-events: none;
}
.plan_card:nth-child(2){
    height: 100%;
    box-shadow: 1px 2px 16px rgb(37, 37, 37);
    border: 3px solid var(--secondary-color-blue__dark);
}


.planHead {
    position: relative;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding: 1rem 2rem;
    
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    
}

p.price span {
    font-size: 2.3rem;
    letter-spacing: 0;
    font-weight: 800;
    color: var(--secondary-color-purple);
}



.features{
    background-color: #141A54;
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 1rem;
    gap: 1rem;
    padding: 1rem 0rem;
    font-size: small;
}
.featurelistitem i{
    color:var(--primary-color-green);
    font-size: 2rem;
    
}
.featurelistitem p{
    font-size: small!important;
    font-weight: 300;
}
p.price span.small-note{
    font-weight: 300;
    font-size: x-small;

}
.popup-notify{
    position: relative;
    cursor: pointer;
}
.popup-notify::after{
    content: '';
}
.popup-notify:hover::after{
    content:'The current product is in beta and available for research use only. This product is not intended for use in diagnosing diseases or other conditions;  determining the state of health;  or curing, mitigating, treating, or preventing disease.';
    position: absolute;
    width: 20vw;
    padding: 0.5rem;
    border: 1px solid black;
    top:1rem;
    left:-3rem;
    background-color: white;
    color:black;
    z-index: 99;
}

.take-quiz-container{
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: 6rem;
    gap: 6rem;
    align-items: center;
}
.take-quiz-container img{
    width: 100%;
    height: 50vh;
    object-fit: cover;
}
#products-pricing{
    background: var(--primary-color-blue__dark);
    position: relative;
    color: var(--primary-color-white);
    padding: var(--padding-tb__desktop) 0;
    padding-bottom: 0;
}
#products-pricing>div{
    padding: 1rem var(--padding-lr__desktop) ;
}
#products-pricing svg{
    position: absolute;
    top:0;
    right: 0;
    height: 18rem;
    opacity: 0.3;
    z-index: 3;
}


.all-products-container{
    display: grid;
    background-color: white;
    grid-template-columns: 1fr 1fr;
    position: relative;
    padding: 3rem 12rem !important; 
    grid-gap: 3rem; 
    gap: 3rem;
    
    
}

.all-benefits>div{
    
    display: flex;
    padding: 3rem 0rem;
}
.one-included{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    grid-gap: 1rem;
    gap: 1rem;
    text-align: center;
    
}
.one-included img{
    height: 4rem;
}
.each-product{
    display: grid;
    grid-template-columns: 4rem auto;
    grid-gap: 2rem;
    gap: 2rem;
    border-radius: 15px;
    align-items: center;
    cursor: pointer;
    padding:3rem 2rem;

}

.each-product__img{
    height: 4rem;
}
.each-product:hover .each-product__img{
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    transition: all 0.2s ease;
}

.each-product__desc{
    font-size: 2rem;
    color: white;
    font-family: var(--primary-typeface);
    font-weight: 600;
}
.each-product__read-more,.each-product__buynow{
    cursor: pointer;
    color: white;
    font-weight: 600;
    display: block;
}
.each-product__buynow{
   
    color: var(--primary-color-green);
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    border-radius: 5px;
    
}
.each-product__read-more:hover,.each-product__buynow:hover{
    color:var(--primary-color-green)
}

.buynow-links{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    align-items: center;
    padding-top: 1rem;
}

@media screen and (max-width: 500px){
    
    .all-plans-container{
        
        width: 100%;
        grid-template-columns: 1fr;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
      
    }
    .plan_card{
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        position: relative;
    }
    .plantitle{
        font-weight: bold;
    }
    p.price span{
        display: block;
        font-size: 2rem;
    }
    p.price .cancelled-price{
        display: inline;
        font-size: small!important;
    }
    .plan_card a{
        position: absolute;
        top:0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
    }
    .plan_card a:hover{
        opacity: 0;
    }
    .small_note{
        font-size: small;
    }
    .planHead{
        display: flex;
        padding: 1rem;
        justify-content: space-between;
        align-items: center;
    }
    .features{
        display: none;
    }
    .plan_card{
        width: 100%;
    }
    .take-quiz-container{
   
        grid-gap: 1rem;
   
        gap: 1rem;
        grid-template-columns: 45% 50%;
    }
    p.bestseller{
        font-weight: 500!important;
    color: black!important;
    position: absolute;
    top: 0;
    left: 0;
    padding: 0 1rem;
    border-top-left-radius: 8px;
    background: var(--primary-color-green);
    /* font-size: 1.5rem; */
    border-bottom-right-radius: 8px;
    }
    .all-products-container{
        
        display: grid;
        grid-template-columns: 1fr;
        position: relative;
        grid-gap:1rem;
        gap:1rem;
        padding: 2rem 1rem !important;
    
    }
    .each-product{
        padding:  1rem;
    }
    .each-product__img{
        height: 3rem;
    }
    .each-product__desc{
        font-size: 1.2rem;
    }
    #products-pricing svg {
        height: 10rem;
        
    }
    .all-benefits>div{
        flex-direction: column;
        grid-gap: 1rem;
        gap: 1rem;
        padding: 1rem
        
    }
    .one-included{
        display: grid;
        grid-template-columns: 2rem auto;
        text-align: start;
        justify-content: start;
        
        align-items: center;
        
    }
    .one-included img{
        height: 2rem;
    }
}
#testimonials{
    min-height: 85vh;
    background-color: var(--primary-color-blue);
    color: var(--primary-color-white);
}
.testimonial-card-scroller{
    display: grid;
    grid-auto-flow: column;
    grid-gap: 1rem;
    gap: 1rem;
    overflow-x: auto;
    width: 100%;
}
.testimonial-card{
    width: 20rem;
    background-color: var(--primary-color-blue__light);
    padding: 1rem;
    border-radius: 10px;
    
    display: grid;
    
}
.testimonial-quote{
    font-style: italic;
    font-size: small;
    font-weight: 300;
}
.testimonial-quote i{
    font-size: 2rem;
    -webkit-filter: opacity(0.4);
            filter: opacity(0.4);
}
.testimonial-author-details{
    padding-top: 0.5rem;
    display: flex;
    align-items: center;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    align-self: end;
    
}
.testimonial-autor-img{
    height: 2rem;
    width: 2rem;
    border-radius: 50%;
    background-color: gray;
}
.testimonial-autor-img img{
    height: 2rem;
    width: 2rem;
    border-radius: 50%;
    object-fit: cover;
}
.testimonial-autor-designation{
    color:var(--secondary-color-purple);
    font-weight: 800;
    font-size: x-small;
}
.community-illustration-wrapper{
    height: 300px;
    position: relative;
    margin-top: 2rem;
    margin-bottom: 2rem;
}
.community-illustration__person{
    height: 4rem;
    width: 4rem;
    border-radius: 50%;
    border: 3px solid var(--primary-color-blue);
}
.community-illustration__person.you_div{
 
    border: 3px dotted var(--primary-color-green);
    top: 35%;
    left: 50%;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 300;
    background: var(--primary-color-blue);
    z-index: 3;
    
}
.community-illustration-wrapper img{
    position: absolute;
}
.community-illustration-wrapper img.wave-patten{
    left:-12rem;
    top:0;
    bottom: 0;
    
    
}
@media screen and (max-width:450px) {
    .testimonial-card{
        width: 16.5rem;
    }
   
    .community-illustration__person.additional_pc{
        display: none;
    }
}





#tech_hero{
    background-attachment: fixed;
    background-size: cover;
    background-repeat: no-repeat;
    color: var(--primary-color-white);
   position: relative;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: flex;
   justify-content: center;
   align-items: center;
   height: 100vh;
  

}
#tech_hero::after{
    
    position: absolute;
    right: 0rem;
    left: 0;
    width: 100vw;

    bottom: 0;
    /* top: 0; */
    /* left: 0; */
    height: 100vh;
    
    z-index: 8;
    background-color: rgba(0,0,0,0.9);

}
#tech_hero .big-header-h1{
    font-size: 6em;
}

#tech_hero h1,#tech_hero .sub-header{
   
    text-align: center;
    position: relative;
    z-index: 9;
}
.kicker{
    font-size: 18px;
    font-weight: 300;
    text-transform: uppercase;
}

#how_recommendations{
   
    padding: 7rem 2rem;
}
.how_recms_illus{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--secondary-color-purple);
    
}
.how_recms_illus img{
    width: 100%;
}
.mobile_howrecm{
    display: none;
}
#our_tech{
    position: relative;
    margin-left: auto;
    z-index: 5;
    
    display: grid;
    grid-template-columns:  1fr;
    color: var(--secondary-color-purple__light);
    background-color: var(--primary-color-blue__dark);
}
#built-for{
    display: grid;
    justify-items: center;
}
#built-for h1{
    text-align: center;
    
}
.built-for-container{
    padding: 2rem 5rem;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-items: center;
}
.built-for-container .built-for-one{
    display: grid;
    width: 13rem;
    height: 13rem;
    border-radius: 50%;
    background-color: var(--secondary-color-purple__light);
    align-content:  center;
    justify-items: center;
}
.built-for-container .built-for-one img{
    height: 5rem;
}
.goal{
    transition: all 0.1s linear;
    font-size: 1.8rem;
}
.goal.active{
    border-bottom: 2px solid rgb(252, 207, 190) ;
    font-weight: 700;
    color: black;
}
.param_list{
    padding-top: 2rem;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}
#desktop_howrecm{
    
    margin-top: 2rem;
}
img.mobile_howrecm{
    display: none;
}
#param_goals{
    background-color: white;
}
.bubble_svg{
    display: flex;
    justify-content: center;
    align-items: center;
}
.bubble_svg svg{
    width: 80%;
    margin-top: 2rem;
}
.bubble {
    opacity: 0.5;
    fill: lightgray;
    transition: all 1s cubic-bezier(0.075, 0.82, 0.165, 1);
}
.bubble.active_bubble {
    opacity: 1;
    fill: var(--primary-color-green__light);
    
}
.goal_list{
    width: 80%;
    padding-top: 2rem;
    justify-content: space-between;
    display: flex;
    flex-wrap: wrap;
    
    text-transform: capitalize;
    letter-spacing: 1px;
    margin-right: auto;
    margin-left: auto;
}
.goal{
    cursor: pointer;
    color: gray;
}



/* .parallax>section{
    height: 100vh;
    
    padding:7rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

} */
 .only_header{
   font-size: 3rem;
   text-align: center;  
    
}
.opacity_text{
    text-align: unset;
}

#our_tech .section_desc{
    overflow: hidden;
   display: flex;
   justify-content: center;
   align-items: center;
   width: 100%;

}

#our_tech .section_desc img{
    width:50%;
    padding-top: var(--padding-tb__desktop);
    object-fit: cover;
    position: relative;
  
}
#getapp .content{
    background-color: #d7f8ff;
    display: flex;
    flex-direction: row;
    width: 100%;
    padding-right: 14rem;
    padding-left: 14rem;
    justify-content: space-between;
}

#getapp .content img{
    height: 39rem;
    position: relative;
    top: -3rem;
}
.app_btn a{
    display: flex;
    align-items: center;
}
.app_btn>div{
    background: black;
    border-radius: 14px;
    color: white;
    display: flex;
    
    justify-content: center;
    align-items: center;
    padding: 0.1rem;
    margin-top: 1rem;
    padding: 0.4rem 0rem;
}
.app_btn p{
    margin-bottom: 0px;
}
#getapp{
    padding: 0;
}
#getapp h1{
    font-size: 40px;
    letter-spacing: 4px;

}
#getapp .content>div{
    width: 50%;
}
#getapp .desc{
    font-size: 22px;

    
}
#getapp .app_btn>div{
    width: 15rem;
}
#getapp .app_btn i{
    font-size: 2rem;
    margin-right: 1rem;
}
footer,#footer {
    background-color: #d7f8ff;
    font-family: 'Work Sans', sans-serif;
    color: white;
    padding-bottom: 0px;
}

#footer>.footer-top .footer-info p, #footer>.footer-top ul, #footer>.footer-top a, #footer .footer-top h4, #footer .footer-top .footer-links ul a,footer>.footer-top .footer-info p, footer>.footer-top ul, footer>.footer-top a, footer .footer-top h4, footer .footer-top .footer-links ul a {
    color: #ccc8c8;
}

#footer .copyright, #footer .credits {
    text-align: start;
    padding-bottom: 2rem;
}

#footer .footer-top, #footer .container {
    background-color: #252525;
    border-bottom: none;
}

#footer .container {
    margin: 0px
}
#footer .footer-top .social-links a {
    background: unset;
}

.footer-top.mobile {
    display: none;
}
.papers_popup_container{
    position: absolute;
    padding: 2rem;
    background-color: rgba(0,0,0,0.9);
    right: 0;
    width: 100vw;
    top:0;
    transition: all 0.2s linear;
}
.papers_popup_container .papers{
    height: 90vh;
    overflow-y: scroll;
    width: 50%;
}
.one_paper{
    padding: 2rem;
    border-bottom: 1px solid lightgray;
}
.one_paper a{
    color: white;
    font-weight: 300;
}
.papers_popup_container.hide{
    right: 100%;
}
.all_categories{
    margin-left: 6rem;
}
.all_categories a{
    display: block;
    padding-bottom: 0.2rem;
}
.all_categories .head{
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 600;
    margin-bottom: 5px;
}
#param_goals{
    position: relative;
   
   
}
 /* upcmoming prod */
 #upcoming-products{
    background-color: var(--primary-color-blue__dark);
    color: var(--primary-color-white);

 }
 .all-upcoming-products__container{
    padding-top: var(--padding-tb__desktop);
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 20rem;
    transition: all 0.2s cubic-bezier(0.19, 1, 0.22, 1);
    max-height:30rem;
}
 .one-upcoming-product{
    padding: 1rem;
    display: grid;
 }
 .one-upcoming-product.active{
    color: white;
 }
 .one-upcoming-product__desc, .one-upcoming-product.active .read-more-btn{
    display: none;
 }
 
 .one-upcoming-product__title{
    font-family: var(--primary-typeface);
    font-weight: 600;
    font-size: 1rem;
    transition: all 0.3s ease;
 }
 .one-upcoming-product.active  .one-upcoming-product__title{
    font-size: 2rem;
 }
 .read-more-btn{
    align-self: flex-end;
    cursor: pointer;
    font-weight: 700;
    color: var(--primary-color-green);
 }
@media screen and (max-width:970px) {
    .kicker{
        font-size: small;
        letter-spacing: 3px;
    }
    #tech_hero .big-header-h1{
        font-size: 3.5em;
    }
    #header{
        position: fixed;
        background-color: transparent;
        padding: 1rem;
        width: 100%;
    }
    
    #hero_img{
        position: absolute;
        right: 0rem;
        opacity: 0.4;
        bottom: 0;
        top: 0;
        left: 0;
        height: 90vh;
        z-index: 2;
        transition: all 0.1s cubic-bezier(0.075, 0.82, 0.165, 1);

    }
    #tech_hero h1{
        font-size: 50px;
    }
    #how_recommendations{
        padding: var(--padding-tb__desktop);
    }
    .how_recms_illus{
        width: 100%;
    }
    #param_goals{
        position: relative;
        padding:0rem ;
        padding-top: 3rem;
       
    }
    .param_goal_map{
       
        padding: 2rem;
        padding-top: 2rem;
        padding-bottom: 0;
        
    }
    /* .parallax>section{
        padding: 3rem;
    } */
 .only_header{
        font-size: 2rem;
        text-align: center;  
       
     }
    .param_list{
        grid-column-start: 3;
    }
    .goal_list{
        width: 100%;
        padding-top: 0rem;
    }
    .goal{
        font-size: 1rem;
    }
    .goal_list .goal,.param_list .param{
        padding:0.3rem;
        background-color: rgba(255,255,255,0.5);
        display: flex;
        justify-content: center;
        align-items: center;
        color: gray;
    }
    .goal.active{
        color: black;
        -webkit-transform: scale(1.1);
                transform: scale(1.1);
    }
    #desktop_howrecm{
        display: none;
    }
    #param_goals{height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    }
    img.mobile_howrecm{
        display: block;
        
        width: 100%;
    }
    .bubble_svg svg{
        width: 100%;
    }
    #our_tech{
        padding: 3rem 1rem;
    }
    #our_tech .section_desc img {
        width: 100%;
        object-fit: cover;
        position: relative;
        
    }
    #our_tech h1{
        font-size: 1.4rem;
    }
    #getapp{
        padding: 0;
    }
    #getapp .content{
        padding: 1rem;
        flex-direction: column;
    
    }
    #getapp .content img{
        height: 15rem;
    }
    #getapp .content img{
        height: 21rem;
        position: relative;
        top: -3rem;
    }
    #getapp .app_btn>div {
        width: 10rem;
    }
    #getapp p.desc{
        display: none;
    }
    #getapp .content>div{
        width: 100%;
    }
    
    .footer-top.desktop {
        display: none;
    }
    .footer-top.mobile {
        display: block;
    }
    #footer .footer-top.mobile .social-links a {
        width: 20px;
        margin-right: 0px;
        height: 30px;
    }
    .section_desc{
        line-height: 25px;
        font-size: 17px;
        width: 100%;
    }
    .papers_popup_container .papers{
       
        width: 100%;
    }
    .all_categories{
        font-size: small;
    }
    .all_categories{
        margin-left: 0;
    }
    .social-links a{
        font-size: 1.5rem;
        margin-right: 1rem;
    }
    .all-upcoming-products__container{
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
        max-height: -webkit-fit-content;
        max-height: -moz-fit-content;
        max-height: fit-content;
    }
    .web_howrecm{
        display: none;
    }
    
    
}

.text-para{
    position: absolute;
    pointer-events: none;

    
    opacity: 0;
    color:white
}
#dip-text{
    
    top:7rem
}
#scan-text{
    
    top:10rem
}
#control-text{
    
    
    top:10rem
}
#app-tour-container{
    
    box-sizing: border-box;
    min-height: 100vh;
    background-color: var(--primary-color-blue__dark);
    color:white;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    overflow: hidden;
}
.app-tour__desc{
    position: relative;
    height: 100%;
    
}
#app-tour-container svg{
    fill: var(--primary-color-green);
    opacity: 0.1;
    pointer-events: none;
    position: absolute;
    height: 40rem;
    left: 6rem;
    bottom: 6rem;
}
.app-tour__desc .one-tour{
    position: absolute;
    top:0rem
}
.app-tour__desc .one-tour:nth-of-type(2){
    
    top:50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
}

.app-tour__desc .one-tour:nth-of-type(3){
    
    top:unset;
    bottom: 0;
}

.app-tour__illustration{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}
.app-tour__illustration > div{
    position: absolute;
    height: 40rem;
    
    color:black;
    display: flex;
    align-items: center;
    justify-content: center;
}
.one-tour__image img{
    height: 100%;
    object-fit: cover;
}
.one-tour__details .big-header-h1{
    padding-left: 20px;
    position: relative;
}
.one-tour__details .big-header-h1::before{
    content: '';
    position: absolute;
    left: 0;
    top:0;
    height: 100%;
    width: 7px;
    background-color: var(--primary-color-green);
}


@media screen and (max-width:700px) {
    
    
    #control-text,#scan-text,#dip-text{
   
        width: -webkit-fit-content;
   
        width: -moz-fit-content;
   
        width: fit-content;
        left:50%;
        top:5rem;
        -webkit-transform: translateX(-50%);
                transform: translateX(-50%);
    }
    #control-text{
      
        text-align: center;
       
        
    ;
    }
    .one-tour__details .big-header-h1::before{
        display: none;
    }
    #app-tour-container{
        grid-template-columns: 1fr;
        grid-template-rows: 20rem auto;

    }
    .app-tour__illustration{
        height: 19rem;
    }
    .app-tour__illustration > div{
        height: 18rem;
    }
    .one-tour__details h1{
        font-size: 1.5rem;
    }
    .app-tour__desc{
        position: relative;
        height: 100%;
    }
    .app-tour__desc .one-tour{
        position: absolute;
        top:1rem;
        text-align: center;
    }
    .app-tour__desc .one-tour:nth-of-type(2){
    
        top:1rem;
        -webkit-transform: unset;
                transform: unset;
    }
    
    .app-tour__desc .one-tour:nth-of-type(3){
        
        top:1rem;
        bottom: unset;
    }
}
@media screen and (max-width:320px) {
    .one-tour__details__desc{
        display: none;
    }
    .all-benefits-container {
        padding: 1rem 0rem;
        grid-template-columns: 7rem 7rem;
        grid-template-rows: 7rem 7rem;
    }
}


section#hero{
    display: grid;
    position: relative;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    color:var(--primary-color-white);
    background-color: var(--primary-color-blue__dark);
    background-repeat: no-repeat;
    background-position-x: -50%;
    background-position-y: 50%;
    height: 80vh;
    background-attachment: fixed;

}
section#hero::after{
    content: '';
    position: absolute;
    top:0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100vw;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(341.78deg, #131847 56.34%, #000749 67.57%, rgba(108, 97, 242, 0.89) 116.33%);
    opacity: 1;
}

section#hero >div{
    position: relative;
    z-index: 99;
}
#hero .all-links{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 2.5rem;
}
#hero .get-app-links,#hero .get-app-links *{
    display: flex;
    align-items: center;
    font-size: 2rem;
    color:white;
    grid-gap: 0.5rem;
    gap: 0.5rem;
}
section#hero svg{
    pointer-events: none;
    fill: var(--primary-color-green);
    opacity: 0.2;
    position: absolute;
    left:-15%;
    bottom: 0;
    width: 60%;
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
}
section#hero img{
    pointer-events: none;
}
#hero .buynow{
    display: flex;
    align-items: center;
    grid-gap:0.5rem;
    gap:0.5rem;
   
    font-size: 1.5rem;
    padding-top: 1rem;
}
#hero .buynow i{
    border: 4px solid var(--primary-color-green);
    border-radius: 50%;
    font-size: 2rem;
}
#wellness-card-container{
    pointer-events: none;
    max-height: 26rem;
}
#urine-cup{
    max-height: 18rem;
}
#phone-scan,#take-control{
    max-height: 40rem;
}

/* arameters */
section#parameters{
    position: relative;
    z-index: 99;
    margin-top: 100vh;
  
    background: var(--primary-color-blue__dark);
    

}
section#parameters h1,section#parameters h2{
    color: var(--primary-color-white);
    padding-top: 2rem;
    text-align: center;
}
section#parameters h2{
    position: relative;
    padding-left: 1rem;
    padding-top: 0;
    color: var(--primary-color-green);
    

}
/* section#parameters h2::before{
    content: '';
    height: 100%;
    width: 0.5rem;
    position: absolute;
    top:0;
    left: 0rem;
    background-color: var(--primary-color-green);
} */
section#parameters svg{
    position: absolute;
    top:0rem;
    right: 7rem;
    height: 17rem;
    
}
section#parameters svg *{
    fill: var(--secondary-color-purple__dark)!important;
    opacity: 0.7;
}
.parameters-container{
    padding-top: 2rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    grid-gap:2rem;
    gap:2rem
}
.each-parameter{
    min-width: 10rem;
    background-color: #070c34;
    color: var(--secondary-color-purple__light);
    border-radius: 25px;
    padding: 1.8rem 3rem;
    font-size: 1.5rem;
    font-weight: 400;
    display: flex;
    justify-content: center;
    flex-direction: column;
   
}
.each-parameter__icon{
    height: 100%;
    width: 2rem;
}
section#parameters .heading{
    margin-bottom: 2rem;
    
}
.allParams{
    display: grid;
    grid-template-columns: 1fr  ;
    flex-grow: 1;
    margin-top: 1rem;
    grid-gap: 2rem ;
    font-size: 1.5rem;
   
}
.allParams>div{
    display: grid;
    grid-auto-rows: 1fr;
    justify-items: self-start;
    grid-gap: 0.5rem;
}
.wellness-card-pinned{
    position: relative;
    height: 30rem;
}
.wellness-card-pinned img{
    position: absolute;
    height: 100%;pointer-events: none;
}
img.card{
    background-color: transparent;
}
.allParams img.card{
    height: 100%;
    object-fit: contain;
    width: 100%;
}

.oneparam{
    display: flex;
    
}
.oneparam img{
    margin-right: 1rem;
    
}


/* how it works */
section#how_it_works{
    padding: 3rem 10rem;
    background-color: white;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}
/* care kits */
section#care-kits{
    background-color: var(--primary-color-blue__dark);
    color: var(--primary-color-white)
}
.all-care-kits-wrapper{
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    justify-content: center;
    grid-gap: 2rem;
    gap: 2rem;
    padding-top: 2rem;
}
.one-care-kit{
    background-color: white;
    border-radius: 8px;
    padding: 2rem;
    color: var(--primary-color-blue);
    display: grid;
    grid-template-columns: 7rem auto;
    grid-auto-flow: column;
    grid-gap: 2rem;
    gap: 2rem;
    align-items: center;
    min-height: 7rem;
}
.one-carekit__image{
    height: 7rem;
    width: 7rem;
    display: grid;
    justify-items: center;
    align-content: flex-end;
    border-radius: 50%;
    order: -3;
    overflow: visible;

}
.one-carekit__image img{
    max-height: 7rem;
    width: 100%;
    object-fit: cover;
    position: relative;
    bottom: -0.7rem;

}
.one-carekit__name{
    font-family: var(--primary-typeface);
    font-size: 2rem;
    font-weight: 700;
}

/* get app */
section#get_app{
    
    height: 70vh;
    display: grid;
   
    grid-template-columns: 1fr 1fr;
    background:var(--secondary-color-purple);
    color:white;
}

#get_app img.app-card-img{
    max-height:70vh;
    position: relative;
   
}
#get_app .app_btns{
    padding-top: 1rem;
    display: grid;
    grid-gap:1.5rem;
    gap:1.5rem;
    grid-template-columns: 1fr 1fr;
    align-items: center;
}
#get_app .app_btns>a img{
    max-width: 80%;
    /* max-width: 100%; */
    /* max-height: 83px; */
}
#get_app .app_btns>a>div{
    display: flex;
    align-items: center;
}
#get_app .app_btns *{
    color:black
}
#get_app .app_btns i{
    font-size: 1.8rem;
}


/* benefits */
section#benefits{
    background-color: var(--primary-color-blue__dark);
    color:var(--primary-color-white);
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    
}
.all-benefits-container{
    position: relative;
    display: grid;
    padding: 2rem 4rem;
    grid-template-columns: 15rem 15rem;
    grid-template-rows: 15rem  15rem;
    margin-left: auto;
    margin-right: auto;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}
.all-benefits-container .neodocs-logo{
    position: absolute;
    
    top:50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}
.neodocs-logo img{
    height: 4rem;
}
.one-benefit{
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    justify-content: center;
    background-color: var(--secondary-color-blue__dark);
    border-radius: 50%;
    font-weight: 900;
}
.one-benefit img{
    height: 5rem!important;
}
.one-benefit{
    color:var(--primary-color-white)
}
.one-benefit__desc{
    display: none;
}




/* how it works */
section#how_it_works h1{
    text-align: center;
}
.hiwsteps{
    display: grid;
    margin-top: 2rem;
    padding: 2rem 15rem;
   
}
    

.hiw-onestep{
    display: grid;
    grid-template-columns: 1fr 0.5rem 1fr;
    grid-gap: 1rem;
    
}
.stepTitle{
    text-transform: uppercase;
    color:var(--primary-color-blue__dark)
}
.stepImage{
    width: 100%;
    height: 70%;
    margin-left: auto;
    margin-bottom: auto;
    margin-right: auto;
    object-fit: cover;
    font-size: 5rem;
    opacity: 0.3;}
    
.stepNumber{
    position: relative;
    
}
.step-path{
   
    width: 2px;
    background: black;
    position: absolute;
    left: 0.3rem;
}
.step-circle{
    position: absolute;
    top:0;
    left: 0;
    background-color: white ;
    border-radius: 50%;
    z-index: 3;
    width: 0.8rem;
    height: 0.8rem;
    border:3px solid #444444;
}
.stepNumber::before{
    content:'';
    
    width: 0.8rem;
    height: 0.8rem;
    opacity: 0.4;
    position: absolute;
    top:0;
    left: 0;
    background-color: #b1b1b1 ;
    border-radius: 50%;
    z-index: 3;
    border:3px solid #b8b8b8;
}
.stepNumber::after{
    content: '';
    position: absolute;
    height: 100%;
    /* width: 4px; */
    opacity: 0.3;
    border-right: 2px dashed #444444;
    left: 89%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    z-index: 2;
}
.step4 .stepNumber::after{
    opacity: 0;
}
.stepDesc{
    font-size: small;
    font-weight: 300;
    padding-bottom: 6rem;
    
}
.stepTitle{
    font-weight: 700;
    font-size: large;
    padding-bottom: 0.5rem;
    color: #0f151a;
}
#phone-mockup img{
    height: 25rem;

}

.wellness-card-intro{
   position: relative;
}
.wellness-card-intro h1{
    font-size: 5rem;
    
    color:var(--primary-color-blue)

}
.wellness-card-intro>div{
    display: flex;
    flex-direction: column;
    justify-content: center;
}
#hero .illus {
    position: relative;
}
#hero img{
    pointer-events: none;
    position: absolute;
    top: -10rem;
    /* left: 0; */
    height: 51vh;
    -webkit-transform: rotate(30deg);
            transform: rotate(30deg);
    /* transform: translateX(7%); */
    margin-bottom: 0px;
    right: 9rem;
}


@media screen and (max-width:500px) {
    
    
    section#hero{
        
        grid-template-columns: 1fr;
        justify-content: unset;
        padding-bottom: 0;
        align-items: start;
        background-position: center;
        height: 90vh;
    }

    section#hero .action-btn__primary{
        padding: 0.8rem 2rem;
        margin-top: 1rem;
    }
    #hero .all-links{
        margin-top: 1rem;
    }
    #hero .get-app-links,#hero .get-app-links *{
        display: none;
    }
    section#hero>div.illus{
        order:3;
        position:relative
    }
    
    
    
    
    #hero img{
        position: absolute;
        top: -13rem;
        /* left: 0; */
        height: 51vh;
        -webkit-transform: rotate(30deg);
                transform: rotate(30deg);
        /* transform: translateX(7%); */
        margin-bottom: 0px;
        right: 1rem;
    }
    
    #hero .app_links{
        display: none;
    }
    section#parameters svg{
        position: absolute;
        top:0rem;
        right: 0rem;
        height: 10rem;
    }
    .parameters-container{
        width: 100%;
    justify-content: center;
    display: grid;
    grid-template-columns: 1fr 1fr;
    
    }
    .each-parameter{
        padding: 1.5rem;
        font-weight: 200;
        font-size: 1.1rem;
    }
    
    
    section#how_it_works{
        padding: 3rem 1rem;
    }
    
    section#benefits{
        grid-template-columns: 1fr;
        align-items: unset;
    }
    
    section#get_app{
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        grid-gap: 1rem;
        grid-template-columns: 1fr;
        padding-bottom: 0;
    }

    #get_app img.app-card-img{
        height: 100%;
        width: 100%;
        object-fit: cover;
        margin-right: 1rem;
        top:0
    }
    
    #get_app h1{
        text-align: unset;
        
    }
    #get_app .desc{
        display: none;
        
    }
    #testimonials_grid {
        padding-top: 0rem;
        grid-template-rows: 0.5fr 1fr 0.5fr 0.5fr;
        margin-right: 0rem;
        margin-left: 0rem;
    }
    #testimonials_grid #join {
        grid-column: span 5;
        grid-row-start: 1;
    }
    #testimonials_grid #declaration {
        position: relative;
        background-color: unset;
        grid-column: span 5;
        grid-row-start: 2;
        width: 100%;
        z-index: 10;
        font-size: 15px;
    }
    #testimonials_grid #test2, #test8 {
        grid-column: span 2;
    }
    #testimonials_grid #declaration {
        position: relative;
        background-color: unset;
        grid-column: span 5;
        grid-row-start: 2;
        width: 100%;
        z-index: 10;
        font-size: 15px;
    }
    #testimonials_grid .actionBtn{
            background-color: white;
            color: black;
            margin-left: auto;
            margin-right: auto;
            border-radius: 25px;
            margin-bottom: 0rem;
            text-transform: uppercase;
            letter-spacing: 2px;
            width: 16rem;
        }
       
        .hiwsteps{
            padding: 0rem;
        }
        .stepDesc{
            padding-bottom: 5rem;
        }
        .neodocs_hero {
            width: unset;
            height: 55vh;
            margin-top: 15vh;
            position: relative;
            z-index: 10;
        }
        div.heading{
            order:-2;
        }
        #join > p{
            text-align: center;
        }
        /* benefits */
        .all-benefits-container {
           
            padding: 2rem 0rem;
            grid-template-columns: 10rem 10rem;
            grid-template-rows: 10rem 10rem;
        }
        .one-benefit p{
            font-size: 13px;
            font-family: var(--primary-typeface);
        }
        .one-benefit img {
            height: 3rem!important;
        }
        .neodocs-logo img{
            height: 3rem;
        }
        .wellness-card-intro h1{
            font-size: 3rem;
            
            
        
        }
        #wellness-card-container{
            
            max-height: 20rem;
        }
        #urine-cup{
            max-height: 15rem;
        }
        #phone-scan,#take-control{
            max-height: 30rem;
        }
        .all-care-kits-wrapper{
            grid-template-columns: 1fr;
        }
        .one-care-kit{
            padding: 1rem
        }
        .one-carekit__name{
            font-size: 1.4rem;
        }
    
}
@media screen and (max-width:320px) {
    #hero img{
        top:-7rem
    }
    .all-benefits-container {
        padding: 1rem 0rem;
        grid-template-columns: 8rem 8rem;
        grid-template-rows: 8rem 8rem;
    }
    .one-benefit img {
        height: 2.5rem!important;
    }
    #hero .buynow {
       
        font-size: 1.2rem;
        
    }
    #wellness-card-container{
        max-height: 15rem;
    }
    #urine-cup{
        max-height: 10rem;
    }
    #phone-scan, #take-control{
        max-height: 22rem;
    }
    #app-tour-container{
        grid-template-rows: 16rem auto
    }
    .app-tour__illustration > div{
        height: 16rem;
    }
}
.future-plans-conatiner-full{
    padding: 5rem 30rem;
    background-color: var(--primary-color-blue__dark);
    color: var(--primary-color-white);
}
.future-plans-conatiner-full>h1{
    font-size: 5rem;
    color: rgb(199, 199, 199);
}
.one-plan-box{
    position: relative;
    height: 20rem;
    /* background-color: var(--primary-color-green__light); */
    padding: 2rem;
    width: 20rem;
    z-index: 20;
    border-radius: 12px;
    color: var(--primary-color-blue);
    line-height: unset;
}
.one-plan-box svg.blob{
    position: absolute;
    top:0;
    left: 0;
    bottom: 0;
    right: 0;
}
.plan-right-box{
    margin-left: auto;
}
.one-path-box{
    height: 20rem;
    position: relative;
}
.plan-name{
    
    font-weight: 800;
    font-family: var(--primary-typeface);
}
.white-cover{
    position: absolute;
    top:0;
    height: 100%;
    width: 100%;
    background-color: var(--primary-color-blue__dark);
    z-index: 10;
}
@media screen and (max-width:900px) {
    .future-plans-conatiner-full{
        padding: 5rem 2rem;
    }
    .one-plan-box{
        height: 20rem;
        
        padding: 1rem;
        width: 90%;
    }
}


.faqHead{
    background-color: var(--primary-color-blue__dark);
    color: var(--primary-color-white);
}



.onefaq{
    padding: 1rem;
    border-bottom: 2px solid var(--primary-color-white);
    position: relative;
    z-index: 6;
}
.onefaq_question{
    width: 100%;
    font-weight: 500;
    font-size: 1.1rem;
    position: relative;
    padding-bottom: 1rem;
    cursor: pointer;
}
.onefaq_question:hover{
    font-weight: 700;
}
.onefaq_question::after{
    content: '+';
    position: absolute;
    right: 0;
    
}
.onefaq_question.open::after{
    content: '-';
    position: absolute;
    right: 0;
}
.faqHead{
    position: relative;
    height: 15rem;
}
.allFaqs img{
    position: absolute;
    top: 0;
    right: -6rem;
    height: 25rem;
    -webkit-transform: translateY(-83.3%);
            transform: translateY(-83.3%);
    z-index: 2;
}
.allFaqs{
    position: relative;
    background-color: rgba(255, 255, 255, 0.924);
    position: relative;
    z-index: 7;
    padding-top: 2rem;
    border-radius: 2px;
}



.faqHead{
    background-color: var(--primary-color-blue__dark);
    color: var(--primary-color-white);
}


.contactus {
    width: 500px;
    margin: 0 auto;
    padding: 20px;
  }
  
.contactus input,
.contactus textarea {
display: block;
padding: 10px 20px;
width: 100%;
margin: 20px 0px;
}

textarea {
    height: 200px;
}

.contactus button {
    background-color: #000;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.contactus-hidden {
    display: none;
  }
  
.contactus-success {
display: block;
}

/* svg */



.hero{
    height: 60vh;
    background-color: var(--secondary-color-purple__dark);
    color:white;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: calc(var(--padding));
    
}


.ourBlogs,.science{
    background-color: white;
}

section.investors{

    padding-top: 2rem;
    padding-bottom: 2rem;
    background-color: white;
}
.ourStory{
 
    background-color: white;
    font-weight: 400;
    display:grid;
    grid-template-columns:0.5fr 1fr
}
.ourStory h1{
    font-size: 5.5rem;
    color: var(--secondary-color-purple__light);
}

/* team */
section.team{
    background-color: var(--secondary-color-purple__dark);
    color:var(--primary-color-white);
    min-height: 70vh;
    position: relative;
}
section.team svg{
    position: absolute;
    height: 14rem;
    top:0;
    left:0;
    opacity: 0.3;
}
.team-members-container{
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap:2rem;
    gap:2rem;
    padding-top: 2rem;
}
.one-team-member__image{
    height: 20rem;
    width: 90%;

    
}
.one-team-member__image img{
    height: 100%;
    width: 100%;
    object-fit: cover;
    -webkit-filter: grayscale(0.3);
            filter: grayscale(0.3);
}
.one-team-member__meta{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.one-team-member__meta__name{
    font-family: var(--primary-typeface);
    font-weight: 800;
}
.textsHeads{
    display: flex;
    justify-content: center;
    align-items: center;  
}



.investors_container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-content: center;
    grid-gap: 5rem;
    margin-top: 3rem;
    justify-items: center;
}
.investor img{
    height: 2rem;
   
    object-fit: cover;
}
/* our focus */
section#our-focus{
    background-color: var(--secondary-color-purple__dark);
    position: relative;
    display: grid;
    grid-template-columns: 15rem auto;
}
img#our-focus__mobile{
    display: none;
}
img#our-focus__web{
    width: 80%;
    margin-right: auto;
    margin-left: auto;
    z-index: 7;
}
img#our-focus__people{
    max-height: 100vh;
    position: absolute;
    bottom: 0;
    right: 2rem;
    z-index: 6;
}
#our-focus__text-wrap .big-header-h1{
    font-size: 2.3rem;
}
#our-focus__text-wrap{
    color: white;
    font-size: 2.3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    align-self: flex-end;
    padding-bottom: 1rem;

}
#text-arrow{
    border: 2px solid white;
    border-radius: 50%;
    width: 3rem;
    height: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    order: 2;
}
#text-arrow .bx-up-arrow-alt{
    display: none;
}
/* contect us */
section.contactUs {
    background-color: var(--secondary-color-purple__dark);
    color: var(--primary-color-white);
    display: grid;
    grid-template-columns: 1fr 1fr;
 
    align-items: center;
    grid-gap: 2rem;
    gap: 2rem;

}
.contactUs .socials{
    display: grid;
    grid-template-columns: 1fr 1fr;
    color: var(--primary-color-white);
    align-items: center;
    justify-items: center;
    grid-gap: 0.5rem;
    gap: 0.5rem;
}

.contactUs .socials a{
    color: var(--primary-color-white);
    font-size: 3rem;
}
.newsletter {
    display: flex;
}
.newsletter input {
    height: 3rem;
    border: none;
    outline: none;
}

/* blogs */
.ourBlogs{
    background-color: var(--secondary-color-purple__dark);
    max-height: unset;
    display: grid;
    grid-template-columns: 1fr 0.5fr;
    grid-gap: 1rem;
    gap: 1rem;
    position: relative;
    overflow: hidden;
}
.ourBlogs svg{
    position: absolute;
    height: 15rem;
    right:-10rem;
    top:-2rem;
    pointer-events: none;
}
.ourBlogs svg *{
    fill:var(--secondary-color-blue__light);
    opacity: 0.7;
}
.ourBlogs h2{
    color:var(--primary-color-white)
}
.blogs{
    position: relative;
   
}
.allBlogs{
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
    grid-gap: 2rem;
}
.blog_img{
    position: relative;
    height: 15rem;
}
.blog_img::after{
    content: '';
    position: absolute;
    top:0;
    left: 0;
    bottom: 0;
    right: 0;

    background: linear-gradient(to bottom, transparent, rgba(0,0,0,0.7));
}
.blog_img img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.ourBlogs .blog_title{
    position: absolute;
    font-family: inherit;
    bottom: 1rem;
    padding: 0 1rem;
    color: lightgrey;
    font-size: 1rem;
    font-weight: 500;
    letter-spacing: unset;
    line-height: unset;
}
.blog_title i{
    text-align: center;
    padding: 0;
}


@media screen and (max-width:500px) {
    
    
    
    section.science{
        padding: 1rem;
        padding-bottom: 3rem;
        padding-top: 0;
    }
    
    .ourStory{
        padding-top: 2rem;
        grid-template-columns: 1fr;
    }
    .ourStory h1{
        font-size: 4rem;
        color: var(--secondary-color-purple__light);
    }
    section.team h2::before {
        content: '';
        display: inline;
        width: 4rem;
        height: 3px;
        background-color: white;
    }
    .textsHead {
        grid-column-start: 1;
        grid-row-start: 1;
    }
    
    .investors_container{
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 1rem;
    }
    .investor {
        margin: 0;
        padding-bottom: 4rem;
        justify-items: center;
    }
    .investor img {
        height: 3rem;
        width: 100%;
        object-fit: contain;
    }
    .allBlogs{
        grid-auto-flow: row;
    }
    .team-members-container{
        grid-template-columns: 1fr;
    }
    .one-team-member__image{
        width: 100%;
        height: 19rem
    }
    section.contactUs{
        grid-template-columns: 1fr;
    }
    .contactUs .socials{
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    }
    .ourBlogs{
        grid-template-columns: 1fr;
    }
    .ourBlogs h2{
        order: -3;
        position: relative;
        z-index: 7;
    }
    section#our-focus{
        grid-template-columns: 1fr;
        padding-bottom: 17rem;
        grid-gap: 1rem;
        gap: 1rem;
    }
    img#our-focus__mobile{
        display: block;
        z-index: 8;
        width: 80%;
        margin-right: auto;
        margin-left: auto;
    }
    img#our-focus__web{
        display: none;
    }
    img#our-focus__people{
        max-height: 18rem;
        left:-1rem;
        right: 0;
        
    }
    
    #our-focus__text-wrap .big-header-h1{
        font-size: 2.3rem;
    }
    #our-focus__text-wrap{
        order: 3;
    
    }
    #text-arrow{
        border: 2px solid white;
        border-radius: 50%;
        width: 3rem;
        height: 3rem;
        display: flex;
        justify-content: center;
        align-items: center;
        order: 2;
    }
    #text-arrow .bx-right-arrow-alt{
        display: none;
    }
    #text-arrow .bx-up-arrow-alt{
        display: block;
    }
}

input[type='text'].input,
input[type='number'].input{
    height: 3rem;
    padding: 0.5rem;
    background-color: white;
    border: none;
    outline: none;
    border-radius: 12px;
    min-width: 10rem;
    justify-content: center;
    cursor: pointer;
    color: black;
}
input[type='text'].input:focus,input[type='number'].input:focus{
    border:3px solid var(--secondary-color-purple)
}
label.inputWrap{
    cursor: pointer;
    min-width: 10rem;
    display: flex;
    background-color: rgb(241, 241, 251);
    padding: 0.8rem;
    margin-top: 0.5rem;
    border-radius: 12px;
    transition: all 0.2s linear;
    justify-content: center;
    align-items: center;
    
}
.inputWrap p{
    color: var(--primary-color-blue);
}
label.inputWrap.active{
    border:3px solid var(--secondary-color-purple);
}
input[type='radio'].input,input[type='checkbox'].input{
    height: 0;
    width: 0;
}

.diet_slider {
    width: 80%;
    position: relative;
}
.diet_slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 25px;
    height: 25px;
    border-radius: 50%; 
    background: #04AA6D;
    cursor: pointer;
  }
  
  .diet_slider::-moz-range-thumb {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: #04AA6D;
    cursor: pointer;
  }

#diet_range {
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    height: 10px;
    background: white;
    outline: none;
    opacity: 0.7;
    border-radius: 25px;
    transition: opacity .2s;
}
.options{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
}
.slider_label {
    position: absolute;
    top:3.3rem;
}


/* keyboard inputs */
.keyboard-input{
    display: flex;
    padding-top: 1.6rem;
    position: relative;
}
.keyboard-input__label{
    position: absolute;
    left: 0%;
    top: 0;
}
.keyboard-input__inputbox{
    border: none;
    outline: none;
    height: 3rem;
    padding-left: 1rem;
    background: white;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    width: 90%;
}
.keyboard-input__input-phase-container{
    background: lavender;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    width: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.keyboard-input .bxs-check-circle{
    color: rgb(91, 179, 91);
    font-size: 1.3rem;
}
@media screen and (max-width:900px) {
    label.inputWrap{
        padding: 0.8rem;
    }
}
.personalised_pricing_quiz{
    box-sizing: border-box;
    height: 100vh;
    background-color: var(--primary-color-blue__dark);
    padding: 3rem 0rem;
    overflow-x: hidden;
    color:var(--primary-color-white)
}
.personalised_pricing_quiz .quiz-slider{
    display: grid;
    /* overflow-x: hidden; */
    grid-auto-flow: column;
    grid-auto-columns: 100%;
    -webkit-transform: translateX(0);
            transform: translateX(0);
    position: relative;
    left: 0%;
}
.button-container{
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
    grid-gap: 2rem;
    width: 25%;
    margin-left: auto;
    margin-right: auto;
    justify-items: center;
    align-items: center;
}


.page{
   
    width: 100vw;
    min-height: 50vh;
    box-sizing: border-box;
    display: flex;
    justify-content: start;
    align-items: center;
    flex-direction: column;
    padding: 0rem 27rem;
}
img.questionInfographic{
    max-height: 5rem;
}
.oneQuestion{
    margin-bottom: 1rem;
}
.question{
    font-size: 2rem;
    text-align: center;
    padding-bottom: 1rem;
}
.inputWrap p{
    margin-top: 0rem !important;
}

@media screen and (max-width:900px) {
    .personalised_pricing_quiz{
        padding: 0;
    }
    .button-container{
        width: 100%;
    }
    .page{
        padding: 1rem;
    }
}
.partners{
    min-height: 100vh;
    
    padding: 7rem;
    padding-top: 8rem;
    background-color: var(--primaryColor);
}
.one-partner{
    border-bottom: 1px solid rgb(243, 243, 243);
    padding: 1rem;
    padding-left: 0;
    cursor: pointer;
}
.partner-type{
    font-size: 24px;
    font-weight: 500;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 0.5rem;
}
.partner-desc{
    letter-spacing: 0.5px;
}
.dropdown-content.hide{
    display: none;
}
@media screen and (max-width:900px) {
    .partners{
        padding: 1rem;
        padding-top: 5rem;
    }
}
.privacyhero{
    min-height: 70vh;
    display: flex;
    
    background-color: var(--primary-color-blue__dark);
    position: relative;
    color:var(--primary-color-white)
    
}


.privacyhero>div{
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 10;
  
    
}


.privacyhero .info{
    padding-right: 3rem;
}
.control{
 
    display: flex;
    align-items: center;
    background-color:white;
    grid-gap:2rem;
    gap:2rem
}

.control h2{
    color: #1d1d47;
    font-weight: 700;
    font-size: 50px;
    padding-bottom: 0.5rem;
}
.control .desc{
    font-size: 25px;
}
.one_check{
    font-size: 18px;
    font-weight: 300;
    padding-top: 1rem;
}
.one_check::before{
    content: "\ecab";
    font-family: 'boxicons'!important;
    font-size: 2rem;
    padding-right: 0.3rem;
    color: rgb(139, 194, 139);
    position: relative;
    top:0.4rem;
}

@media screen and (max-width:1000px) {
    .privacyhero{
        flex-direction: column;
        padding-top: 5rem;
        
        justify-content: center;
        align-items: center;
        text-align: start;
        padding: 2rem;
    }
    .privacyhero h1{
        font-size: 45px;
        line-height: 55px;
        text-align: start;
    }
    .privacyhero h5{
        width: 100%;
        font-size: 1rem;
        font-weight: 400;
    }
    .privacyhero .info{
        padding-right: 0;
    }
    .privacyhero svg{
        width: 100%;
    }
    .control{
     
        flex-direction: column;
    }
    .control>div{
        padding: 0;
    }
    .control h2{
        font-size: 45px;
    }
    .control .desc{
        font-size: 20px;
    }
}
@media screen and (max-width:500px) {
    .privacyhero{
        height: 85vh;
    }
}
.privacy-heading{
    background-color : var(--primary-color-blue__dark);
    color:var(--primary-color-white);
    position: relative;
}
.privacy-heading svg{
    height: 100%;
    position: absolute;
    right:7rem;
    top:0rem;
    opacity: 0.2;
}


ul.information-ul{
    list-style:disc !important;
    
}
.part-heading{
    padding-top: 2rem;
}
.privacy-container{
    padding-top: 2rem;
    padding-bottom: 2rem;
}

.tos-heading{
    background-color : var(--primary-color-blue__dark);
    color:var(--primary-color-white);
    position: relative;
}
.tos-heading svg{
    height: 100%;
    position: absolute;
    right:7rem;
    top:0rem;
    opacity: 0.2;
}
.terms-container{
    padding-top: 2rem;
}

ul.information-ul{
    list-style:disc !important;
    
}





.terms-heading{
    font-weight: 800px;
    
}

.unordered-list{
    padding-top: 1rem;
    list-style: disc !important;
}
.ordered-list{
    padding-top: 1rem;
    padding-left: 0rem;
}
.list-items{
    display: list-item;
    padding-bottom: 1rem;
}


/* color definition for each service */
.uti-service{
    --primary-color: #FFF6FA;
    --primary-color__light:#502f4c;
    --primary-color__dark: #5F2E59;
    --text-color__pink: pink;
    --text-color__white:white;
}
.pregnancy-service{
    --primary-color: #F7F3FF;
    --primary-color__light:#6e5497;
    --primary-color__dark: #61468D;
    --text-color__pink: pink;
    --text-color__white:white;
}
.ckd-service{
    --primary-color: #FFFAF8;
    --primary-color__light:#aa5a4c;
    --primary-color__dark: #d36450;
    --text-color__pink: pink;
    --text-color__white:white;
}
.elderlycare-service{
    --primary-color: #F3F9FF;
    --primary-color__light:#345880;
    --primary-color__dark: #426C9C;
    --text-color__pink: pink;
    --text-color__white:white;
}
.service-container #header{
    background-color: var(--primary-color__dark)!important;
}
.servicesHero{
    height: 60vh;
    position: relative;
    background-color: var(--primary-color__dark);
    display: flex;
}

.service-container .big-header-h1{
   font-size: 3.5rem; 
}
.servicesHero .text{
    color: var(--text-color__white);
    /* height: 100%; */
    display: grid;
    width: 80%;
    align-items: center;
    align-content: center;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    
}

.servicesHero img{
    padding-top: 10%;
    height: 150%;
}

.servicesHero #header{
    background-color: var(--primary-color);
}
.service-container .products-nav .dropdown-container{
    background-color: var(--primary-color);
}
.service-container .action-btn__primary{
    background-color: var(--primary-color);
    color:var(--primary-color__dark);
    width: 12rem;
    margin-right: auto;
}
.service-features{
    background-color: var(--primary-color__dark);
    color:var(--text-color__white);
    display: grid;
    grid-gap: 2rem;
    gap: 2rem;
    grid-auto-flow: column;
    padding-top: 5rem;
    padding-bottom: 5rem;
}
.one-service-feature{
    display: grid;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    
    justify-content: center;
    justify-items: center;
    text-align: center;
}
.one-service-feature img{
    height: 4rem;
    opacity:0.8;
}
.one-feature__description{
    font-size: 1.5rem;
    font-weight: 300;
}
.one-feature__illustration svg{
    height: 3rem;
    opacity: 0.7;
}
/* tetsimonials */
.service-container #testimonials{
    background-color: var(--primary-color__dark);
}
.service-container .testimonial-card{
    background-color: var(--primary-color__light);
}
.service-container .testimonial-autor-designation{
    color:var(--text-color__pink)
}
.service-container .community-illustration__person{
    border: 3px solid var(--primary-color__light);
}

.service-container .community-illustration__person.you_div{
    border: 3px dashed var(--primary-color);
    background-color: var(--primary-color__dark);
}
.how-it-works-step-container{
    display: grid;
    padding-top: 2rem;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 3rem;
    gap: 3rem;
}
.how-it-works{
    background-color: var(--primary-color__dark);
    color: white!important;
}
.how-it-works-step-container .hiw-one-step{
    height: 12rem;
    font-size: 1.5rem;
    width: 100%;
    padding-top: 0;
    position: relative;
    display: grid;
    position: relative;
    grid-gap: 1rem;
    gap: 1rem;
    grid-template-rows: 4rem auto;
    text-align: center;
    justify-content: center;
    font-weight: 600;
    font-family: var(--primary-typeface);
    
}
.how-it-works .big-header-h1{
    text-align: center;
    margin-right: auto;
    margin-left: auto;
}
.hiw-one-step .step-circle{
    position: relative;
    width:4rem;
    height:4rem;
    display: grid;
    align-items: center;
    justify-content: center;
    margin-right: auto;
    margin-left: auto;
    border-color: var(--primary-color__dark) ;
    color:var(--primary-color__dark)
}
.hiw-one-step .step-path{
    left: 50%;
    background-color: white;
    height: 2px;
    width: 100%;
    position: absolute;
    top: 2rem;
}
.doctors{
    display: grid;
    grid-auto-flow: column;
    grid-gap: 2rem;
    gap: 2rem;
}
.doctor-card.testimonial-card{
    background-color: white;
    padding: 1rem;
    border-radius: 8px;
    width: 100%;
    grid-template-rows: 4rem auto;
}
.doctor-Img{
    display: flex;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    align-items: center;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}
.doctor-Img img{
    height: 4rem;
    width: 4rem;
    border-radius: 50%;
    border: 2px solid var(--primary-color__dark);
}
.doctor-Note{
    position: relative;
    padding-left: 2.5rem;
    padding-top: 2rem;
}
.doctor-Note::before{
    content: "\eb75";
    position: absolute;
    font-family: boxicons!important;
    opacity: 0.4;
    font-size: 3.5rem;
    color:var(--primary-color__dark);
    left: 0rem;
    top: -0.5rem;
}
.doctor-Name{
    font-weight: 600;
    font-size: 25px;
    color:var(--primary-color__dark)
}
section.symptoms{
    display: grid;
    grid-template-columns: 1fr 1fr;
    background-color: var(--primary-color);
}
section.symptoms ul{
    padding-left: 0;
    display: grid;
    grid-gap:1rem;
    gap:1rem;
    padding-top: 1rem;
    
}
section.symptoms i{
    color:var(--primary-color__dark)!important;
    font-size: 2rem !important;
}
section.symptoms li{
    font-size: 1.3rem !important;
    display: flex;
    
}
section.symptoms .image img{
    height: 20rem;
}
.parameters-detected{
    display:grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;
    gap: 1rem;
}
.parameters-detected .imageContainer{
    display: grid;
    padding-top: 2rem;
    justify-content: center;
    position: relative;
}
.imageContainer svg{
    position: absolute;
    height: 20rem;
  
    z-index: -1;
    left: 0.8rem;
    opacity: 0.5;
    top: 0rem;
}
.imageContainer svg *{
    fill: var(--primary-color__dark);
}
.parameters-detected img{
    height: 30rem;
}
.parameteres-detected-container{
    padding-top: 5rem;
    display: grid;
   grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;
    gap: 1rem;
}
.one-parameter-detected{
    font-size: 2rem;
    background: var(--primary-color);
    box-shadow: 1px 2px 8px var(--primary-color);
    padding: 0.5rem;
}
.one-parameter-detected__name{
    font-size:25px;
    font-weight:600;
    color:var(--primary-color__dark)
}
.one-parameter-detected__description{
    font-size: 14px;
}
.doctor-section{
    background-color: var(--primary-color);
    display: none;
}

@media screen and (max-width:500px) {
    .servicesHero{
        min-height: 60vh;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        display: block;
    }
    .servicesHero img{
        padding: None;
        height: 400px;
    }
    .service-container .big-header-h1{
        font-size: 2.8rem;
    }
    .service-features{
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        grid-gap: 3rem;
        gap: 3rem;
        border-top: 2px solid var(--primary-color);
    }
    .one-service-feature img{
        height: 3rem;
    }
    .one-feature__description{
        font-size: 1.1rem;
    }
    .one-parameter-detected{
        font-size: 1rem;
        
    }
    section.symptoms{
        grid-template-columns: 1fr;
    }
    section.symptoms .image{
        display: none;
    }
    .parameters-detected{
        grid-template-columns: 1fr;
    }
    .parameteres-detected-container{
        grid-template-columns: 1fr;
        grid-auto-flow: unset;
        padding-top: 1rem;
    }
    .one-parameter-detected__description{
        font-size: 14px;
    }
    .parameters-detected img{
        height: 25rem;
        padding-top: 0;
    }
    .imageContainer svg {
        
        height: 14rem;
      
        left: -2rem;
        
    }
    .doctors{
        grid-auto-flow: row;
    }
    .how-it-works-step-container{
        grid-template-columns: 1fr;
        grid-gap: 2rem;
        gap: 2rem;
    }
    .how-it-works-step-container .hiw-one-step {
        height: 12rem;
        font-size: 1.5rem;
        padding-left: 0rem;
        padding-top: 0;
        position: relative;
        display: grid;
        grid-auto-flow: column;
        
        grid-template-rows: 1fr;
        grid-template-columns: 4rem auto;
        grid-gap: 1rem;
        gap: 1rem;
        justify-content: unset;
        text-align: unset;
    }
    
    .hiw-one-step .step-path{
        width: 0px;
        display: none;
    }
    .doctor-card.testimonial-card{
        width: 17rem;
    }
    .doctor-Note{
        font-size: small;
    }

}
.uploadProfile{
    padding: 5rem 1rem;
}
.uploadReportBtn{
      
    width: 17rem;
    height: 3.5rem;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    background-color: rgba(0,0,0,0.9);
    border-radius: 18px;
    color:white;
    margin-top: 2rem;
    text-transform: uppercase;
    box-shadow: 1px 1.2px 5px #4a4a4ac2
}
.uploadProfile label{
    display: flex;
    justify-content: center;
    align-items: center;
}
.uploadReportBtn p{
    display: flex;
    align-items: center;
}
.uploadReportBtn i {
    font-size: 1.4rem;
    margin-right: 1rem;
}
.red_bar{
    background-color: #f65e5b;
}
.yellow_bar{
    background-color: #fec760;
}
.green_bar{
    background-color: #23e5af;
}
.test_bars {
   
    position: relative;
    margin-bottom: 20px;
}
.percentage-bar{
   
        margin-top: 10px;
        margin-bottom: 27px;
        display: flex;
        width: 100%;
        border-radius: 10px;
        overflow: hidden;
    
}
.bar_pointer {
    position: absolute;
    width: 0;
    height: 0;
    z-index: 2;
    -webkit-transform: translate(-50%, 0%);
            transform: translate(-50%, 0%);
    top: 24px;
    /* transform: translate(-50%, 78%); */
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid rgb(74, 136, 218);
}
.percentage-bar__bar{
    height: 12px;
    position: relative;
}
.pointer__value {
    position: absolute;
    bottom: -30px;
    font-size: 12px;
    font-weight: 500;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    color: black;
}
.panelInfoDetails{
    background-color: white;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    width:100%;
    border-bottom-right-radius: 0px;
    border-top-right-radius: 0px;
    position: relative;
    
}
.panelInfoDetails .wrap::after{
    content: '';
    position: absolute;
    top:0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(255,255,255,0.9);
}
.panelInfoDetails ul{
    z-index: 3;
}
.panelInfoDetails .wrap>*{
    position: relative;
    z-index: 3;
}

.panelWrapper{
    display: flex;
    margin-top: 1rem;
}
.panelContainer{
    margin:1rem;
}

.panelWrapper > div{
    width:100%;
}
.panelElement:last-of-type{
    margin-bottom: 6rem;
    
}
/* .panelWrapper_risk::after{
    content: '';
    position: relative;
    width:7px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;

    background-color:#F65E5B ;
} */
.panelWrapper_Safe::after{
    content: '';
    position: relative;
    width:7px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;

    background-color: #23E5AF;
}
.wrap{
    padding:1.2rem;
}
.panelHead{
    display: flex;
    font-family: 'Raleway','sans-serif';
    justify-content: space-between;

}
.bio_read_more{
    padding-top: 1rem;
    text-transform: uppercase;
    font-weight: 900;
    color: #000646;
    margin-left: auto;
    display: flex;
    justify-content: flex-end;
}
.biomarker-title{
    font-weight:700;
    font-size: large;
    color:#212121;
    font-size: 21px;
}
.panelIcon{
    width:3rem;
    height: 3rem;
    margin-right: 1rem;
}
.date{
    color:#888888;
    margin-top:2px;
}
.note{
    text-transform:uppercase;
    font-weight: 600;
    margin-top:4px;
    display: flex;
    align-items: center;
}


.small{
    font-size: small;
}


.note_borderline{
    text-transform: uppercase;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 1px 6px;
    background-color: #f8dca9;
 
    
}
.note_borderline::before{
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background-color: #FFB429;
    content: "";
    margin-right:4px;
}
.note_risk{
    background: #f6d0d0;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 1px 6px;
}
.note_risk::before{
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background-color: #f96b68;
    content: "";
    margin-right:4px;
}
.note_safe{
    background: #c6edc6;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 1px 6px;
}
.note_safe::before{
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background-color: #23E5AF;
    content: "";
    margin-right:4px;
  
}
.getRecommendations{
  
    color:#0457b0;
    font-weight: bold;
    
    
}
.panelDetailCont{
    display: flex;
    align-items: center;
}
.panelScore{
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.panelScore>p{
    font-size: x-small;
}
.panelScore>h3{
    font-weight: 800;
    margin-bottom: 0px;
}
.qualitativeScore{
    border-radius: 50%;
    
}

.score_risk{
    color:#FF5F45
}
.score_borderline{
    color:#FFB429
}
.score_good{
    color:rgb(35, 229, 175)
}
.summary{
    margin-top:1rem;
    margin-bottom: 0.5rem;
}

.summaryHead{
    
    font-size: 13px;
    color: #000646;
    font-weight: 800!important;
}
.summaryHead span{
    padding-left: 0rem;
}
.panelSummary{
    font-size:smaller;
    color:#333333;
    font-weight: 400;
}
.markerDetail{
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-top: 2px dashed #d3d3d3cf;
}
.markerDetail:first-child{
    border-top:none;
    padding-top: 0.5rem;
}
.markerDetail .reference-range{
    
    padding-top: 1rem;
    color:#000646;
    font-weight: 900;
}

/* TABS */
.nav-tabs .nav-link:hover {
    border-color: white;
}
a:hover{
    color:#888888;
}
.nav-tabs{
    display: flex;
   
}
.nav-link{
    font-size: 14px;
    border:none;
    padding:0.2rem;
    color:#888888;
    font-weight: 400;
    width:32%;
    text-align: center;
    
}
.nav-tabs{
    border-bottom: 4px solid rgb(230, 240, 250);
    
    justify-content: space-between;
    padding-top: 0;
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
    
}
.nav-item button{
    margin-right: 0;
    width: 100%;
    background-color: white;
    padding: 0.5rem;
    text-transform: uppercase;
    font-weight: 900;
}
.nav-tabs .nav-link.active{
    border-color: white;
    border-bottom: 4px solid rgb(71, 82, 141);
    background: white;
    
}


/* historic trends */
.trendsContainer{
    margin-top:1rem;
    font-family: 'Raleway','sans-serif';
}

.chartContainer{
    border:1px solid #d6d4df;
    border-radius: 10px;
    margin-top:1rem;
}
/* all recms */
.getAllRecms{
    position: fixed;
    bottom: 6px;
    right: 0px;
    left: 0px;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 2rem;
    width: 15rem;
    background-color: rgb(71, 82, 141);
    border: 1px solid rgb(71, 82, 141);
    padding: 1rem;
    color: white;
    font-weight: 900;
    text-align: center;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    z-index: 999;
    text-transform: capitalize;
    box-shadow: 2px 2px 10px grey;
}
/* marker */
.panelBarValueWrapper{
    display: grid;
    grid-template-columns: 25% 66%;
    grid-column-gap: 1rem;
    grid-column-gap: 8%;
    -webkit-column-gap: 8%;
            column-gap: 8%;
    padding-top: 1rem
}
.panelBarValueWrapper .summaryHead{
    font-size: small;
}
.panelBarValueWrapper span.biomarker-value{
    font-size: 2rem!important;
    padding-left: 0;
    font-weight: 900;
}
/* summary */
.parameter-summary-container{
padding: 0.5rem;
background-color: #f5f5fa;
display: grid;

align-items: center;

}
.parameters-summary .parameters-container{
    display: flex;
    flex-wrap: wrap;
    grid-column-gap: 1rem;
    -webkit-column-gap: 1rem;
            column-gap: 1rem;
    font-weight: 500;
    font-size: small;
    width: unset;
    padding-top: unset;
    grid-row-gap: 0;
    row-gap: 0;
}

.parameters-summary .parameters-container p{
    position: relative;
    display: flex;
    align-items: center;
    font-size: small!important;
    
}
.abnormal-parameters-summary{
    border-bottom: 1px solid rgb(208, 208, 247);
    padding-bottom: 0.5rem;
}
.normal-parameters-summary{
    padding-top: 0.5rem;
}
.abnormal-parameters-summary .parameters-container p::before{
    content:'';
    height:8px;
    width: 8px;
    border-radius: 50%;
    background-color: #f96b68;
    margin-right: 0.2rem;
}
.normal-parameters-summary .parameters-container p::before{
    content:'';
    height:8px;
    width: 8px;
    border-radius: 50%;
    background-color: #23E5AF;
    margin-right: 0.2rem;
}
.normal-parameters-summary{
    padding-bottom: 1rem;
}
.biomarker-value_0{
    color: #23E5AF;
}
.biomarker-value_1,.biomarker-value_-1{
    color: #fec760;
}
.biomarker-value_2,.biomarker-value_-2{
    color: #f65e5b;
}
@media screen and (max-width:320px) {
    .getAllRecms{
        margin-bottom: 1rem;
    }
    .PatientInfo .greetingMsgBox{
        padding: 2rem 1rem;
    }
}

*{
    padding:0;
    margin:0;
}
p{
    margin:0;
}

#smartReportMainContainer *:not(i,.panelSummary){
    font-family: 'Manrope', sans-serif!important;
     
}
.panelSummary{
    font-family: 'Metropolis'!important;
    font-size: 14px!important;
}
#root,.mainContainer,.allRecommendations,.allRecommendations .tab-content,.allRecommendations .tab-content  .tab-pane,.allRecommendations .tab-content  .tab-pane >*{
    height: 100%;
}

/* patient data */

.personal-deets{
    
    color:white;
    
    padding-top: 1.5rem;
 
    display: flex;
    font-size: smaller;

    align-items: center;
    width: 100%;
    
}
.eachDeetHead{
    color:#565555;
    font-size: small;
    font-weight: 300;
}
.eachDeet{
    
    padding:0.7rem;
    flex-grow: 1;
    
}
.eachDeetDetail{
    color:rgb(71, 82, 141);
    font-size: smaller;
    font-weight: bolder;
}
.eachDeet:last-child{
    border-right: none;
   
}
.tab-container{
    width:90%;
    display: flex;
    flex-direction: column;
    padding:1rem;
    justify-content: space-evenly;
    
    margin-top: 1rem;
}
.personal-deets > p{
    margin:0.3rem;
}
.personal-deets >p .deetCat{
    font-weight:600;
    
}
#share-report-whatsapp{
    padding: 0.8rem 1rem;
    border-radius: 25px;
    background-color: white;
    border:1px solid rgb(71, 82, 141);
    font-weight: 800;
    color:rgb(71, 82, 141);
    position: absolute;
    bottom: -3rem;
    left: 1rem;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    box-shadow: 0.5px 0.5px 0px rgb(71 82 141);
}
#share-report-whatsapp a{
    font-weight: 800;
    color:rgb(71, 82, 141);
}
.PatientInfo{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.PatientInfo .greetingMsgBox{
    display: flex;
    padding: 3.5rem 1rem;
    width: 100%;
    position: relative;
    background-color: rgb(71, 82, 141);
    
    /* background-position-x: 50%; */
    background-repeat: no-repeat;
    background-position: top left;
    border-bottom-right-radius: 123px;
}
.introp{
   
    width: 65%;
    color:white;
}
#smartReportMainContainer{
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
    font-family: 'Open Sans';
}
.overall{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top:1rem;
}
.deetCat{
    font-weight: bolder;
    margin-right: 4px;
}
/* overall health */
.progress-bar{
    height: 8rem;
    width: 8rem;
    border-radius: 15px;
    
    margin-right: 0.5rem;
    position: relative;
    background-color: inherit;
    }
    .circle-progress {
    fill: none;
    stroke-width: 6.2;
    stroke-opacity: 0.3;
    stroke-linecap: round;
    stroke: white;
    }
    
    .circle-progress-fill {
    --initialStroke: 0;
    --transitionDuration: 0;
    stroke-opacity: 1;
    stroke-dasharray: var(--initialStroke);
    stroke-dashoffset: var(--initialStroke);
    transition: stroke-dashoffset var(--transitionDuration) ease;
    
    
    }
    .score-text{
        color:white;
        font-weight: 900;
        margin-right: 1rem;
    }
    strong{
    font-weight: 500;
    }
    .desc{
    width:10rem;
    }
    #overall-wellness-score{
        font-family: 'Raleway','sans-serif';
        font-weight: 600;
        position: absolute;
        font-size: 2.3rem;
        top: 25px;
        right: 43px;
        width: 3rem;
        height: 3rem;
        /* margin-right: auto; */
        /* margin-left: auto; */
        margin-right: auto;
        /* transform: translate(-150%); */
        /* background-color: white; */
        border-radius: 50%;
    }
    #wellness-sticker{
        background-color: white;
        border-radius: 50%;
        height: 1.5rem;
        width: 1.5rem;
        position: absolute;
        bottom: 6px;
        right: 11px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
    }
    .bx{
        margin-right: 0px;
    }
    .wellness-score{
    border-radius:15px;
    flex-grow: 1;
    display: flex;
    
    justify-content: center;
    align-items: center;
   
    }
    
    .circle_wrapper{
    
    -webkit-transform: rotate(-90deg);
    
            transform: rotate(-90deg);
    
    }
    /* modal */
    .disclaimerModalContainer{
        
    position: fixed; /* Stay in place */
    z-index: 9999999; /* Sit on top */
    padding-top: 100px; /* Location of the box */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
        overflow:auto;

    background-color: rgba(0,0,0,0.8); 
    }
    .disclaimerModal{
        background-color: white;
        width:50%;
        font-size: small;
        margin-right: auto;
        margin-left: auto;
        padding: 1rem;
        border-radius: 12px;
        display: flex;
        flex-direction: column;
        align-items: center;
        border:5px solid #e78888;
        position: relative;
        z-index: 900;
    }
    .modalOk{
        border-radius: 25px;
        background-color: rgb(71, 82, 141);
        color:white;
        font-weight: bolder;
        padding:10px 38px;
        margin-top: 1rem;
    }

    /* question */
    .QuestionWrapper{
        position: fixed;
    z-index: 1;
    
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0,0,0,0.7);
    z-index: 99999;
    }
    .QuestionContainer{
        position: fixed;
        bottom:0;
        width: 100%;
        display: flex;
        flex-direction: column;
        box-shadow: 0.3px -3.8px 9px #948c8cf1;
        background: white;
        height: 70%;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        

    }
    .questionHeader{
        padding: 1rem ;
        background-color: #3c9aff6b;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        color: darkslategray;

    }
    .questionTitle{
        font-weight: 700;
        font-family: 'Raleway','sans-serif';
    }
    .questionslide_title{
        padding-bottom: 1rem;
        font-weight: 800;
    }
    .questionContainer{
        padding: 1rem;
    }
    .questionButtons{
        display: flex;
        padding: 1rem;
        justify-content: space-around;
    }
    .questionButtons div{
        background-color: darkslategray;
        color:white;
        padding: 0.5rem 1.5rem;
        border-radius: 8px;
        box-shadow: 1px 2px 4px lightgray;
    }
    .optionContainer{
       
        border-radius: 12px;
    border: 1px solid lightgray;
    padding: 0.5rem;
    margin-top: 0.7rem;
 
    }
    .optionContainer input[type='checkbox']{
        opacity: 0;
    }
    .optionContainer input{
        outline: none;
        border: none;
    }
    .optionContainer.checkbox label::before{
        content: '';
        height:1.2rem;
        width: 1.2rem;
        border-radius: 50%;
        border:1px solid rgb(204, 204, 204);
    }
    .optionContainer.checkbox.checked label::before{
        background-color: rgb(71, 82, 141);
    }
    .optionContainer.checkbox.checked{
        border:1px solid rgb(71, 82, 141);
    }
    
    .optionContainer label{
       margin-bottom: 0;
        display: flex;
        width: 100%;
        align-items: center;
    }
    .opt_name{
        text-transform: capitalize;
        padding-left: 0.5rem;
        font-weight: 500;
    }
    .reportLoader lottie-player{
        width: 20%;
    }
 
    @media screen and (max-width:900px) {
        #smartReportMainContainer{
            width: 100%;
        }
        .disclaimerModal{
            width: 90%;
            
        }
        .reportLoader lottie-player{
            width: 50%;
        }
    }
    @media screen and (max-width:375px) {
        .getAllRecms{
            margin-bottom: 1rem;
            bottom: 0;
            padding: 0.8rem 1rem;
            font-size: smaller;
            width: -webkit-fit-content;
            width: -moz-fit-content;
            width: fit-content;
        }
        .PatientInfo .greetingMsgBox{
            padding: 2rem 1rem;
            border-bottom-right-radius:80px;
        }
        #share-report-whatsapp{
            padding: 0.5rem;
            bottom: -2.8rem;
        }
    }
        




.recommendationsContainer{
    border-radius:5px;
    padding:0.5rem;
    margin-top:1rem;
    background-color: white;
}
.recommendationContainer{
    border-radius:5px;
    padding:1rem;
    margin-bottom:1rem;
}
.recm-title{
    text-transform: capitalize;
    color:#000646;
    margin-bottom: 0px;
}
.title_wrap img{
    padding-right:0.5rem ;
}
.title_wrap{
    display: flex;
    align-items: center;
}
.head{
 
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.5rem;
}
.knowMore{
    color:#FF5F45;
    font-size: smaller;
}
ul{
    list-style: none;
}
span.recmListTitle{
    text-transform: capitalize;
    font-weight: 600;
   
}
span.recmListItems{
    font-weight: 300;
    text-align: justify;
    overflow-wrap: break-word;
}
span.recmListTitle i{
    margin-right: 3px;
}
.recmListItemContainer{
    margin-top: 0.5rem;
    display: block
}
.recmIcon{
    height: 23px;
}


/* diet recommendations */
.diet_recm{
    background-color: white;
    border-radius: 5px;
}
.includeList,.reduceList,.avoidList{
    padding-top: 0;
}
.includeContainer,.reduceContainer,.avoidContainer{
    padding-bottom: 1rem;
    margin-top: 1rem;
    padding: 1rem;
    background-color: #fdd2ca;
}
.includeHead{
    text-transform:uppercase;
    font-weight: 900;
    letter-spacing: 1px;
    text-align: center;
    font-size: 1.2rem ;
    color:green;
    margin-bottom: 0.5rem;

}

.bx-check-circle{
    color:green;
}
.bx-down-arrow-circle{
    color:#da7c29;
}
.bx-minus-circle{
color:#af1616;
}
.optionContainerWrapper{
    display: flex;
    padding: 1rem;
    flex-direction: column;
}
.includeContainer{
    order:-2;
    background:#bfedbf;
    padding: 1rem;
    margin-top: 0;
}
.reduceContainer{
    order:-1;
    background:#f1dcb5;
    padding: 1rem;
}
.reduceHead{
    
    text-transform:uppercase;
    font-weight: 900;
    letter-spacing: 1px;
    text-align: center;
    font-size: 1.2rem ;
    color:#ad5e19;
    margin-bottom: 0.5rem;

}
.avoidHead{
    
    color:#af1616;
    
    text-transform:uppercase;
    font-weight: 900;
    letter-spacing: 1px;
    text-align: center;
    font-size: 1.2rem ;
    
    margin-bottom: 0.5rem
}
/* toggle switch  */
.switchWrapper{
    width:100%;
    display: flex;
    
    align-items: center;
    padding-left: 1rem;
    padding-top: 1rem;
}
.switch{
    position: relative;
    height:20px;
    width:41px;
    display: flex;
    align-items: center;
    margin:0px;
}
.switch input{
    
opacity: 0;


}
.slider{
    position: absolute;
    cursor: pointer;
    top:0;
    bottom: 0;
    left:0;
    right: 0;
    background-color: lightgray;
    border-radius: 25px;
}

.slider-before{
    
    position: absolute;
    
    
    background-color: white;
    height:20px;
    width:20px;
    transition:0.4s;
    border:1px solid lightgray;
    border-radius:50%;

}
.dot{
    content:'';
    position: absolute;
    top:4px;
    left:4px;
    border-radius: 50%;
    height:12px;
    width:12px;
    background-color: white;

}

input:checked + .slider .slider-before{
   
    border:1px solid green;
    -webkit-transform: translateX(22px);
            transform: translateX(22px);
}
input:checked + .slider .slider-before::before{
    background-color: green;
}
input:checked + .slider{
    background-color: green;
}


/* ALL RECOMMENDATIONS */
.allRecommendations .nav{
    display: grid;
    
    justify-content: unset;
background-color: white;
    overflow-x: scroll;
    margin-top: 3.5rem;
    grid-column-gap: 1rem;
    -webkit-column-gap: 1rem;
            column-gap: 1rem;
    
  
}
.allRecommendations .nav::-webkit-scrollbar-track{
    background-color: rgb(224, 224, 224);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.filterSticker{
    margin-left: 0.5rem;
    width: 1.6rem;
    height: 1.6rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid lightgray;
    border-radius: 50%;
    box-shadow: 1px 2px 4px lightgrey;
    background-color: #f9f9f9;
}
.stickerIcon{
    width:1rem;
    height: 1rem;
}


 
 .navbar{
     background-color:rgb(71, 82, 141);
     color:white;
     justify-content: flex-start;
     align-items: center;
     position: fixed;
     top:0;
     left:0;
     height: 3.5rem;
     width: 100vw;
     z-index: 999;
 }
 .bx-left-arrow-alt{
     font-size: 36px;
     color:white
 }
.eachRecmConatiner{
    display: flex;
    border-radius: 5px;
    border:none;
    margin: 1rem;
    background-color: white;


}
.eachRecmImage{
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    width:140px;
    background-color:lightgray ;
}
.whyRecommended{
   
    font-weight: 200;
}
.eachRecmDescription{
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
    width:80%;
}
.eachRecmTitle{
    color:rgb(71, 82, 141);
    font-size: 17px;
    font-weight: 900;
}
.eachRecmImage img{
    width: 139px;
    height: 100%;
    object-fit: cover;
}
.eachRecmTime{
    display: flex;
    grid-gap:0.5rem;
    gap:0.5rem;
    font-weight: 400;
}
.practiseNow{
    color: #e75e34;
    letter-spacing: 0.1px;
    font-size: 14px;
    margin-top:1rem;
    font-weight: 900;
    text-transform: uppercase;
}
/* practise yoga componeny*/
.recmDescription{
    padding:1rem;
}
ul{
    list-style: none;

}

hr{
    background-color: rgb(71, 82, 141);
    width: 80%;
    height: 2px;
    border: none;
}
.recmImage{
    width: 100%;
    background-color: gray;
    height:-webkit-fit-content;
    height:-moz-fit-content;
    height:fit-content;
    
}
.recmImage img{
    object-fit: cover;

    width: 100%;
    margin-top: 3.5rem;
}
.recmPanel_desc{
    font-size: smaller;
    /* font-weight: lighter; */
    padding: 1rem;
    background: lavender;
    color: rgb(71, 82, 141);
    /* font-style: italic; */
    letter-spacing: 0.2px;
    line-height: 20px;
    font-weight: bold;
}
.pills{
    display: flex;
    align-items: center;
}

.pills div{
    display: flex;
    grid-gap:0.5rem;
    gap:0.5rem;
    align-items: center;
    margin-right: 0.5rem;
    border-radius: 25px;
    padding:2px;
    padding-left:8px;
    padding-right: 8px;
    color: white;
    font-size: smaller;
}
.pills .duration{
    background-color: #f0e1f7;
    color: black;
}
.pills .difficulty{
    background-color:#FFB429;
}

.recmTitle{
    color:rgb(71, 82, 141);
}
.sectionTitle{
    color:#000646;
    font-weight: 900;
    letter-spacing: 0.5px;
    text-transform: uppercase;
}
.sectionList{
    padding-top: 1rem;
}
.eachInstructionContainer{
    background-color: lavender;
    padding: 1rem;
    margin-bottom: 1rem;
}
.eachInstructionContainer.benefits{
    background-color: #c1f4d6;
}
.eachInstructionContainer.precautions{
    background-color: #f0e0b2;
    padding: 1rem;
    margin-bottom: 1rem;
}
.sectionList li{
    display: flex;
    align-items:baseline;
    font-weight: 300;
    font-size: 15px;
    margin-bottom: 0.3rem;
    
}
.sectionList li::before{
    display: inline;
   content: "";
   height: 5px;
   width: 5px;
   border-radius: 50%;
   background-color:rgb(71, 82, 141);
   font-size:7px;
   margin-right:10px;
  
}
.stepsWrapper{
    background-color: lavender;
    margin-top: 1rem;
    padding: 1rem;
}
.step{
    display: flex;
   
    position: relative;
}
.stepDesc{
    margin-bottom: 1rem;
}

.step:last-child .line{
    display: none;
}

.stepNo{
    background-color:white;
    position: relative;
    margin-bottom:3rem ;
    border:2px solid #664277;
    padding: 14px;
    height: 41px;
    border-radius:50% ;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 1rem;
    color: rgb(71, 82, 141);
    font-weight: bold;
}
.psuedostepNo{
    position:absolute;
    top:0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index:3;
}
.line{
    position: absolute;
    height: 100%;
    border-right: 1px dashed black;
    left:20px
}

/* superfood component */
.instructionWrapper{
    margin-top:1rem;
}

/*recioe */
.recipesWrapper{
    padding:1rem;
    background-color: white;
    margin-bottom: 1rem;
}
.recipeScroll{
    display: flex;
    overflow-x: scroll;
   
}
.recipeContainer{
    display: flex;
  
    width: 100%;
    padding:0.5rem;
    
}

.eachRecipe{
    background-color: white;
    padding: 1rem;
    margin-right: 1rem;
    width:100px;
    display: flex;
    box-shadow: 2px 2px 3px lightgray;
    border-radius: 6px;
}
*{
    box-sizing: border-box;
}
.registeration-form{
    display: grid;
    grid-template-columns: 1.2fr 1fr;
    min-height: 100vh;
    font-family: var(--secondary-typeface);
}
.registeration-form>div{
    padding: 7rem 4rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.registeration-form-title{
    padding-bottom: 1rem;
    color: var(--primary-color-blue);
}

.registeration-form  input,.registeration-form  select{
    height: 3rem !important;
    border-radius: 5px;
    border: 1px solid lightgray;
    padding-left: 0.5rem;
    margin-right: 1rem;
    margin-bottom: 1rem;
    width: 100%;
    color: var(--primary-color-blue__dark);
   
    padding-right: 0.5rem;
}
.registeration-form label{
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 700;
    font-family: var(--primary-typeface);
    color: var(--primary-color-blue__dark);
}
.registeration-form  input:focus,.registeration-form  select:focus{
    border: 1.5px solid var(--secondary-color-purple);
    transition: all 0.1s ease-out;
}
.registeration-form input:disabled{
    border: none;
    background-color: #E8E8E8;
    cursor: not-allowed;
    color: rgb(175, 175, 175);
}
.registeration-form-illus{
    display: flex;
    background: var(--secondary-color-purple__dark);
    justify-content: center;
    flex-direction: column;
    
}
.registeration-form-title{
    font-family: var(--primary-typeface);
}
.registeration-form .register-btn{
    background-color: var(--primary-color-green);
    color: var(--primary-color-blue__light);
    letter-spacing: 1px;
    padding: 0.2rem 1rem;
    width: 100%;
    height: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    font-weight: 600;
    cursor: pointer;text-transform: uppercase;
}

/* success page */
.successPage{
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width:1000px) {
    .registeration-form{
        display: grid;
        grid-template-columns: 1fr;
        min-height: 100vh;
    } 
    .registeration-form>div{
        padding: 2rem;
        width: 100vw;
    }
    .registeration-form-illus{
        display: flex;
        justify-content: start;
        align-items: start!important;
        padding: 5rem 2rem!important;
    }
    
}

.profileHead{
    font-weight: 400;
    padding-bottom: 0.5rem;
    color: #323248c7;
    text-transform: uppercase;
    font-size: small !important;
    letter-spacing: 1px;
  
}


.profileDetails{
    padding: 1rem;
    background-color: rgb(215 215 215 / 32%);
    margin-bottom: 1rem;
}
.user-profile-container{
    padding: 0;
    padding-top: 4rem;
    min-height: 100vh;
}
.user-profile-container>div{
    padding: 2rem 7rem;
}
.editprofile-form{
    position: absolute;
    background-color: lavender;
    top:10vh;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 400;
    overflow: hidden;
}

.editprofile-form img{
    position: absolute;
    border: -15rem;
    
    bottom: -38px;
    height: 24rem;
    left: 0;
}
.editProfile_labels{
    text-align: start;
    display: block!important;
    margin-bottom: 0px;
    letter-spacing: 1px;
    padding-left: 0.3rem;
}

.profileDetails p:not(.profileHead){
    font-weight: 500;
    padding-bottom: 0.5rem;
}
.onegoal i{
    font-size: 5px;
}
.allInputsContainer{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;
}
.allInputsContainer .input-field{
    width: 100%;
}
.allInputsContainer>div.fullColumn{
    grid-column: 1 / 3;
}
/* services */
.services-container{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 1rem;
    -webkit-column-gap: 1rem;
            column-gap: 1rem;
}
.services-container .service{
    background-color: rgba(255,255,255,0.8);
    padding: 1rem;
    box-shadow: 1px 2px 16px rgb(224 224 224 / 90%);
    border-radius: 8px;
    color: #0c0b20;
}
.service h5{
    font-weight: 900;
    text-transform: uppercase;
    font-size: 1rem;
    letter-spacing: 1px;
    line-height: 1.5rem;
}
.service.drug-interaction-checker{
    background-color: #dadaf8;
}
.service.pdf-analyzer{
    background-color: #f0d0b4;
}
.service p{
    font-size: small!important;
}
@media screen and (max-width:900px) {
    .user-profile-container>div{
        padding: 1rem;
    }
}
#order-details-container{
    padding:inherit 16rem;
    display: grid;
    grid-template-columns: 0.75fr 0.3fr;
    min-height: 70vh;
    grid-gap:2rem;
    gap:2rem;
    color: var(--primary-color-blue);
    background-color: rgb(241, 243, 243);
}
#order-details-container div[class*="action-btn"]:hover{
    color:unset;
    opacity: 1;
}
.error-msg{
    color:red;
    font-size: smaller;
}
#order-details-container h1{
    font-size: 2rem;
    padding-bottom: 0.5rem;
}
.white-box{
    padding: 1rem;
    background-color: white;
}
.order-meta{
    font-weight: 700;
}
.order-meta span{
    background-color: var(--primary-color-green__light);
    padding: 3px;
    font-weight: 400;
}
.order-price{
    display: flex;
    align-items: center;
}
.order-price .old-price{
    text-decoration: line-through;
    color: gray;
    font-size: small;
}
.order-price .new-price{

    padding-left: 0.5rem;
}

.shipment-details__form{
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;
    max-width: 100%;
    
}

.shipment-details__form input {
    height: 3rem;
    padding-left: 1rem;
    width: 100%;
    border: 1px solid lightgray;
    border-radius: 5px;
}
label.input-container,input[name='email']{
    grid-column: 1 / 3;
    position: relative;
}
label.input-container input{
    padding-left: 3.5rem;
}
label.input-container span{
    position: absolute;
    left: 1px;
    top:1px;
    height: calc(100% - 2px);
    width: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(243, 243, 243);
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}
input[placeholder="Address"]{
    height: 7rem;
    grid-column: 1 / 3;
}
.total-price {
    font-size: large;
    border-top: 1px solid var(--primary-color-blue);
    padding-top: 0.5rem;
    margin-top: 0.5rem;
}
.total-price span{
    font-weight: 800;
}
.total-price-calculations{
    margin-top: 1rem;
}
#total-price-calculations__table p{
    display: grid;
    grid-template-columns: 1fr 1fr;
}
#total-price-calculations__table p span{
    justify-self: flex-end;
}
#order-coupons{
    margin-top: 1rem;
}
#coupon-addition-form{
    
    display: flex;
    align-items: center;
    grid-gap: 0.5rem;
    gap: 0.5rem;

}

#coupon-addition-form{
    display: flex;
    align-items: center;
    grid-gap: 1rem;
    gap: 1rem
}
#coupon-addition-form input{
    height: 3rem;
    padding-left: 1rem;
    border:1px solid rgb(201, 201, 201);
    border-radius: 8px;
}
@media screen and (max-width:500px){
    #order-details-container{
        grid-template-columns: 1fr;
    }
    .package-details{
        order: -2;
    }
    .shipment-details__form{
        max-width: 100%;
        grid-gap:0.8rem;
        gap:0.8rem
    }

}
#loginContainer{
    display: flex;
    overflow: hidden;
    flex-direction: column;
    
    align-items: center;
    width: 100%;
    height: 100vh;
    background-color: white;
}
#loginContainer .headerWave{
    background-color: white;
    position: relative;
    height: 40vh;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    z-index: 3;
    text-align: start;
    padding: 2rem 7rem;
    margin-bottom: 2rem;
    width: 100%;
    background-color: var(--primary-color-blue__dark);
    color: var(--primary-color-white);
}
#loginContainer .headerWave lottie-player{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    position: absolute;
    height: 12rem;
    right: 0;
    opacity: 0.9;
    bottom: 3rem;
    pointer-events: none;
    
}
#loginContainer .headerWave h1{
    position: relative;
    z-index: 6;
    text-align: start;
    
    font-weight: 800;
    
}
#loginContainer .headerWave svg{
    position: absolute;
    height: 90%;
    right: -2rem;
    opacity: 0.1;
}
#loginContainer .headerWave svg *{
    fill: white;
}
#loginContainer .action-btn__primary{
    
    width: 20rem;
    height: 3.3rem;
    margin-top: 1rem;
    box-shadow: 1px 1.2px 5px #dad9d9c2
}
input:focus{
    border: none;
    outline: none;
}
.login_labels{
    letter-spacing: 1px;
    margin-bottom: 3px;
    color:var(--primary-color-blue)
    
    
}
.otpInputContainer{
    width: 100%;
    justify-content: center;
    
}
.otpInputContainer .input-field{
    width: 3rem;
    padding: 0;
}

.loginInputsContainer{
    display:grid;
    width: 100%;
    justify-items: center;
    align-items: center;
}
.country-code{
    background-color: rgba(255,255,255,0.9);
    border: 2px solid lavender;

    width: 3rem;
    height: 3.4rem;
    border-radius: 12px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 0%;
    z-index: 3;
    font-weight: 800;
}
.input-field{
    
    width: 100%;
    background-color: #ebebf4;
    border: 0px solid transparent;
    
    height: 3.4rem;
    border-radius: 12px;
    
    padding: 0 3rem;
    
    position: relative;
    justify-self: center;
}
.otpInputContainer .input-field{
    width: 3rem !important;
    height: 3rem !important;
}
.input-field:focus{
    border: none;
}

@media screen and (max-width:1000px) {
    #loginContainer .headerWave{
        padding: 2rem;
    }
    .input-field{
        width: 100%;
    }
    
    .country-code{
        left: 0%;
    }
    .auth-registerBtn{
        width: 65%;
    }
}
#blog-domain-container{
    
    font-family: 'Metropolis';
  
}
#blog-domain-container h1,h2,h3,h4,h5{
    font-family: 'Manrope';
}



/* general */

.recipe-ad-conatiner{
    padding-top: 2rem;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    position: relative;
}
.recipe-ad-conatiner .blog_data{
    position: absolute;
    
    padding: 1rem;
   
    padding-bottom: 2rem;
    bottom: 0;
    left: 7rem;
    
}
.recipe-ad-conatiner img{
    height: 50vh;
    width: 100%;
    object-fit: cover;
}
.blog-header{
    color:var(--primary-color-blue__dark);
    padding-left: 0.5rem;
    border-left: 0.5rem solid var(--secondary-color-purple__light);
   
    
}

.categories-container{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    letter-spacing: 2px;
    font-weight: 900;
}
.categories-container img{
    height: 3rem;
    display: none;
    
}
.categories-container .category{
    padding: 0.5rem;
   
    margin-right: 1rem;
    margin-bottom: 1rem;
}
.categories-container h3{
    font-size: 20px;
    font-weight: 600;
    
    text-transform: uppercase;
    color: var(--primary-color-blue);
}
.blog-no{
    font-size: small;
    color: gray;
    margin-bottom: auto;
    padding-right: 1rem;
}

/* popular blogs */
.popular-blogs{
    padding: 0rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
    
}
.popular-blogs img{
 height: 18rem;
 width: 100%;
 object-fit:cover
}
.popular-blogs .blogs-container{
    display: grid;
    grid-gap: 2rem;
    grid-template-columns: 1fr 1fr 1fr;
}

.blogs-container > a:first-child img{
    max-height: 20rem;
    
}
.blog_image{
    overflow: hidden;
    
}
.blog-card:hover .blog_image img{
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    transition: all 0.5s ease;
}

.recipe-hero-img{
    position:relative;
}
.recipe-ad-conatiner .blog_data{
    z-index:4;
    color:white;
    left:7rem;

}
.recipe-hero-img::after{
    content:'';
    position:absolute;
    top:0;
    bottom:0;
    left:0;
    right:0;
    background:linear-gradient(to bottom, transparent, black);
    z-index:2
}
.recipe-ad-conatiner{
    padding-left: 0rem!important;
    padding-right: 0rem!important;
    
}
.recipe-ad-conatiner .blog-headers{
    width: 60%;
    opacity: 0.7;
    padding-bottom: 1rem;
}
.recipe-ad-conatiner a{
    font-size: 1rem;
   
    opacity: 0.9;
    text-transform: uppercase;
    
}

a.category:hover h3{
    color:var(--primary-color-green)
}
section.all-categories{
    padding-bottom: 1rem;
}
@media screen and (max-width:500px) {
    
    
    .blog-headers{
        font-size: 3rem;
    }
    
    
    .popular-blogs .blogs-container{
        grid-template-columns: 1fr;
    }
    .popular-blogs img{
        width: 100%;
    }
    
    .recipe-ad-conatiner .blog_data{
        
        left:1rem;
        padding-bottom: 2rem;
    
    }
    .recipe-ad-conatiner .blog-headers{
        width: 100%;
        font-size: 2.2rem;
        opacity: 0.7;
        padding-bottom: 0.5rem;
    }
    .all-category-blogs{
        grid-gap: 1rem;
    }
    .blogs-container > a:first-child{
        grid-row: 1 / 1 !important;
        grid-column: 1 / 1 !important;
        height: 100%;
    }
    .all-category-blogs > a:first-child{
        grid-row: 1 / 1 !important;
        grid-column: 1 / 1 !important;
        height: 100%;
    }
    
}
.blog-card .blog-image{
    overflow: hidden;
}
.blog-card{
    grid-gap:0.4rem;
    gap:0.4rem
}
.blog-category{
    text-transform: uppercase;
    font-size: small;
    
    color: var(--secondary-color-blue__dark);
    width: -moz-fit-content;
    width: -webkit-fit-content;
    width: fit-content;
    font-weight: 700;
    padding-right: 0.3rem;
}
.blog-date{
    font-size:small;
    text-transform:uppercase;
    color:#727070;
    font-weight: 500;
}

/*verticle blog card */
.vertical-blog-card{
    display: grid;
    color:black;
    padding-top:0.5rem;
    position: relative;
    height: 100%;

}
.vertical-blog-card img{
    -webkit-filter:brightness(0.8);
            filter:brightness(0.8)
}

.vertical-blog-card  img{
    width: 100%;
    height: 17rem;
    max-height: 17rem;
    object-fit: cover;
    align-self: flex-end;
    /* border-radius: 8px */
}
.vertical-blog-card .blog-title{
    
    color: var(--primary-color-blue__dark);
    font-size: 1.5rem;
    font-weight: 600;
    font-family: var(--primary-typeface);
}
.vertical-blog-card .blog-image{
    display: grid;
}
.vertical-blog-card .blog-date{
    font-size: smaller;
}
/* horizontal blog card */
.horizontal-blog-card{
    display: grid;
    grid-template-columns: 8rem auto;
    color:black; 
    height: 8rem;
    grid-gap: 1rem;
    gap: 1rem;

}
.horizontal-blog-card .blog-image{
    order: -3;
    height: 100%;
    /* border-radius: 8px; */
}

.horizontal-blog-card .blog-title{ 
    color:var(--primary-color-blue__dark);
    font-size: 18px;
    font-weight: 500;
    line-height: unset;
}
.horizontal-blog-card .blog-image img{
    height: 100%;
    width: 100%;
    object-fit: cover;
    /* border-radius: 8px; */
}
/* latest blog card */
.latest-blog-card{
    width: 100%;
    height: 25rem;
    position: relative;
    display: grid;
    grid-template-columns: 1.5fr 1fr;
    grid-gap:1rem;
    gap:1rem
}
.latest-blog-card .blog-metadata{
    display: grid;
}
.latest-blog-card .blog-image{
    order: -2;
}
.latest-blog-card .blog-desc{
    color:var(--primary-color-blue);
    padding-bottom: 0.5rem;
}
.latest-blog-card .blog-date{
    color: rgb(41, 40, 40);
    font-size: 15px;

}
.latest-blog-card .blog-title{
    font-size: 2rem;
    color:var(--primary-color-blue__dark);
    font-weight: 700;
}

.latest-blog-card img{
    width: 100%;
    height: 25rem;
    object-fit: cover;
}
.blog-author{
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 4rem auto;
}
.blog-author .author-image{
    height: 3.5rem;
    width: 3.5rem;
    border-radius: 50% ;
    background-color: lightgray;
}
.blog-author .author-image img{
    object-fit: cover;
    height: 3.5rem;
    width: 3.5rem;
    border-radius: 50%;
}
.blog-author .author-name{
    color:var(--primary-color-blue__dark);
    font-weight: 600;
    font-size: large;
}
.blog-author .author-role{
    color: gray;
}
/* hover */
a.blog-card:hover img:not(.author-image img){
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
    transition: -webkit-transform 0.2s ease;
    transition: transform 0.2s ease;
    transition: transform 0.2s ease, -webkit-transform 0.2s ease;
}
@media screen and (max-width:500px) {
    .vertical-blog-card{
        height: 100%;
        max-height: 24rem;
    }
    .vertical-blog-card img{
        max-height: 12rem;
    }
    .horizontal-blog-card{
        height: 7rem;
        grid-template-columns: 7rem auto;
    }
    .horizontal-blog-card img{
        max-height: 7rem;
    }
    .latest-blog-card{
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        grid-template-columns: 1fr;
        grid-gap:1rem;
        gap:1rem;
       
    }
    .latest-blog-card img{
        height: 18rem;
    }
    .latest-blog-card .blog-image{
        order: 3;
    }
    .vertical-blog-card .blog-title{
        font-size: 1.5rem;
    }
    
    
}





.error{
    display: grid;
    grid-template-columns: 1fr 1fr;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    min-height: 100vh;
    justify-items: center;
  
}
.error .big-header-h1{
    color:var(--primary-color-blue)
}
 .logoheader{
    width: 50%;
}
.infographic{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: var(--secondary-color-purple__light);
    position: relative;
}
/* .infographic::after{
    content: '';
    position: absolute;
    top:0;
    left: 0;
    right: 0;
    bottom: 0;
   
} */
.error .infographic img{
    height: 100%;
   
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    object-fit: contain;
    position: relative;
    z-index: 5;
}
.error h2{
    margin-bottom: 1rem;
}
.linksContainer a{
    display: block;
    color: var(--primary-color-blue);
    font-weight: 600;
    margin-top: 0.5rem;
    font-size: 1.2rem;
}
.linksContainer i.bx{
    color: var(--primary-color-blue);
    padding-right: 0;

}
.textContainer{
    text-align: start;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    position: relative;
    padding-left: 2rem;
    
}
.error svg{
    display: none;
}
.error h2{
    font-size: 2.8rem;
}
.error p{
    font-size: 1.2rem;
}

@media screen and (max-width:800px) {
    .error{
        grid-template-columns: 1fr;
        grid-template-rows: 0.7fr 1fr;
    }
    .logoheader{
        width: 100%;
    }
    .textContainer{
        justify-content: unset;
        padding-top: 2rem;
        text-align: center;
        
    }
    .error svg{
        display: block;
        position: absolute;
        height: unset;
        top:0;
        left: 0;
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    .error h2{
        font-size: 1.8rem;
    }
}

#singleBlogContainer{
    background-color: white;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    max-height: -webkit-fit-content;
    max-height: -moz-fit-content;
    max-height: fit-content;
    padding-top: 0;
    display: grid;
    grid-template-columns: 1fr;
    max-width: 100vw;
    word-break:break-word;
    margin-right: auto;
    margin-left: auto;
    padding: 0;
    
}

#singleBlogContainer p,#singleBlogContainer ol,#singleBlogContainer ul,#singleBlogContainer li {
    font-size: 20px !important;
    position: relative;
    letter-spacing: 0!important;
    margin-bottom: 0rem !important;
}
#singleBlogContainer a{
    color: var(--primary-color-blue);
    
    font-weight: 600;
    padding: 3px;
    border-bottom: 1.5px solid var(--primary-color-green);
}

.author_name{
    font-weight: 600;
    padding-left: 0.5rem;
}
#singleBlogContainer ul>li,#singleBlogContainer ol>li{
    padding-bottom: 0.8rem!important;
    padding-left: 0.4rem;
    position: relative;
    display: block;
}

#singleBlogContainer ul>li::before , #singleBlogContainer ol>li::before{
    content: "\ea39";
    font-family: 'boxicons'!important;
    position: absolute;
    top: 0;
    left: -0.8rem;
    font-size: 8px;
    color: var(--primary-color-blue);
}
.blogCover{
    display: grid;
    grid-template-columns: 1fr 1fr;
    background-size: cover;
    background-position: center;
    padding: 4rem 0rem;
    padding-bottom: 0;
    width:100%;
    height: 70vh;
    position: relative;
}
.blog_desc{
    background-color: var(--secondary-color-blue__dark);
    position: relative;
    padding: 0rem 0rem;
    z-index: 300;
    order: 4;
    padding-left: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;

}
.blog-type{
    background-color: transparent;
    font-weight: 600;
   
    padding: 0px;
    margin: 0px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    font-size: smaller;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    border-bottom: none!important;
}
.blog-type p{
    margin-bottom: 0px;
    margin-right: 0.5rem;
   
    padding: 1px;
    text-transform: uppercase;
    font-size: small;
    color: var(--primary-color-green);
    font-weight: 600;

}
.blog_title{
    color: white;
    position: relative;
    z-index: 300;
    padding: 0rem 0rem;
    background-color: transparent;
    font-family: 'Manrope';
    font-weight: 800;
    text-align: start;
    
}
.blog_date{
    color: white;
    display: flex;
    align-items: center;
    z-index: 300;
    position: relative;
    
    font-weight: 100;
    
    padding-left: 0rem;
    padding-bottom: 1rem;
}
.blog_subtitle{
    font-size: 2rem !important;
    padding-left: 1rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    text-align: start;
    font-style: italic;
    font-weight: 500!important;
    opacity: 0.5;
    line-height: 3.4rem;
}

.blog-center{
    max-width: 50vw;
    margin-right: auto;
    margin-left: auto;
}
.section_hrefs{
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    margin-top: 0rem;
    text-transform: capitalize;
    font-weight: 600;
    padding:1rem 1rem;
}
.hrefs:hover{
    text-decoration: none;
}

.blog_content{
    display: flex;
    flex-direction: column;
    width: 100%;
}
.sectionImg{
    width: 100%;
    object-fit: contain;
    order: 30;
}
.sectionHead{
    font-weight: 600;
    padding: 1rem;
    padding-bottom: 0.5rem;
    font-size: 1.4rem;

    
}
.section_desc{
    padding: 1rem;
    padding-top: 0;
    font-size: 20px !important;
    font-weight: 300;

    line-height: 1.7rem;
    color: black;
    width:100% !important;
  
    line-height: 31px;
}
.blog_share{
    color: white;
    display: flex;
    grid-gap: 1rem;
    gap: 1rem;
    align-items: center;
    padding-top: 3rem;
}
.blog_share a{
    border-bottom: none!important;
}
.blog-share p{
    font-size: smaller!important;
}
.blog_share a i{
    color: white;
    font-size: 1.5rem;
}
#singleBlogContainer ul,#singleBlogContainer ol{
    padding-left: 1rem;
    padding-top: 0px;
}
.blogCover .blogImageContainer{
    height: 100%;
}
.blogCover img{
    height: calc(70vh - 4rem);
    width: 100%;
    object-fit: cover;

   

}
.twitter-share-button{
    background-color: none;
}
.commentsContainer {
    border-top: 1px solid lightgray;
    padding: 1rem;
    width:100%;
    margin-top: 50px;
}
.allComments ul{
    list-style: none;
    padding : 0px;
    margin: 20px 0px;

}
.allComments ul .commentCard {
    border-bottom: 1px solid rgb(235, 235, 235);
    padding: 0.5rem;
    padding-bottom: 1rem;
    flex-direction: column;
    justify-content: start;
    align-items: flex-start;
}
.commentor_data {
    display: flex;
    width: 100%;
    justify-content: space-between;
}
.comment_author {
    font-weight: 600;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 0.6rem;
    color: #3c3c56;
    font-size: 19px;
    
}

.comment_content{
    font-weight: 300;
    font-size: 14px;
}
.alert_fail {
    padding: 0.5rem;
    border-left: 5px solid #adb;
    background: #e9fff1;
    box-shadow: 1px 1px 6px lightgrey;
    border-radius: 6px;
    color: #3a754e;
    display: none;
}
.addComment {
    margin-top: 2rem;
    background: #e6e6e6;
    padding: 2rem 1rem;
    position: relative;
    overflow: hidden;
    border-radius: 5px;
}
.addComment h4 {
    font-size: 1.1rem;
    position: relative;
    z-index: 20;
   
    text-transform: uppercase;
    color: var(--primary-color-blue__dark);
    font-weight: 700;
    padding-left: 0.7rem;
    margin-bottom: 1rem;
}
.addComment h4::before{
    content: '';
    height:100%;
    width:0.5rem;
    background-color:var(--primary-color-green);
    position: absolute;
    top:0;
    left: 0;
}
.addComment form {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 1rem;
}
.addComment form input {
    width: 100%;
    height: 3rem;
    grid-column: 1 / 3;
    outline: none;
    border: none;
    position: relative;
    z-index: 20;
    padding-left: 0.5rem;
    border-radius: 5px;
   
}
.addComment form textarea{
    grid-column: 1 / 3;
    outline: none;
    border-radius: 5px;
    height: 8rem;
    position: relative;
    z-index: 20;
    border: none;
    overflow: auto;
    resize: vertical;
    padding-left: 0.5rem;
    
}
.addComment .actionBtn {
    font-size: smaller;
    text-transform: uppercase;
  
    justify-content: center;
    height: 3rem;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 1rem;
}
/* .addComment::after {
    clip-path: circle(50% at 50% 50%);
    content: ' ';
    width: 8rem;
    height: 8rem;
    background: #e5b595;
    position: absolute;
    top: -4rem;
    left: -3rem;
    z-index: 0;
} */
.allComments ul{
    padding-left: 0 !important;
}
.commentCard::before{
    content:''!important;
}
.section_hrefs a{
    color: var(--secondary-color-blue__dark);
    /* font-weight: 800; */
    border-bottom: none;
    position: relative;
    width: -moz-fit-content;
    width: -webkit-fit-content;
    width: fit-content;
    padding-right: 8px;
}

/* references */
#references a{
    color: var(--secondary-color-blue__dark);
    border-bottom: none;
    font-weight: 500;
}
@media(max-width:570px){

    #singleBlogContainer{
        padding-left: 0;
        padding-right: 0;
        grid-template-columns: 100vw;
        max-width: unset;
        margin-right: unset;
        margin-left: unset;
    }
    #singleBlogContainer p,#singleBlogContainer ol,#singleBlogContainer ul,#singleBlogContainer li {
        font-size: 17px !important;
    }
    .sectionImg img{
        height:30rem;
       
        margin: 1rem;
    }
    .section_desc{
        
        font-size: 17px !important;
        line-height: 27px;
    }
    
    .blog-center{
        max-width: 100vw;
    }
    .blogCover{
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 40vh auto;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        
    }
    .blog_desc{
        padding: 1rem;
    }
    .blogCover .blogImageContainer,.blogCover img{
        height: 100%;
        object-fit: cover;
    }
}
.category-title-container{
    padding: 7rem;
    padding-top:7rem;
    padding-bottom: 0;
    position: relative;
    background-size: cover ;
    background-position: center;
    color:white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: var(--primary-color-blue__dark);
    min-height: 50vh;
}
.header-quote{
    font-style: italic;
    opacity: 1;
    
    color: rgb(180, 178, 178);
    text-align: start;
    width: 100%;
    line-height: 5rem;
    font-weight: 700;
    letter-spacing: 0.5px;
    padding-bottom: 1rem;
   
}
.header-quote i{
    opacity: 0.5;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    position: relative;
    top:-0.5rem;
    color:var(--primary-color-green)
}


.category-title-container *{
    position:relative;
    z-index: 4;
}
.all-category-blogs{
    display:grid;
  
    padding-top: 2rem;
    grid-gap: 1.5rem;
}
.all-category-blogs > a:first-child{
    grid-column: 1 / 3;
    grid-row: 1 / 3;
    height: 100%;
}
.category-blogs-page{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 1rem;
    gap: 1rem;
}
.category-blog-carousel{
    display: flex;
    align-items: center;
    justify-content: end;
    grid-gap: 1rem;
    gap: 1rem;
}
.category-blog-carousel .carousel__pages span{
    color:gray;
    padding-left: 0.4rem;
}
.category-blog-carousel .carousel__pages span.active{
    color: var(--primary-color-blue);
}
.carousel__btn{
    height: 2rem;
    width: 2rem;
    border-radius: 50%;
    background-color: var(--primary-color-blue);
    color:var(--primary-color-green);
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
@media screen and (max-width:900px) {
    .category-title-container{
        padding: 2rem;
        min-height: 60vh;
        padding-top:4rem;
        padding-bottom:0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .all-category-blogs {
        display: grid;
        grid-template-columns: 1fr;
       
    }
    .header-quote{
        font-style: italic;
        margin-top: auto;
        opacity: 1;
       
        line-height: 3.5rem;
        color: rgb(186 186 186);
        text-align:start;
        width: 100%;
        
    }
    .category-blogs-page{
        grid-template-columns: 1fr;
    }
}


#recipe-blog-container{
    background-color: white;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    max-height: -webkit-fit-content;
    max-height: -moz-fit-content;
    max-height: fit-content;
    padding: 0;
    padding-top: 0;
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    
    letter-spacing: 1px;
    
}
.head-4{
    padding-top: 1rem;
}
.ingredients{
    display: flex;
    flex-direction: column;
}
.section-ul{
    list-style: disc;
    padding-top: 0;
   
}
.section-li{
    display: list-item;
    
}
.career-page-main-container{
    min-height: 100vh;
}
.career-page-main-container .header-container {
    background-color: var(--primary-color-blue__dark);
    color: white;
    height: 40vh;
    display: flex;
    flex-direction: column;
    justify-content: end;
    padding-left: var(--padding-lr__desktop);
    
}
.career-category{
    padding: 3rem var(--padding-lr__desktop);
    
}

.career-roles__one__heading{
    font-size: 2rem;
    font-family: var(--primary-typeface);
    font-weight: 600;

}
.about-neodocs-culture{
    width: 70%;
    padding: var(--padding-tb__desktop) var(--padding-lr__desktop);
}
.career-category__roles{
    
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 3rem;
    gap: 3rem;
}
.career-roles__one__categories{
    display: flex;
    flex-wrap: wrap;
    grid-gap: 1rem;
    gap: 1rem;
    text-transform: capitalize;
    padding-bottom: 1rem;
}
.career-roles__one__categories div{
    background-color: rgb(219, 219, 219);
    border-radius: 25px;
    padding: 0rem 1rem;
    font-size: smaller;

}
.career-roles__one{
    color: var(--primary-color-blue__dark);
}
.career-category__heading{
    text-transform: uppercase;
    font-size: 1rem;
    font-weight: 600;
    color: #9e9e9e;
}
@media screen and (max-width:900px) {
    .career-page-main-container .header-container {
        height: 25vh;
    }
    .career-category__roles{
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 1rem;
        gap: 1rem;
    }
    .about-neodocs-culture{
        width: 100%;
       
    }
}
#questionnaire-main-container{
    display: grid;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    width: 100vw;
    grid-template-columns: 1fr;
    background-color: var(--secondary-color-purple);
    color: white;
}
.questionnaire-main-slider{
    width: 50%;
    max-width: 50%;
    margin-left: auto;
    margin-right: auto;
}
.questionnaire-wrapper{
    display: none;
    padding: 4rem 1rem;
}
.questionnaire-loader{
    padding: 3rem 0rem;
    margin-right: auto;
    margin-left: auto;
    width: 50%;
}
.questionnaire-loader .user_details_form{
    display: grid;
    grid-gap: 2rem;
    grid-gap: 2rem;
    gap: 2rem;
    /* grid-template-columns: 1fr 1fr; */
    width: 50%;
}
.questionnaire-loader #login-button{
    display: none;
}

.questionnaire-main-slider .optionContainer{
   
    background-color: white;
    color: var(--secondary-color-purple);
    font-weight: bold;
    border: none;
    padding: 0rem;
    margin-top: 1rem;
    transition: all 0.2s linear;
}
.questionnaire-main-slider .optionContainer label{
    padding: 1rem;
    cursor: pointer;
}
.questionnaire-main-slider .optionContainer input{
    opacity: 0;
    height: 0;
    width: 0;
}
.questionnaire-main-slider .optionContainer.checked{
    border:2px solid rgb(182, 182, 226)
}
.questionnaire-main-slider .optionContainer .opt_name{
    font-weight: 600;
}
.questionnaire-main-slider .questionButtons {
    display: flex;
    margin-top: 2rem;
    padding: 1rem 0;
    justify-content: space-between;
}
.questionslide_title{
    padding-bottom: 0;
}
.questionButtons div{
    background-color: var(--primary-color-green);
    box-shadow: none;
    border: none;
    color: var(--primary-color-blue);
}
.upload-report-whatsapp-link-container a{
    background-color: white;
    padding: 0.9rem;
    border-radius: 8px;
    margin-top: 1rem;
    font-weight: 700;
    display: block;
    color: var(--secondary-color-purple);
}
.questionContainer img{
    height:10rem;
    margin-right: auto;
    margin-left: auto;
}
.questionnaire-main-slider .optionContainer:hover{
    border:2px solid rgb(182, 182, 226)
}

.results-infographics{
    position: relative;
    padding-top: 4rem;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
}

.results-infographics #arrow{
    position: absolute;
    left: 0;
    top:0;
    width: 6rem;
    height: 6rem;
}
.results-infographics #linear-gauge{
    width: 100%;
}
.user_details_form .otpInputContainer {
    justify-content: space-between;
}
.result-texts{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    font-size: smaller;

}
.result-texts >div{
    padding-left: 0.25rem;
    
}

@media screen and (max-width:700px) {
    .questionnaire-main-slider{
        width: 100%;
        max-width: 100%;
    }
    .questionnaire-loader{
        padding: 3rem 1rem;
        margin-right: auto;
        margin-left: auto;
        width: 100%;
  
    }
    .questionnaire-loader .user_details_form{
        grid-template-columns: 1fr;
        grid-gap: 1rem;
        gap: 1rem;
        width: 100%;
    }
    
    .results-infographics{
        padding-top: 2rem;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
    }
    .results-infographics #arrow {
      
        width: 3rem;
        height: 3rem;
    }
}

.one-career-page-main-container{
    min-height: 100vh;
    padding: var(--padding-tb__desktop) calc(var(--padding-lr__desktop) + 3rem);
    display: grid;
    grid-gap: 1.5rem;
    gap: 1.5rem;
}
.one-career-page-main-container .big-header-h1{
    font-size: 1.8rem;
    color: var(--primary-color-blue);
}
.one-career__small-header{
    font-weight: 600;
    color: var(--secondary-color-purple__dark);
}

.job-desc__one-block p li{
 padding-left: 0.3rem;
}
.job-desc__one-block .big-header-h1{
    padding-bottom: 0.5rem;
}
.career-illus{
    
   
    position: relative;
    height: 40vh;
    object-fit: cover;
    overflow: hidden;
    width: 100%;
}
@media screen and(max-width:800px) {
    .one-career-page-main-container{

        padding: var(--padding-tb__desktop) var(--padding-lr__desktop);
        
    }
}
.up-hero{
    background-color: var(--secondary-color-purple__dark);
    color: var(--primary-color-white);
    display: grid;
    align-items: center;
    grid-gap: 2rem;
    gap: 2rem;
    padding-bottom: 0;
    
}
.up-hero .big-header-h2{
    font-size: 2rem;
}
.up-introdesc{
    min-height: 70vh;
    background-color: var(--primary-color-blue__dark);
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
}
.up-introdesc .big-header-h1{
    color: var(--primary-color-green);
}
.up-introdesc img{
    max-width: 100%;
 
}
.up-short-desc{
    width: 70%;
    padding-top: 1rem;
    font-weight: 500;
    color: var(--primary-color-white);
}
.up-short-desc strong{
    color: white;
    font-weight: 700;
}
.up-steps{
    
    background-color: #F4F4F4;
    max-height: -webkit-fit-content;
    max-height: -moz-fit-content;
    max-height: fit-content;
}
.up-testkit{
    background-color: var(--secondary-color-purple);
    color: white;
}
.up-testkit img{
    width: 100%;
    max-height: 70vh;
}
.up-each-step{
    display: grid;
    justify-items: center;
}
.up-steps{
    
    display: grid;
    
    
}
.up-steps-desc{
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    grid-gap: 3rem;
    gap: 3rem;
    padding-top: 2rem;
}
.up-each-step .step-no{
    font-size:3rem;
    font-weight: 700;
    opacity: 0.5;
    color: var(--secondary-color-blue__dark);
}
.up-each-step .step-desc{
    font-size: 1.5rem;
    text-align: center;
    color: var(--primary-color-blue__dark);
}
.one-step .step-no{
    
    font-size: 2rem;
    font-weight: 700;
}
.one-step .step-desc{
    font-size: 1.5rem;
    
}
.up-meta-data{
    background-color: var(--primary-color-blue__dark);
    display: grid;
    grid-auto-flow: column;
    justify-content: space-between;
    grid-gap: 2rem;
    gap: 2rem;
    color: var(--primary-color-white);
}
.up-meta-data__title{
    font-size: smaller;
    text-transform: uppercase;
    font-weight: 600;
}
.up-meta-data__desc{
    color: white;
}

@media screen and (max-width:450px) {
    .up-steps-container{
        grid-template-columns: 1fr;
    }

    .up-each-step .step-desc{
        font-size: 1.2rem;
    }
    .up-introdesc{
        
        grid-template-columns: 1fr;
        justify-content: center;
    }
    .up-introdesc img{
        order: -2;
        padding-bottom: 1rem;
    }
    .up-meta-data{
        grid-auto-flow: row;
    }
    .up-short-desc{
        width: 100%;}
        .up-steps{
            max-height: -webkit-fit-content;
            max-height: -moz-fit-content;
            max-height: fit-content;
        }
        
        .up-steps-desc{

            display: grid;
            grid-template-columns: 1fr;
            width: 100%;
            min-height: 100vh;
            height: -webkit-fit-content;
            height: -moz-fit-content;
            height: fit-content;
        }
}
.accuracy-hero{
    position: relative;
    display: flex;
    align-items: flex-end;
    padding-bottom: 2rem;
}

.accuracy-hero::after{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
}
.accuracy-hero>*{
    position: relative;
    z-index: 99;
}
.image-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 50%;
}
.image-box img{
    height: 10rem;
}
.accuracy-details{
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 4rem;
    gap: 4rem;
    background-color: var(--secondary-color-purple__dark);
    color: #d5d5d5;
}
.accuracy-details .big-header-h2{
    position: relative;
    left: 1rem;
}
.accuracy-details .sub-header{
    font-size: 1.2rem;
}
.accuracy-details .big-header-h2::before{
    content: '';
    height: 100%;
    position: absolute;
    left: -1rem;
    top: 0;
    width: 0.5rem;
    background-color: var(--primary-color-green);
}
.our-thirdparty img{
    width: 60vw;
}
@media screen and (max-width:800px) {
    .image-box img{
        height: 5rem;
    }
    .our-thirdparty img{
        width: 90vw;
    }
    .accuracy-details .sub-header {
        font-size: 1rem;
    }
}

#hcg-hero{
    background-color: var(--secondary-color-purple);
    color: #e5e5e5;
    min-height: 80vh;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
}
.hero-img{
    position: relative;
    padding-left: 2rem;
}
#hcg-hero img {
   max-height: 70vh;
   position: relative;
   top: var(--padding-tb__desktop);
}
.hcg-test-properties{
    padding-top: 4rem;
    display: grid;
    grid-auto-flow: column;
    color: var(--secondary-color-purple__dark);
    grid-gap: 1rem;
    gap: 1rem;
    text-align: center;
    font-size: large;
    font-weight: 600;
}
.one-property{
    display: grid;
}
.one-property__image{

    height: 7rem;
  
    align-self: center;
    justify-self: center;
}
.one-property__image img{
   height: 6rem; 
}
.one-hcg-step{
    display: grid;
    grid-template-columns: auto auto;
    align-items: center;
    font-size: 1.5rem;
    font-weight: 400;
    grid-gap: 1rem;
    gap: 1rem;
    padding-top: 1rem;
    justify-content: center;
}

.one-hcg-step__image{
    width: 15rem;
    display: flex;
    align-items: center;
    justify-content: center;
}
.one-hcg-step__image img{
    height: 12rem;
}
.hcg-steps{
    background-color: var(--secondary-color-purple);
    color: white;
}
.step-hcg-no{
    font-weight: 700;
    font-size: 3rem;
    padding-right: 1rem;
    color: var(--primary-color-green);
}
@media screen and (max-width:700px) {
    .hcg-test-properties{
        grid-auto-flow: row;
        grid-gap: 1rem;
        gap: 1rem;
      
       
    }
    #hcg-hero{
        min-height: 100vh;
        grid-template-columns: 1fr;
    }
    h2{
        font-size: 1.2rem;
    }
    h1:not(.big-header-h1){
        font-size: 1.5rem;
        /* color: var(--primary-color-blue__dark); */
    }
    .one-hcg-step{
        grid-template-columns: 1fr;
        font-size: 1.2rem;
    }
    .one-hcg-step__image img{
        height:9rem;
    }
    .hero-img{
        padding-left: 0;
    }
    .hero-img img{
        padding-left: 0;
    }
    #hcg-hero img {
       height: 50vh;
        top: 3rem;
    }
    .step-hcg-no{
        font-size: 2.2rem;
    }
}


#header .nav{
  text-decoration: none;
  color: white!important;
  text-decoration: none!important;
  text-transform: uppercase;
  font-size: 13px;
}
a{
  text-decoration: none!important;
  
}
#header{
  padding: 1rem var(--padding-lr__desktop);
  position: fixed;
  top:0;
  
  width: 100%;
  display: flex;
  justify-content: space-between;
  z-index: 900;
  align-items: center;
  background-color: var(--primary-color-blue__dark);
}
#neodocs_logo{
  display: flex;
  align-items: center;
}
#neodocs_logo img{
  height: 2.7rem;
  cursor: pointer;
}
#navs{
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
  font-weight: bold;
  letter-spacing: 0.5px;
}
#inner-navs{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: auto;
  margin-left: auto;
}
#inner-navs .nav{
  margin-right: 1.5rem;
  text-decoration: none;
  height: 100%;
  align-items: center;
}
#mobile_navs{
  display: none;
  left: 100vw;
}
.hamburger{
  display: none;
  position: relative;
  z-index: 901;
}
/* products nav */
.products-nav{
  width: 100%;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

}

.products-nav .dropdown-container{
  display: none;
  position: absolute;
  top: 4rem;
  left: 0;
  width: 100vw;
  z-index: 88;
  background-color: var(--secondary-color-purple__light);
  flex-wrap: wrap;
  gap: 2rem;
  color: white;
  padding: 2rem var(--padding-lr__desktop);
  transition: all 0.5s ease;
  
}
.products-nav:hover .dropdown-container{
  display: flex;
  transition: all 0.5s ease;
  justify-content: center;
  align-items: center;
  
}

.one-product{
  display: grid;
  grid-template-columns: 5rem auto;
  gap: 1rem;
  align-items: center;
  background-color: white;
  box-shadow: 1px 2px 13px rgba(233, 233, 233, 0.644);
  border-radius: 8px;
  padding-right: 1rem;
  padding-left: 1rem;
}
.one-product:hover{
  background-color: rgb(245, 245, 253);
  transition: all 0.2s ease;
}
.one-product__image img{
  height: 5rem;
  border-radius: 5px;
}
.one-product__name{
  order: -1;
  color: var(--primary-color-blue);
  font-size: 18px;
  text-transform: capitalize;
  font-weight: 600;
  font-family: var(--primary-typeface);
}
@media screen and (max-width:900px) {
  #header{
    padding: 1rem var(--padding-lr__mobile);
    background-color: var(--primary-color-blue__dark);
  }
  #neodocs_logo{
  position: relative;
    z-index: 4444;}
  #header .nav{
    color: white !important;
  }
  #navs,#inner-navs{
    display: none;
  }
  #neodocs_logo img{
    height: 2.5rem;
  }
  #mobile_navs{
    box-sizing: border-box;
    position: absolute;
    height: 100vh;
    width: 100vw;
    top: 0;
    bottom: 0;
    left: 100vw;
    right: 0;
   
    background-color: var(--primary-color-blue__dark);
    display: flex;
  flex-direction: column;
    /* justify-content: center; */
    color: white;
    font-size: 1.8rem;
    z-index: 900;
    gap: 3rem;
  }
  .mobile_navs__primary{
    padding-top: 7rem;
  }
  
  .mobile_navs__bottom{
    justify-self: end;
    margin-top: auto;
    background-color: var(--secondary-color-purple);
   padding: 2rem;
  }
  #mobile_navs .nav{
    color:rgb(224, 224, 224);
    margin-bottom: 1rem;
    font-size: 1.5rem;
    padding-left: 2rem;
    text-transform: uppercase;
    font-weight: 600;
  }
  #mobile_navs .secondary-nav{
    color: white;
    font-size: 20px;
    padding-left: 2rem;
    font-weight: 300;
  }
  .products-nav{
    align-items: flex-start;
  }
  .hamburger{
    height: 0.8rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .hamburger div{
    height: 3px;
    width: 25px;
    
    
    background-color: white;
  }
  .hamburger.active div{
    background-color:white;
    transition: all 0.9s cubic-bezier(0.075, 0.82, 0.165, 1);
  }
  .hamburger.active .line1{
    width: 1.3em;
    transform: rotate(-45deg);
    transform-origin: 14px 12px;
  }
  
  .hamburger.active .line2{
    width: 1.3em;
    transform: rotate(
45deg);
    transform-origin: 13px -7px;
  }
  .products-nav .dropdown-container,.products-nav:hover .dropdown-container{
    display: grid;
    grid-template-columns: 1fr 1fr;
    position: relative;
    top: unset;
    left: -2rem;
    width: 100vw;
    z-index: 88;
    
  
    gap: 1rem;
 
    padding: 2rem var(--padding-lr__mobile);
    
  }
  .one-product{
    display: grid;
    grid-template-columns: auto auto;
    gap: 0.5rem;
    align-items: center;
    box-shadow: 1px 1px 16px #9f9fbd;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  
  .one-product__name{
    font-size: 13px;
  }
  .one-product__image img{
    height: 3.2rem;
   
  }
  
  
}

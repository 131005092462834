

.faqHead{
    background-color: var(--primary-color-blue__dark);
    color: var(--primary-color-white);
}


.contactus {
    width: 500px;
    margin: 0 auto;
    padding: 20px;
  }
  
.contactus input,
.contactus textarea {
display: block;
padding: 10px 20px;
width: 100%;
margin: 20px 0px;
}

textarea {
    height: 200px;
}

.contactus button {
    background-color: #000;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.contactus-hidden {
    display: none;
  }
  
.contactus-success {
display: block;
}
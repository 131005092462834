.recommendationsContainer{
    border-radius:5px;
    padding:0.5rem;
    margin-top:1rem;
    background-color: white;
}
.recommendationContainer{
    border-radius:5px;
    padding:1rem;
    margin-bottom:1rem;
}
.recm-title{
    text-transform: capitalize;
    color:#000646;
    margin-bottom: 0px;
}
.title_wrap img{
    padding-right:0.5rem ;
}
.title_wrap{
    display: flex;
    align-items: center;
}
.head{
 
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.5rem;
}
.knowMore{
    color:#FF5F45;
    font-size: smaller;
}
ul{
    list-style: none;
}
span.recmListTitle{
    text-transform: capitalize;
    font-weight: 600;
   
}
span.recmListItems{
    font-weight: 300;
    text-align: justify;
    overflow-wrap: break-word;
}
span.recmListTitle i{
    margin-right: 3px;
}
.recmListItemContainer{
    margin-top: 0.5rem;
    display: block
}
.recmIcon{
    height: 23px;
}


/* diet recommendations */
.diet_recm{
    background-color: white;
    border-radius: 5px;
}
.includeList,.reduceList,.avoidList{
    padding-top: 0;
}
.includeContainer,.reduceContainer,.avoidContainer{
    padding-bottom: 1rem;
    margin-top: 1rem;
    padding: 1rem;
    background-color: #fdd2ca;
}
.includeHead{
    text-transform:uppercase;
    font-weight: 900;
    letter-spacing: 1px;
    text-align: center;
    font-size: 1.2rem ;
    color:green;
    margin-bottom: 0.5rem;

}

.bx-check-circle{
    color:green;
}
.bx-down-arrow-circle{
    color:#da7c29;
}
.bx-minus-circle{
color:#af1616;
}
.optionContainerWrapper{
    display: flex;
    padding: 1rem;
    flex-direction: column;
}
.includeContainer{
    order:-2;
    background:#bfedbf;
    padding: 1rem;
    margin-top: 0;
}
.reduceContainer{
    order:-1;
    background:#f1dcb5;
    padding: 1rem;
}
.reduceHead{
    
    text-transform:uppercase;
    font-weight: 900;
    letter-spacing: 1px;
    text-align: center;
    font-size: 1.2rem ;
    color:#ad5e19;
    margin-bottom: 0.5rem;

}
.avoidHead{
    
    color:#af1616;
    
    text-transform:uppercase;
    font-weight: 900;
    letter-spacing: 1px;
    text-align: center;
    font-size: 1.2rem ;
    
    margin-bottom: 0.5rem
}
/* toggle switch  */
.switchWrapper{
    width:100%;
    display: flex;
    
    align-items: center;
    padding-left: 1rem;
    padding-top: 1rem;
}
.switch{
    position: relative;
    height:20px;
    width:41px;
    display: flex;
    align-items: center;
    margin:0px;
}
.switch input{
    
opacity: 0;


}
.slider{
    position: absolute;
    cursor: pointer;
    top:0;
    bottom: 0;
    left:0;
    right: 0;
    background-color: lightgray;
    border-radius: 25px;
}

.slider-before{
    
    position: absolute;
    
    
    background-color: white;
    height:20px;
    width:20px;
    transition:0.4s;
    border:1px solid lightgray;
    border-radius:50%;

}
.dot{
    content:'';
    position: absolute;
    top:4px;
    left:4px;
    border-radius: 50%;
    height:12px;
    width:12px;
    background-color: white;

}

input:checked + .slider .slider-before{
   
    border:1px solid green;
    transform: translateX(22px);
}
input:checked + .slider .slider-before::before{
    background-color: green;
}
input:checked + .slider{
    background-color: green;
}


/* ALL RECOMMENDATIONS */
.allRecommendations .nav{
    display: grid;
    
    justify-content: unset;
background-color: white;
    overflow-x: scroll;
    margin-top: 3.5rem;
    column-gap: 1rem;
    
  
}
.allRecommendations .nav::-webkit-scrollbar-track{
    background-color: rgb(224, 224, 224);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.filterSticker{
    margin-left: 0.5rem;
    width: 1.6rem;
    height: 1.6rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid lightgray;
    border-radius: 50%;
    box-shadow: 1px 2px 4px lightgrey;
    background-color: #f9f9f9;
}
.stickerIcon{
    width:1rem;
    height: 1rem;
}


.future-plans-conatiner-full{
    padding: 5rem 30rem;
    background-color: var(--primary-color-blue__dark);
    color: var(--primary-color-white);
}
.future-plans-conatiner-full>h1{
    font-size: 5rem;
    color: rgb(199, 199, 199);
}
.one-plan-box{
    position: relative;
    height: 20rem;
    /* background-color: var(--primary-color-green__light); */
    padding: 2rem;
    width: 20rem;
    z-index: 20;
    border-radius: 12px;
    color: var(--primary-color-blue);
    line-height: unset;
}
.one-plan-box svg.blob{
    position: absolute;
    top:0;
    left: 0;
    bottom: 0;
    right: 0;
}
.plan-right-box{
    margin-left: auto;
}
.one-path-box{
    height: 20rem;
    position: relative;
}
.plan-name{
    
    font-weight: 800;
    font-family: var(--primary-typeface);
}
.white-cover{
    position: absolute;
    top:0;
    height: 100%;
    width: 100%;
    background-color: var(--primary-color-blue__dark);
    z-index: 10;
}
@media screen and (max-width:900px) {
    .future-plans-conatiner-full{
        padding: 5rem 2rem;
    }
    .one-plan-box{
        height: 20rem;
        
        padding: 1rem;
        width: 90%;
    }
}
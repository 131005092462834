.uploadProfile{
    padding: 5rem 1rem;
}
.uploadReportBtn{
      
    width: 17rem;
    height: 3.5rem;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    background-color: rgba(0,0,0,0.9);
    border-radius: 18px;
    color:white;
    margin-top: 2rem;
    text-transform: uppercase;
    box-shadow: 1px 1.2px 5px #4a4a4ac2
}
.uploadProfile label{
    display: flex;
    justify-content: center;
    align-items: center;
}
.uploadReportBtn p{
    display: flex;
    align-items: center;
}
.uploadReportBtn i {
    font-size: 1.4rem;
    margin-right: 1rem;
}
.accuracy-hero{
    position: relative;
    display: flex;
    align-items: flex-end;
    padding-bottom: 2rem;
}

.accuracy-hero::after{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
}
.accuracy-hero>*{
    position: relative;
    z-index: 99;
}
.image-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 50%;
}
.image-box img{
    height: 10rem;
}
.accuracy-details{
    display: grid;
    grid-template-columns: 1fr;
    gap: 4rem;
    background-color: var(--secondary-color-purple__dark);
    color: #d5d5d5;
}
.accuracy-details .big-header-h2{
    position: relative;
    left: 1rem;
}
.accuracy-details .sub-header{
    font-size: 1.2rem;
}
.accuracy-details .big-header-h2::before{
    content: '';
    height: 100%;
    position: absolute;
    left: -1rem;
    top: 0;
    width: 0.5rem;
    background-color: var(--primary-color-green);
}
.our-thirdparty img{
    width: 60vw;
}
@media screen and (max-width:800px) {
    .image-box img{
        height: 5rem;
    }
    .our-thirdparty img{
        width: 90vw;
    }
    .accuracy-details .sub-header {
        font-size: 1rem;
    }
}

input[type='text'].input,
input[type='number'].input{
    height: 3rem;
    padding: 0.5rem;
    background-color: white;
    border: none;
    outline: none;
    border-radius: 12px;
    min-width: 10rem;
    justify-content: center;
    cursor: pointer;
    color: black;
}
input[type='text'].input:focus,input[type='number'].input:focus{
    border:3px solid var(--secondary-color-purple)
}
label.inputWrap{
    cursor: pointer;
    min-width: 10rem;
    display: flex;
    background-color: rgb(241, 241, 251);
    padding: 0.8rem;
    margin-top: 0.5rem;
    border-radius: 12px;
    transition: all 0.2s linear;
    justify-content: center;
    align-items: center;
    
}
.inputWrap p{
    color: var(--primary-color-blue);
}
label.inputWrap.active{
    border:3px solid var(--secondary-color-purple);
}
input[type='radio'].input,input[type='checkbox'].input{
    height: 0;
    width: 0;
}

.diet_slider {
    width: 80%;
    position: relative;
}
.diet_slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 25px;
    height: 25px;
    border-radius: 50%; 
    background: #04AA6D;
    cursor: pointer;
  }
  
  .diet_slider::-moz-range-thumb {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: #04AA6D;
    cursor: pointer;
  }

#diet_range {
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    height: 10px;
    background: white;
    outline: none;
    opacity: 0.7;
    border-radius: 25px;
    -webkit-transition: .2s;
    transition: opacity .2s;
}
.options{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
}
.slider_label {
    position: absolute;
    top:3.3rem;
}


/* keyboard inputs */
.keyboard-input{
    display: flex;
    padding-top: 1.6rem;
    position: relative;
}
.keyboard-input__label{
    position: absolute;
    left: 0%;
    top: 0;
}
.keyboard-input__inputbox{
    border: none;
    outline: none;
    height: 3rem;
    padding-left: 1rem;
    background: white;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    width: 90%;
}
.keyboard-input__input-phase-container{
    background: lavender;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    width: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.keyboard-input .bxs-check-circle{
    color: rgb(91, 179, 91);
    font-size: 1.3rem;
}
@media screen and (max-width:900px) {
    label.inputWrap{
        padding: 0.8rem;
    }
}
/* svg */



.hero{
    height: 60vh;
    background-color: var(--secondary-color-purple__dark);
    color:white;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: calc(var(--padding));
    
}


.ourBlogs,.science{
    background-color: white;
}

section.investors{

    padding-top: 2rem;
    padding-bottom: 2rem;
    background-color: white;
}
.ourStory{
 
    background-color: white;
    font-weight: 400;
    display:grid;
    grid-template-columns:0.5fr 1fr
}
.ourStory h1{
    font-size: 5.5rem;
    color: var(--secondary-color-purple__light);
}

/* team */
section.team{
    background-color: var(--secondary-color-purple__dark);
    color:var(--primary-color-white);
    min-height: 70vh;
    position: relative;
}
section.team svg{
    position: absolute;
    height: 14rem;
    top:0;
    left:0;
    opacity: 0.3;
}
.team-members-container{
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap:2rem;
    padding-top: 2rem;
}
.one-team-member__image{
    height: 20rem;
    width: 90%;

    
}
.one-team-member__image img{
    height: 100%;
    width: 100%;
    object-fit: cover;
    filter: grayscale(0.3);
}
.one-team-member__meta{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.one-team-member__meta__name{
    font-family: var(--primary-typeface);
    font-weight: 800;
}
.textsHeads{
    display: flex;
    justify-content: center;
    align-items: center;  
}



.investors_container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-content: center;
    grid-gap: 5rem;
    margin-top: 3rem;
    justify-items: center;
}
.investor img{
    height: 2rem;
   
    object-fit: cover;
}
/* our focus */
section#our-focus{
    background-color: var(--secondary-color-purple__dark);
    position: relative;
    display: grid;
    grid-template-columns: 15rem auto;
}
img#our-focus__mobile{
    display: none;
}
img#our-focus__web{
    width: 80%;
    margin-right: auto;
    margin-left: auto;
    z-index: 7;
}
img#our-focus__people{
    max-height: 100vh;
    position: absolute;
    bottom: 0;
    right: 2rem;
    z-index: 6;
}
#our-focus__text-wrap .big-header-h1{
    font-size: 2.3rem;
}
#our-focus__text-wrap{
    color: white;
    font-size: 2.3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    align-self: flex-end;
    padding-bottom: 1rem;

}
#text-arrow{
    border: 2px solid white;
    border-radius: 50%;
    width: 3rem;
    height: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    order: 2;
}
#text-arrow .bx-up-arrow-alt{
    display: none;
}
/* contect us */
section.contactUs {
    background-color: var(--secondary-color-purple__dark);
    color: var(--primary-color-white);
    display: grid;
    grid-template-columns: 1fr 1fr;
 
    align-items: center;
    gap: 2rem;

}
.contactUs .socials{
    display: grid;
    grid-template-columns: 1fr 1fr;
    color: var(--primary-color-white);
    align-items: center;
    justify-items: center;
    gap: 0.5rem;
}

.contactUs .socials a{
    color: var(--primary-color-white);
    font-size: 3rem;
}
.newsletter {
    display: flex;
}
.newsletter input {
    height: 3rem;
    border: none;
    outline: none;
}

/* blogs */
.ourBlogs{
    background-color: var(--secondary-color-purple__dark);
    max-height: unset;
    display: grid;
    grid-template-columns: 1fr 0.5fr;
    gap: 1rem;
    position: relative;
    overflow: hidden;
}
.ourBlogs svg{
    position: absolute;
    height: 15rem;
    right:-10rem;
    top:-2rem;
    pointer-events: none;
}
.ourBlogs svg *{
    fill:var(--secondary-color-blue__light);
    opacity: 0.7;
}
.ourBlogs h2{
    color:var(--primary-color-white)
}
.blogs{
    position: relative;
   
}
.allBlogs{
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
    grid-gap: 2rem;
}
.blog_img{
    position: relative;
    height: 15rem;
}
.blog_img::after{
    content: '';
    position: absolute;
    top:0;
    left: 0;
    bottom: 0;
    right: 0;

    background: linear-gradient(to bottom, transparent, rgba(0,0,0,0.7));
}
.blog_img img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.ourBlogs .blog_title{
    position: absolute;
    font-family: inherit;
    bottom: 1rem;
    padding: 0 1rem;
    color: lightgrey;
    font-size: 1rem;
    font-weight: 500;
    letter-spacing: unset;
    line-height: unset;
}
.blog_title i{
    text-align: center;
    padding: 0;
}


@media screen and (max-width:500px) {
    
    
    
    section.science{
        padding: 1rem;
        padding-bottom: 3rem;
        padding-top: 0;
    }
    
    .ourStory{
        padding-top: 2rem;
        grid-template-columns: 1fr;
    }
    .ourStory h1{
        font-size: 4rem;
        color: var(--secondary-color-purple__light);
    }
    section.team h2::before {
        content: '';
        display: inline;
        width: 4rem;
        height: 3px;
        background-color: white;
    }
    .textsHead {
        grid-column-start: 1;
        grid-row-start: 1;
    }
    
    .investors_container{
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 1rem;
    }
    .investor {
        margin: 0;
        padding-bottom: 4rem;
        justify-items: center;
    }
    .investor img {
        height: 3rem;
        width: 100%;
        object-fit: contain;
    }
    .allBlogs{
        grid-auto-flow: row;
    }
    .team-members-container{
        grid-template-columns: 1fr;
    }
    .one-team-member__image{
        width: 100%;
        height: 19rem
    }
    section.contactUs{
        grid-template-columns: 1fr;
    }
    .contactUs .socials{
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    }
    .ourBlogs{
        grid-template-columns: 1fr;
    }
    .ourBlogs h2{
        order: -3;
        position: relative;
        z-index: 7;
    }
    section#our-focus{
        grid-template-columns: 1fr;
        padding-bottom: 17rem;
        gap: 1rem;
    }
    img#our-focus__mobile{
        display: block;
        z-index: 8;
        width: 80%;
        margin-right: auto;
        margin-left: auto;
    }
    img#our-focus__web{
        display: none;
    }
    img#our-focus__people{
        max-height: 18rem;
        left:-1rem;
        right: 0;
        
    }
    
    #our-focus__text-wrap .big-header-h1{
        font-size: 2.3rem;
    }
    #our-focus__text-wrap{
        order: 3;
    
    }
    #text-arrow{
        border: 2px solid white;
        border-radius: 50%;
        width: 3rem;
        height: 3rem;
        display: flex;
        justify-content: center;
        align-items: center;
        order: 2;
    }
    #text-arrow .bx-right-arrow-alt{
        display: none;
    }
    #text-arrow .bx-up-arrow-alt{
        display: block;
    }
}

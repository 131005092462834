*{
    box-sizing: border-box;
}
.registeration-form{
    display: grid;
    grid-template-columns: 1.2fr 1fr;
    min-height: 100vh;
    font-family: var(--secondary-typeface);
}
.registeration-form>div{
    padding: 7rem 4rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.registeration-form-title{
    padding-bottom: 1rem;
    color: var(--primary-color-blue);
}

.registeration-form  input,.registeration-form  select{
    height: 3rem !important;
    border-radius: 5px;
    border: 1px solid lightgray;
    padding-left: 0.5rem;
    margin-right: 1rem;
    margin-bottom: 1rem;
    width: 100%;
    color: var(--primary-color-blue__dark);
   
    padding-right: 0.5rem;
}
.registeration-form label{
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 700;
    font-family: var(--primary-typeface);
    color: var(--primary-color-blue__dark);
}
.registeration-form  input:focus,.registeration-form  select:focus{
    border: 1.5px solid var(--secondary-color-purple);
    transition: all 0.1s ease-out;
}
.registeration-form input:disabled{
    border: none;
    background-color: #E8E8E8;
    cursor: not-allowed;
    color: rgb(175, 175, 175);
}
.registeration-form-illus{
    display: flex;
    background: var(--secondary-color-purple__dark);
    justify-content: center;
    flex-direction: column;
    
}
.registeration-form-title{
    font-family: var(--primary-typeface);
}
.registeration-form .register-btn{
    background-color: var(--primary-color-green);
    color: var(--primary-color-blue__light);
    letter-spacing: 1px;
    padding: 0.2rem 1rem;
    width: 100%;
    height: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    font-weight: 600;
    cursor: pointer;text-transform: uppercase;
}

/* success page */
.successPage{
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width:1000px) {
    .registeration-form{
        display: grid;
        grid-template-columns: 1fr;
        min-height: 100vh;
    } 
    .registeration-form>div{
        padding: 2rem;
        width: 100vw;
    }
    .registeration-form-illus{
        display: flex;
        justify-content: start;
        align-items: start!important;
        padding: 5rem 2rem!important;
    }
    
}
/* color definition for each service */
.uti-service{
    --primary-color: #FFF6FA;
    --primary-color__light:#502f4c;
    --primary-color__dark: #5F2E59;
    --text-color__pink: pink;
    --text-color__white:white;
}
.pregnancy-service{
    --primary-color: #F7F3FF;
    --primary-color__light:#6e5497;
    --primary-color__dark: #61468D;
    --text-color__pink: pink;
    --text-color__white:white;
}
.ckd-service{
    --primary-color: #FFFAF8;
    --primary-color__light:#aa5a4c;
    --primary-color__dark: #d36450;
    --text-color__pink: pink;
    --text-color__white:white;
}
.elderlycare-service{
    --primary-color: #F3F9FF;
    --primary-color__light:#345880;
    --primary-color__dark: #426C9C;
    --text-color__pink: pink;
    --text-color__white:white;
}
.service-container #header{
    background-color: var(--primary-color__dark)!important;
}
.servicesHero{
    height: 60vh;
    position: relative;
    background-color: var(--primary-color__dark);
    display: flex;
}

.service-container .big-header-h1{
   font-size: 3.5rem; 
}
.servicesHero .text{
    color: var(--text-color__white);
    /* height: 100%; */
    display: grid;
    width: 80%;
    align-items: center;
    align-content: center;
    gap: 0.5rem;
    
}

.servicesHero img{
    padding-top: 10%;
    height: 150%;
}

.servicesHero #header{
    background-color: var(--primary-color);
}
.service-container .products-nav .dropdown-container{
    background-color: var(--primary-color);
}
.service-container .action-btn__primary{
    background-color: var(--primary-color);
    color:var(--primary-color__dark);
    width: 12rem;
    margin-right: auto;
}
.service-features{
    background-color: var(--primary-color__dark);
    color:var(--text-color__white);
    display: grid;
    gap: 2rem;
    grid-auto-flow: column;
    padding-top: 5rem;
    padding-bottom: 5rem;
}
.one-service-feature{
    display: grid;
    gap: 0.5rem;
    
    justify-content: center;
    justify-items: center;
    text-align: center;
}
.one-service-feature img{
    height: 4rem;
    opacity:0.8;
}
.one-feature__description{
    font-size: 1.5rem;
    font-weight: 300;
}
.one-feature__illustration svg{
    height: 3rem;
    opacity: 0.7;
}
/* tetsimonials */
.service-container #testimonials{
    background-color: var(--primary-color__dark);
}
.service-container .testimonial-card{
    background-color: var(--primary-color__light);
}
.service-container .testimonial-autor-designation{
    color:var(--text-color__pink)
}
.service-container .community-illustration__person{
    border: 3px solid var(--primary-color__light);
}

.service-container .community-illustration__person.you_div{
    border: 3px dashed var(--primary-color);
    background-color: var(--primary-color__dark);
}
.how-it-works-step-container{
    display: grid;
    padding-top: 2rem;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 3rem;
}
.how-it-works{
    background-color: var(--primary-color__dark);
    color: white!important;
}
.how-it-works-step-container .hiw-one-step{
    height: 12rem;
    font-size: 1.5rem;
    width: 100%;
    padding-top: 0;
    position: relative;
    display: grid;
    position: relative;
    gap: 1rem;
    grid-template-rows: 4rem auto;
    text-align: center;
    justify-content: center;
    font-weight: 600;
    font-family: var(--primary-typeface);
    
}
.how-it-works .big-header-h1{
    text-align: center;
    margin-right: auto;
    margin-left: auto;
}
.hiw-one-step .step-circle{
    position: relative;
    width:4rem;
    height:4rem;
    display: grid;
    align-items: center;
    justify-content: center;
    margin-right: auto;
    margin-left: auto;
    border-color: var(--primary-color__dark) ;
    color:var(--primary-color__dark)
}
.hiw-one-step .step-path{
    left: 50%;
    background-color: white;
    height: 2px;
    width: 100%;
    position: absolute;
    top: 2rem;
}
.doctors{
    display: grid;
    grid-auto-flow: column;
    gap: 2rem;
}
.doctor-card.testimonial-card{
    background-color: white;
    padding: 1rem;
    border-radius: 8px;
    width: 100%;
    grid-template-rows: 4rem auto;
}
.doctor-Img{
    display: flex;
    gap: 0.5rem;
    align-items: center;
    height: fit-content;
}
.doctor-Img img{
    height: 4rem;
    width: 4rem;
    border-radius: 50%;
    border: 2px solid var(--primary-color__dark);
}
.doctor-Note{
    position: relative;
    padding-left: 2.5rem;
    padding-top: 2rem;
}
.doctor-Note::before{
    content: "\eb75";
    position: absolute;
    font-family: boxicons!important;
    opacity: 0.4;
    font-size: 3.5rem;
    color:var(--primary-color__dark);
    left: 0rem;
    top: -0.5rem;
}
.doctor-Name{
    font-weight: 600;
    font-size: 25px;
    color:var(--primary-color__dark)
}
section.symptoms{
    display: grid;
    grid-template-columns: 1fr 1fr;
    background-color: var(--primary-color);
}
section.symptoms ul{
    padding-left: 0;
    display: grid;
    gap:1rem;
    padding-top: 1rem;
    
}
section.symptoms i{
    color:var(--primary-color__dark)!important;
    font-size: 2rem !important;
}
section.symptoms li{
    font-size: 1.3rem !important;
    display: flex;
    
}
section.symptoms .image img{
    height: 20rem;
}
.parameters-detected{
    display:grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
}
.parameters-detected .imageContainer{
    display: grid;
    padding-top: 2rem;
    justify-content: center;
    position: relative;
}
.imageContainer svg{
    position: absolute;
    height: 20rem;
  
    z-index: -1;
    left: 0.8rem;
    opacity: 0.5;
    top: 0rem;
}
.imageContainer svg *{
    fill: var(--primary-color__dark);
}
.parameters-detected img{
    height: 30rem;
}
.parameteres-detected-container{
    padding-top: 5rem;
    display: grid;
   grid-template-columns: 1fr 1fr;
    gap: 1rem;
}
.one-parameter-detected{
    font-size: 2rem;
    background: var(--primary-color);
    box-shadow: 1px 2px 8px var(--primary-color);
    padding: 0.5rem;
}
.one-parameter-detected__name{
    font-size:25px;
    font-weight:600;
    color:var(--primary-color__dark)
}
.one-parameter-detected__description{
    font-size: 14px;
}
.doctor-section{
    background-color: var(--primary-color);
    display: none;
}

@media screen and (max-width:500px) {
    .servicesHero{
        min-height: 60vh;
        height: fit-content;
        display: block;
    }
    .servicesHero img{
        padding: None;
        height: 400px;
    }
    .service-container .big-header-h1{
        font-size: 2.8rem;
    }
    .service-features{
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        gap: 3rem;
        border-top: 2px solid var(--primary-color);
    }
    .one-service-feature img{
        height: 3rem;
    }
    .one-feature__description{
        font-size: 1.1rem;
    }
    .one-parameter-detected{
        font-size: 1rem;
        
    }
    section.symptoms{
        grid-template-columns: 1fr;
    }
    section.symptoms .image{
        display: none;
    }
    .parameters-detected{
        grid-template-columns: 1fr;
    }
    .parameteres-detected-container{
        grid-template-columns: 1fr;
        grid-auto-flow: unset;
        padding-top: 1rem;
    }
    .one-parameter-detected__description{
        font-size: 14px;
    }
    .parameters-detected img{
        height: 25rem;
        padding-top: 0;
    }
    .imageContainer svg {
        
        height: 14rem;
      
        left: -2rem;
        
    }
    .doctors{
        grid-auto-flow: row;
    }
    .how-it-works-step-container{
        grid-template-columns: 1fr;
        gap: 2rem;
    }
    .how-it-works-step-container .hiw-one-step {
        height: 12rem;
        font-size: 1.5rem;
        padding-left: 0rem;
        padding-top: 0;
        position: relative;
        display: grid;
        grid-auto-flow: column;
        
        grid-template-rows: 1fr;
        grid-template-columns: 4rem auto;
        gap: 1rem;
        justify-content: unset;
        text-align: unset;
    }
    
    .hiw-one-step .step-path{
        width: 0px;
        display: none;
    }
    .doctor-card.testimonial-card{
        width: 17rem;
    }
    .doctor-Note{
        font-size: small;
    }

}

*{
    padding:0;
    margin:0;
}
p{
    margin:0;
}

#smartReportMainContainer *:not(i,.panelSummary){
    font-family: 'Manrope', sans-serif!important;
     
}
.panelSummary{
    font-family: 'Metropolis'!important;
    font-size: 14px!important;
}
#root,.mainContainer,.allRecommendations,.allRecommendations .tab-content,.allRecommendations .tab-content  .tab-pane,.allRecommendations .tab-content  .tab-pane >*{
    height: 100%;
}

/* patient data */

.personal-deets{
    
    color:white;
    
    padding-top: 1.5rem;
 
    display: flex;
    font-size: smaller;

    align-items: center;
    width: 100%;
    
}
.eachDeetHead{
    color:#565555;
    font-size: small;
    font-weight: 300;
}
.eachDeet{
    
    padding:0.7rem;
    flex-grow: 1;
    
}
.eachDeetDetail{
    color:rgb(71, 82, 141);
    font-size: smaller;
    font-weight: bolder;
}
.eachDeet:last-child{
    border-right: none;
   
}
.tab-container{
    width:90%;
    display: flex;
    flex-direction: column;
    padding:1rem;
    justify-content: space-evenly;
    
    margin-top: 1rem;
}
.personal-deets > p{
    margin:0.3rem;
}
.personal-deets >p .deetCat{
    font-weight:600;
    
}
#share-report-whatsapp{
    padding: 0.8rem 1rem;
    border-radius: 25px;
    background-color: white;
    border:1px solid rgb(71, 82, 141);
    font-weight: 800;
    color:rgb(71, 82, 141);
    position: absolute;
    bottom: -3rem;
    left: 1rem;
    transform: translateY(-50%);
    box-shadow: 0.5px 0.5px 0px rgb(71 82 141);
}
#share-report-whatsapp a{
    font-weight: 800;
    color:rgb(71, 82, 141);
}
.PatientInfo{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.PatientInfo .greetingMsgBox{
    display: flex;
    padding: 3.5rem 1rem;
    width: 100%;
    position: relative;
    background-color: rgb(71, 82, 141);
    
    /* background-position-x: 50%; */
    background-repeat: no-repeat;
    background-position: top left;
    border-bottom-right-radius: 123px;
}
.introp{
   
    width: 65%;
    color:white;
}
#smartReportMainContainer{
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
    font-family: 'Open Sans';
}
.overall{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top:1rem;
}
.deetCat{
    font-weight: bolder;
    margin-right: 4px;
}
/* overall health */
.progress-bar{
    height: 8rem;
    width: 8rem;
    border-radius: 15px;
    
    margin-right: 0.5rem;
    position: relative;
    background-color: inherit;
    }
    .circle-progress {
    fill: none;
    stroke-width: 6.2;
    stroke-opacity: 0.3;
    stroke-linecap: round;
    stroke: white;
    }
    
    .circle-progress-fill {
    --initialStroke: 0;
    --transitionDuration: 0;
    stroke-opacity: 1;
    stroke-dasharray: var(--initialStroke);
    stroke-dashoffset: var(--initialStroke);
    transition: stroke-dashoffset var(--transitionDuration) ease;
    
    
    }
    .score-text{
        color:white;
        font-weight: 900;
        margin-right: 1rem;
    }
    strong{
    font-weight: 500;
    }
    .desc{
    width:10rem;
    }
    #overall-wellness-score{
        font-family: 'Raleway','sans-serif';
        font-weight: 600;
        position: absolute;
        font-size: 2.3rem;
        top: 25px;
        right: 43px;
        width: 3rem;
        height: 3rem;
        /* margin-right: auto; */
        /* margin-left: auto; */
        margin-right: auto;
        /* transform: translate(-150%); */
        /* background-color: white; */
        border-radius: 50%;
    }
    #wellness-sticker{
        background-color: white;
        border-radius: 50%;
        height: 1.5rem;
        width: 1.5rem;
        position: absolute;
        bottom: 6px;
        right: 11px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
    }
    .bx{
        margin-right: 0px;
    }
    .wellness-score{
    border-radius:15px;
    flex-grow: 1;
    display: flex;
    
    justify-content: center;
    align-items: center;
   
    }
    
    .circle_wrapper{
    
    transform: rotate(-90deg);
    
    }
    /* modal */
    .disclaimerModalContainer{
        
    position: fixed; /* Stay in place */
    z-index: 9999999; /* Sit on top */
    padding-top: 100px; /* Location of the box */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
        overflow:auto;

    background-color: rgba(0,0,0,0.8); 
    }
    .disclaimerModal{
        background-color: white;
        width:50%;
        font-size: small;
        margin-right: auto;
        margin-left: auto;
        padding: 1rem;
        border-radius: 12px;
        display: flex;
        flex-direction: column;
        align-items: center;
        border:5px solid #e78888;
        position: relative;
        z-index: 900;
    }
    .modalOk{
        border-radius: 25px;
        background-color: rgb(71, 82, 141);
        color:white;
        font-weight: bolder;
        padding:10px 38px;
        margin-top: 1rem;
    }

    /* question */
    .QuestionWrapper{
        position: fixed;
    z-index: 1;
    
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0,0,0,0.7);
    z-index: 99999;
    }
    .QuestionContainer{
        position: fixed;
        bottom:0;
        width: 100%;
        display: flex;
        flex-direction: column;
        box-shadow: 0.3px -3.8px 9px #948c8cf1;
        background: white;
        height: 70%;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        

    }
    .questionHeader{
        padding: 1rem ;
        background-color: #3c9aff6b;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        color: darkslategray;

    }
    .questionTitle{
        font-weight: 700;
        font-family: 'Raleway','sans-serif';
    }
    .questionslide_title{
        padding-bottom: 1rem;
        font-weight: 800;
    }
    .questionContainer{
        padding: 1rem;
    }
    .questionButtons{
        display: flex;
        padding: 1rem;
        justify-content: space-around;
    }
    .questionButtons div{
        background-color: darkslategray;
        color:white;
        padding: 0.5rem 1.5rem;
        border-radius: 8px;
        box-shadow: 1px 2px 4px lightgray;
    }
    .optionContainer{
       
        border-radius: 12px;
    border: 1px solid lightgray;
    padding: 0.5rem;
    margin-top: 0.7rem;
 
    }
    .optionContainer input[type='checkbox']{
        opacity: 0;
    }
    .optionContainer input{
        outline: none;
        border: none;
    }
    .optionContainer.checkbox label::before{
        content: '';
        height:1.2rem;
        width: 1.2rem;
        border-radius: 50%;
        border:1px solid rgb(204, 204, 204);
    }
    .optionContainer.checkbox.checked label::before{
        background-color: rgb(71, 82, 141);
    }
    .optionContainer.checkbox.checked{
        border:1px solid rgb(71, 82, 141);
    }
    
    .optionContainer label{
       margin-bottom: 0;
        display: flex;
        width: 100%;
        align-items: center;
    }
    .opt_name{
        text-transform: capitalize;
        padding-left: 0.5rem;
        font-weight: 500;
    }
    .reportLoader lottie-player{
        width: 20%;
    }
 
    @media screen and (max-width:900px) {
        #smartReportMainContainer{
            width: 100%;
        }
        .disclaimerModal{
            width: 90%;
            
        }
        .reportLoader lottie-player{
            width: 50%;
        }
    }
    @media screen and (max-width:375px) {
        .getAllRecms{
            margin-bottom: 1rem;
            bottom: 0;
            padding: 0.8rem 1rem;
            font-size: smaller;
            width: fit-content;
        }
        .PatientInfo .greetingMsgBox{
            padding: 2rem 1rem;
            border-bottom-right-radius:80px;
        }
        #share-report-whatsapp{
            padding: 0.5rem;
            bottom: -2.8rem;
        }
    }
        






.faqHead{
    background-color: var(--primary-color-blue__dark);
    color: var(--primary-color-white);
}



.onefaq{
    padding: 1rem;
    border-bottom: 2px solid var(--primary-color-white);
    position: relative;
    z-index: 6;
}
.onefaq_question{
    width: 100%;
    font-weight: 500;
    font-size: 1.1rem;
    position: relative;
    padding-bottom: 1rem;
    cursor: pointer;
}
.onefaq_question:hover{
    font-weight: 700;
}
.onefaq_question::after{
    content: '+';
    position: absolute;
    right: 0;
    
}
.onefaq_question.open::after{
    content: '-';
    position: absolute;
    right: 0;
}
.faqHead{
    position: relative;
    height: 15rem;
}
.allFaqs img{
    position: absolute;
    top: 0;
    right: -6rem;
    height: 25rem;
    transform: translateY(-83.3%);
    z-index: 2;
}
.allFaqs{
    position: relative;
    background-color: rgba(255, 255, 255, 0.924);
    position: relative;
    z-index: 7;
    padding-top: 2rem;
    border-radius: 2px;
}



section#hero{
    display: grid;
    position: relative;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    color:var(--primary-color-white);
    background-color: var(--primary-color-blue__dark);
    background-repeat: no-repeat;
    background-position-x: -50%;
    background-position-y: 50%;
    height: 80vh;
    background-attachment: fixed;

}
section#hero::after{
    content: '';
    position: absolute;
    top:0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100vw;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(341.78deg, #131847 56.34%, #000749 67.57%, rgba(108, 97, 242, 0.89) 116.33%);
    opacity: 1;
}

section#hero >div{
    position: relative;
    z-index: 99;
}
#hero .all-links{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 2.5rem;
}
#hero .get-app-links,#hero .get-app-links *{
    display: flex;
    align-items: center;
    font-size: 2rem;
    color:white;
    gap: 0.5rem;
}
section#hero svg{
    pointer-events: none;
    fill: var(--primary-color-green);
    opacity: 0.2;
    position: absolute;
    left:-15%;
    bottom: 0;
    width: 60%;
    transform: scale(1.5);
}
section#hero img{
    pointer-events: none;
}
#hero .buynow{
    display: flex;
    align-items: center;
    gap:0.5rem;
   
    font-size: 1.5rem;
    padding-top: 1rem;
}
#hero .buynow i{
    border: 4px solid var(--primary-color-green);
    border-radius: 50%;
    font-size: 2rem;
}
#wellness-card-container{
    pointer-events: none;
    max-height: 26rem;
}
#urine-cup{
    max-height: 18rem;
}
#phone-scan,#take-control{
    max-height: 40rem;
}

/* arameters */
section#parameters{
    position: relative;
    z-index: 99;
    margin-top: 100vh;
  
    background: var(--primary-color-blue__dark);
    

}
section#parameters h1,section#parameters h2{
    color: var(--primary-color-white);
    padding-top: 2rem;
    text-align: center;
}
section#parameters h2{
    position: relative;
    padding-left: 1rem;
    padding-top: 0;
    color: var(--primary-color-green);
    

}
/* section#parameters h2::before{
    content: '';
    height: 100%;
    width: 0.5rem;
    position: absolute;
    top:0;
    left: 0rem;
    background-color: var(--primary-color-green);
} */
section#parameters svg{
    position: absolute;
    top:0rem;
    right: 7rem;
    height: 17rem;
    
}
section#parameters svg *{
    fill: var(--secondary-color-purple__dark)!important;
    opacity: 0.7;
}
.parameters-container{
    padding-top: 2rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    gap:2rem
}
.each-parameter{
    min-width: 10rem;
    background-color: #070c34;
    color: var(--secondary-color-purple__light);
    border-radius: 25px;
    padding: 1.8rem 3rem;
    font-size: 1.5rem;
    font-weight: 400;
    display: flex;
    justify-content: center;
    flex-direction: column;
   
}
.each-parameter__icon{
    height: 100%;
    width: 2rem;
}
section#parameters .heading{
    margin-bottom: 2rem;
    
}
.allParams{
    display: grid;
    grid-template-columns: 1fr  ;
    flex-grow: 1;
    margin-top: 1rem;
    grid-gap: 2rem ;
    font-size: 1.5rem;
   
}
.allParams>div{
    display: grid;
    grid-auto-rows: 1fr;
    justify-items: self-start;
    grid-gap: 0.5rem;
}
.wellness-card-pinned{
    position: relative;
    height: 30rem;
}
.wellness-card-pinned img{
    position: absolute;
    height: 100%;pointer-events: none;
}
img.card{
    background-color: transparent;
}
.allParams img.card{
    height: 100%;
    object-fit: contain;
    width: 100%;
}

.oneparam{
    display: flex;
    
}
.oneparam img{
    margin-right: 1rem;
    
}


/* how it works */
section#how_it_works{
    padding: 3rem 10rem;
    background-color: white;
    height: fit-content;
}
/* care kits */
section#care-kits{
    background-color: var(--primary-color-blue__dark);
    color: var(--primary-color-white)
}
.all-care-kits-wrapper{
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    padding-top: 2rem;
}
.one-care-kit{
    background-color: white;
    border-radius: 8px;
    padding: 2rem;
    color: var(--primary-color-blue);
    display: grid;
    grid-template-columns: 7rem auto;
    grid-auto-flow: column;
    gap: 2rem;
    align-items: center;
    min-height: 7rem;
}
.one-carekit__image{
    height: 7rem;
    width: 7rem;
    display: grid;
    justify-items: center;
    align-content: flex-end;
    border-radius: 50%;
    order: -3;
    overflow: visible;

}
.one-carekit__image img{
    max-height: 7rem;
    width: 100%;
    object-fit: cover;
    position: relative;
    bottom: -0.7rem;

}
.one-carekit__name{
    font-family: var(--primary-typeface);
    font-size: 2rem;
    font-weight: 700;
}

/* get app */
section#get_app{
    
    height: 70vh;
    display: grid;
   
    grid-template-columns: 1fr 1fr;
    background:var(--secondary-color-purple);
    color:white;
}

#get_app img.app-card-img{
    max-height:70vh;
    position: relative;
   
}
#get_app .app_btns{
    padding-top: 1rem;
    display: grid;
    gap:1.5rem;
    grid-template-columns: 1fr 1fr;
    align-items: center;
}
#get_app .app_btns>a img{
    max-width: 80%;
    /* max-width: 100%; */
    /* max-height: 83px; */
}
#get_app .app_btns>a>div{
    display: flex;
    align-items: center;
}
#get_app .app_btns *{
    color:black
}
#get_app .app_btns i{
    font-size: 1.8rem;
}


/* benefits */
section#benefits{
    background-color: var(--primary-color-blue__dark);
    color:var(--primary-color-white);
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    
}
.all-benefits-container{
    position: relative;
    display: grid;
    padding: 2rem 4rem;
    grid-template-columns: 15rem 15rem;
    grid-template-rows: 15rem  15rem;
    margin-left: auto;
    margin-right: auto;
    width: fit-content;
}
.all-benefits-container .neodocs-logo{
    position: absolute;
    
    top:50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.neodocs-logo img{
    height: 4rem;
}
.one-benefit{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
    justify-content: center;
    background-color: var(--secondary-color-blue__dark);
    border-radius: 50%;
    font-weight: 900;
}
.one-benefit img{
    height: 5rem!important;
}
.one-benefit{
    color:var(--primary-color-white)
}
.one-benefit__desc{
    display: none;
}




/* how it works */
section#how_it_works h1{
    text-align: center;
}
.hiwsteps{
    display: grid;
    margin-top: 2rem;
    padding: 2rem 15rem;
   
}
    

.hiw-onestep{
    display: grid;
    grid-template-columns: 1fr 0.5rem 1fr;
    grid-gap: 1rem;
    
}
.stepTitle{
    text-transform: uppercase;
    color:var(--primary-color-blue__dark)
}
.stepImage{
    width: 100%;
    height: 70%;
    margin-left: auto;
    margin-bottom: auto;
    margin-right: auto;
    object-fit: cover;
    font-size: 5rem;
    opacity: 0.3;}
    
.stepNumber{
    position: relative;
    
}
.step-path{
   
    width: 2px;
    background: black;
    position: absolute;
    left: 0.3rem;
}
.step-circle{
    position: absolute;
    top:0;
    left: 0;
    background-color: white ;
    border-radius: 50%;
    z-index: 3;
    width: 0.8rem;
    height: 0.8rem;
    border:3px solid #444444;
}
.stepNumber::before{
    content:'';
    
    width: 0.8rem;
    height: 0.8rem;
    opacity: 0.4;
    position: absolute;
    top:0;
    left: 0;
    background-color: #b1b1b1 ;
    border-radius: 50%;
    z-index: 3;
    border:3px solid #b8b8b8;
}
.stepNumber::after{
    content: '';
    position: absolute;
    height: 100%;
    /* width: 4px; */
    opacity: 0.3;
    border-right: 2px dashed #444444;
    left: 89%;
    transform: translateX(-50%);
    z-index: 2;
}
.step4 .stepNumber::after{
    opacity: 0;
}
.stepDesc{
    font-size: small;
    font-weight: 300;
    padding-bottom: 6rem;
    
}
.stepTitle{
    font-weight: 700;
    font-size: large;
    padding-bottom: 0.5rem;
    color: #0f151a;
}
#phone-mockup img{
    height: 25rem;

}

.wellness-card-intro{
   position: relative;
}
.wellness-card-intro h1{
    font-size: 5rem;
    
    color:var(--primary-color-blue)

}
.wellness-card-intro>div{
    display: flex;
    flex-direction: column;
    justify-content: center;
}
#hero .illus {
    position: relative;
}
#hero img{
    pointer-events: none;
    position: absolute;
    top: -10rem;
    /* left: 0; */
    height: 51vh;
    transform: rotate(30deg);
    /* transform: translateX(7%); */
    margin-bottom: 0px;
    right: 9rem;
}


@media screen and (max-width:500px) {
    
    
    section#hero{
        
        grid-template-columns: 1fr;
        justify-content: unset;
        padding-bottom: 0;
        align-items: start;
        background-position: center;
        height: 90vh;
    }

    section#hero .action-btn__primary{
        padding: 0.8rem 2rem;
        margin-top: 1rem;
    }
    #hero .all-links{
        margin-top: 1rem;
    }
    #hero .get-app-links,#hero .get-app-links *{
        display: none;
    }
    section#hero>div.illus{
        order:3;
        position:relative
    }
    
    
    
    
    #hero img{
        position: absolute;
        top: -13rem;
        /* left: 0; */
        height: 51vh;
        transform: rotate(30deg);
        /* transform: translateX(7%); */
        margin-bottom: 0px;
        right: 1rem;
    }
    
    #hero .app_links{
        display: none;
    }
    section#parameters svg{
        position: absolute;
        top:0rem;
        right: 0rem;
        height: 10rem;
    }
    .parameters-container{
        width: 100%;
    justify-content: center;
    display: grid;
    grid-template-columns: 1fr 1fr;
    
    }
    .each-parameter{
        padding: 1.5rem;
        font-weight: 200;
        font-size: 1.1rem;
    }
    
    
    section#how_it_works{
        padding: 3rem 1rem;
    }
    
    section#benefits{
        grid-template-columns: 1fr;
        align-items: unset;
    }
    
    section#get_app{
        height: fit-content;
        grid-gap: 1rem;
        grid-template-columns: 1fr;
        padding-bottom: 0;
    }

    #get_app img.app-card-img{
        height: 100%;
        width: 100%;
        object-fit: cover;
        margin-right: 1rem;
        top:0
    }
    
    #get_app h1{
        text-align: unset;
        
    }
    #get_app .desc{
        display: none;
        
    }
    #testimonials_grid {
        padding-top: 0rem;
        grid-template-rows: 0.5fr 1fr 0.5fr 0.5fr;
        margin-right: 0rem;
        margin-left: 0rem;
    }
    #testimonials_grid #join {
        grid-column: span 5;
        grid-row-start: 1;
    }
    #testimonials_grid #declaration {
        position: relative;
        background-color: unset;
        grid-column: span 5;
        grid-row-start: 2;
        width: 100%;
        z-index: 10;
        font-size: 15px;
    }
    #testimonials_grid #test2, #test8 {
        grid-column: span 2;
    }
    #testimonials_grid #declaration {
        position: relative;
        background-color: unset;
        grid-column: span 5;
        grid-row-start: 2;
        width: 100%;
        z-index: 10;
        font-size: 15px;
    }
    #testimonials_grid .actionBtn{
            background-color: white;
            color: black;
            margin-left: auto;
            margin-right: auto;
            border-radius: 25px;
            margin-bottom: 0rem;
            text-transform: uppercase;
            letter-spacing: 2px;
            width: 16rem;
        }
       
        .hiwsteps{
            padding: 0rem;
        }
        .stepDesc{
            padding-bottom: 5rem;
        }
        .neodocs_hero {
            width: unset;
            height: 55vh;
            margin-top: 15vh;
            position: relative;
            z-index: 10;
        }
        div.heading{
            order:-2;
        }
        #join > p{
            text-align: center;
        }
        /* benefits */
        .all-benefits-container {
           
            padding: 2rem 0rem;
            grid-template-columns: 10rem 10rem;
            grid-template-rows: 10rem 10rem;
        }
        .one-benefit p{
            font-size: 13px;
            font-family: var(--primary-typeface);
        }
        .one-benefit img {
            height: 3rem!important;
        }
        .neodocs-logo img{
            height: 3rem;
        }
        .wellness-card-intro h1{
            font-size: 3rem;
            
            
        
        }
        #wellness-card-container{
            
            max-height: 20rem;
        }
        #urine-cup{
            max-height: 15rem;
        }
        #phone-scan,#take-control{
            max-height: 30rem;
        }
        .all-care-kits-wrapper{
            grid-template-columns: 1fr;
        }
        .one-care-kit{
            padding: 1rem
        }
        .one-carekit__name{
            font-size: 1.4rem;
        }
    
}
@media screen and (max-width:320px) {
    #hero img{
        top:-7rem
    }
    .all-benefits-container {
        padding: 1rem 0rem;
        grid-template-columns: 8rem 8rem;
        grid-template-rows: 8rem 8rem;
    }
    .one-benefit img {
        height: 2.5rem!important;
    }
    #hero .buynow {
       
        font-size: 1.2rem;
        
    }
    #wellness-card-container{
        max-height: 15rem;
    }
    #urine-cup{
        max-height: 10rem;
    }
    #phone-scan, #take-control{
        max-height: 22rem;
    }
    #app-tour-container{
        grid-template-rows: 16rem auto
    }
    .app-tour__illustration > div{
        height: 16rem;
    }
}
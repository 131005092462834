.text-para{
    position: absolute;
    pointer-events: none;

    
    opacity: 0;
    color:white
}
#dip-text{
    
    top:7rem
}
#scan-text{
    
    top:10rem
}
#control-text{
    
    
    top:10rem
}
#app-tour-container{
    
    box-sizing: border-box;
    min-height: 100vh;
    background-color: var(--primary-color-blue__dark);
    color:white;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    overflow: hidden;
}
.app-tour__desc{
    position: relative;
    height: 100%;
    
}
#app-tour-container svg{
    fill: var(--primary-color-green);
    opacity: 0.1;
    pointer-events: none;
    position: absolute;
    height: 40rem;
    left: 6rem;
    bottom: 6rem;
}
.app-tour__desc .one-tour{
    position: absolute;
    top:0rem
}
.app-tour__desc .one-tour:nth-of-type(2){
    
    top:50%;
    transform: translateY(-50%);
}

.app-tour__desc .one-tour:nth-of-type(3){
    
    top:unset;
    bottom: 0;
}

.app-tour__illustration{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}
.app-tour__illustration > div{
    position: absolute;
    height: 40rem;
    
    color:black;
    display: flex;
    align-items: center;
    justify-content: center;
}
.one-tour__image img{
    height: 100%;
    object-fit: cover;
}
.one-tour__details .big-header-h1{
    padding-left: 20px;
    position: relative;
}
.one-tour__details .big-header-h1::before{
    content: '';
    position: absolute;
    left: 0;
    top:0;
    height: 100%;
    width: 7px;
    background-color: var(--primary-color-green);
}


@media screen and (max-width:700px) {
    
    
    #control-text,#scan-text,#dip-text{
   
        width: fit-content;
        left:50%;
        top:5rem;
        transform: translateX(-50%);
    }
    #control-text{
      
        text-align: center;
       
        
    ;
    }
    .one-tour__details .big-header-h1::before{
        display: none;
    }
    #app-tour-container{
        grid-template-columns: 1fr;
        grid-template-rows: 20rem auto;

    }
    .app-tour__illustration{
        height: 19rem;
    }
    .app-tour__illustration > div{
        height: 18rem;
    }
    .one-tour__details h1{
        font-size: 1.5rem;
    }
    .app-tour__desc{
        position: relative;
        height: 100%;
    }
    .app-tour__desc .one-tour{
        position: absolute;
        top:1rem;
        text-align: center;
    }
    .app-tour__desc .one-tour:nth-of-type(2){
    
        top:1rem;
        transform: unset;
    }
    
    .app-tour__desc .one-tour:nth-of-type(3){
        
        top:1rem;
        bottom: unset;
    }
}
@media screen and (max-width:320px) {
    .one-tour__details__desc{
        display: none;
    }
    .all-benefits-container {
        padding: 1rem 0rem;
        grid-template-columns: 7rem 7rem;
        grid-template-rows: 7rem 7rem;
    }
}
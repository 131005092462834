#loginContainer{
    display: flex;
    overflow: hidden;
    flex-direction: column;
    
    align-items: center;
    width: 100%;
    height: 100vh;
    background-color: white;
}
#loginContainer .headerWave{
    background-color: white;
    position: relative;
    height: 40vh;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    z-index: 3;
    text-align: start;
    padding: 2rem 7rem;
    margin-bottom: 2rem;
    width: 100%;
    background-color: var(--primary-color-blue__dark);
    color: var(--primary-color-white);
}
#loginContainer .headerWave lottie-player{
    width: fit-content;
    position: absolute;
    height: 12rem;
    right: 0;
    opacity: 0.9;
    bottom: 3rem;
    pointer-events: none;
    
}
#loginContainer .headerWave h1{
    position: relative;
    z-index: 6;
    text-align: start;
    
    font-weight: 800;
    
}
#loginContainer .headerWave svg{
    position: absolute;
    height: 90%;
    right: -2rem;
    opacity: 0.1;
}
#loginContainer .headerWave svg *{
    fill: white;
}
#loginContainer .action-btn__primary{
    
    width: 20rem;
    height: 3.3rem;
    margin-top: 1rem;
    box-shadow: 1px 1.2px 5px #dad9d9c2
}
input:focus{
    border: none;
    outline: none;
}
.login_labels{
    letter-spacing: 1px;
    margin-bottom: 3px;
    color:var(--primary-color-blue)
    
    
}
.otpInputContainer{
    width: 100%;
    justify-content: center;
    
}
.otpInputContainer .input-field{
    width: 3rem;
    padding: 0;
}

.loginInputsContainer{
    display:grid;
    width: 100%;
    justify-items: center;
    align-items: center;
}
.country-code{
    background-color: rgba(255,255,255,0.9);
    border: 2px solid lavender;

    width: 3rem;
    height: 3.4rem;
    border-radius: 12px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 0%;
    z-index: 3;
    font-weight: 800;
}
.input-field{
    
    width: 100%;
    background-color: #ebebf4;
    border: 0px solid transparent;
    
    height: 3.4rem;
    border-radius: 12px;
    
    padding: 0 3rem;
    
    position: relative;
    justify-self: center;
}
.otpInputContainer .input-field{
    width: 3rem !important;
    height: 3rem !important;
}
.input-field:focus{
    border: none;
}

@media screen and (max-width:1000px) {
    #loginContainer .headerWave{
        padding: 2rem;
    }
    .input-field{
        width: 100%;
    }
    
    .country-code{
        left: 0%;
    }
    .auth-registerBtn{
        width: 65%;
    }
}
#questionnaire-main-container{
    display: grid;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    width: 100vw;
    grid-template-columns: 1fr;
    background-color: var(--secondary-color-purple);
    color: white;
}
.questionnaire-main-slider{
    width: 50%;
    max-width: 50%;
    margin-left: auto;
    margin-right: auto;
}
.questionnaire-wrapper{
    display: none;
    padding: 4rem 1rem;
}
.questionnaire-loader{
    padding: 3rem 0rem;
    margin-right: auto;
    margin-left: auto;
    width: 50%;
}
.questionnaire-loader .user_details_form{
    display: grid;
    grid-gap: 2rem;
    gap: 2rem;
    /* grid-template-columns: 1fr 1fr; */
    width: 50%;
}
.questionnaire-loader #login-button{
    display: none;
}

.questionnaire-main-slider .optionContainer{
   
    background-color: white;
    color: var(--secondary-color-purple);
    font-weight: bold;
    border: none;
    padding: 0rem;
    margin-top: 1rem;
    transition: all 0.2s linear;
}
.questionnaire-main-slider .optionContainer label{
    padding: 1rem;
    cursor: pointer;
}
.questionnaire-main-slider .optionContainer input{
    opacity: 0;
    height: 0;
    width: 0;
}
.questionnaire-main-slider .optionContainer.checked{
    border:2px solid rgb(182, 182, 226)
}
.questionnaire-main-slider .optionContainer .opt_name{
    font-weight: 600;
}
.questionnaire-main-slider .questionButtons {
    display: flex;
    margin-top: 2rem;
    padding: 1rem 0;
    justify-content: space-between;
}
.questionslide_title{
    padding-bottom: 0;
}
.questionButtons div{
    background-color: var(--primary-color-green);
    box-shadow: none;
    border: none;
    color: var(--primary-color-blue);
}
.upload-report-whatsapp-link-container a{
    background-color: white;
    padding: 0.9rem;
    border-radius: 8px;
    margin-top: 1rem;
    font-weight: 700;
    display: block;
    color: var(--secondary-color-purple);
}
.questionContainer img{
    height:10rem;
    margin-right: auto;
    margin-left: auto;
}
.questionnaire-main-slider .optionContainer:hover{
    border:2px solid rgb(182, 182, 226)
}

.results-infographics{
    position: relative;
    padding-top: 4rem;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
}

.results-infographics #arrow{
    position: absolute;
    left: 0;
    top:0;
    width: 6rem;
    height: 6rem;
}
.results-infographics #linear-gauge{
    width: 100%;
}
.user_details_form .otpInputContainer {
    justify-content: space-between;
}
.result-texts{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 0.5rem;
    font-size: smaller;

}
.result-texts >div{
    padding-left: 0.25rem;
    
}

@media screen and (max-width:700px) {
    .questionnaire-main-slider{
        width: 100%;
        max-width: 100%;
    }
    .questionnaire-loader{
        padding: 3rem 1rem;
        margin-right: auto;
        margin-left: auto;
        width: 100%;
  
    }
    .questionnaire-loader .user_details_form{
        grid-template-columns: 1fr;
        gap: 1rem;
        width: 100%;
    }
    
    .results-infographics{
        padding-top: 2rem;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
    }
    .results-infographics #arrow {
      
        width: 3rem;
        height: 3rem;
    }
}

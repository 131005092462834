.privacyhero{
    min-height: 70vh;
    display: flex;
    
    background-color: var(--primary-color-blue__dark);
    position: relative;
    color:var(--primary-color-white)
    
}


.privacyhero>div{
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 10;
  
    
}


.privacyhero .info{
    padding-right: 3rem;
}
.control{
 
    display: flex;
    align-items: center;
    background-color:white;
    gap:2rem
}

.control h2{
    color: #1d1d47;
    font-weight: 700;
    font-size: 50px;
    padding-bottom: 0.5rem;
}
.control .desc{
    font-size: 25px;
}
.one_check{
    font-size: 18px;
    font-weight: 300;
    padding-top: 1rem;
}
.one_check::before{
    content: "\ecab";
    font-family: 'boxicons'!important;
    font-size: 2rem;
    padding-right: 0.3rem;
    color: rgb(139, 194, 139);
    position: relative;
    top:0.4rem;
}

@media screen and (max-width:1000px) {
    .privacyhero{
        flex-direction: column;
        padding-top: 5rem;
        
        justify-content: center;
        align-items: center;
        text-align: start;
        padding: 2rem;
    }
    .privacyhero h1{
        font-size: 45px;
        line-height: 55px;
        text-align: start;
    }
    .privacyhero h5{
        width: 100%;
        font-size: 1rem;
        font-weight: 400;
    }
    .privacyhero .info{
        padding-right: 0;
    }
    .privacyhero svg{
        width: 100%;
    }
    .control{
     
        flex-direction: column;
    }
    .control>div{
        padding: 0;
    }
    .control h2{
        font-size: 45px;
    }
    .control .desc{
        font-size: 20px;
    }
}
@media screen and (max-width:500px) {
    .privacyhero{
        height: 85vh;
    }
}
.error{
    display: grid;
    grid-template-columns: 1fr 1fr;
    height: fit-content;
    min-height: 100vh;
    justify-items: center;
  
}
.error .big-header-h1{
    color:var(--primary-color-blue)
}
 .logoheader{
    width: 50%;
}
.infographic{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: var(--secondary-color-purple__light);
    position: relative;
}
/* .infographic::after{
    content: '';
    position: absolute;
    top:0;
    left: 0;
    right: 0;
    bottom: 0;
   
} */
.error .infographic img{
    height: 100%;
   
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    object-fit: contain;
    position: relative;
    z-index: 5;
}
.error h2{
    margin-bottom: 1rem;
}
.linksContainer a{
    display: block;
    color: var(--primary-color-blue);
    font-weight: 600;
    margin-top: 0.5rem;
    font-size: 1.2rem;
}
.linksContainer i.bx{
    color: var(--primary-color-blue);
    padding-right: 0;

}
.textContainer{
    text-align: start;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    position: relative;
    padding-left: 2rem;
    
}
.error svg{
    display: none;
}
.error h2{
    font-size: 2.8rem;
}
.error p{
    font-size: 1.2rem;
}

@media screen and (max-width:800px) {
    .error{
        grid-template-columns: 1fr;
        grid-template-rows: 0.7fr 1fr;
    }
    .logoheader{
        width: 100%;
    }
    .textContainer{
        justify-content: unset;
        padding-top: 2rem;
        text-align: center;
        
    }
    .error svg{
        display: block;
        position: absolute;
        height: unset;
        top:0;
        left: 0;
        transform: rotate(0deg);
    }
    .error h2{
        font-size: 1.8rem;
    }
}
.common-plans {
    background-color: var(--primary-color-blue__dark);
    color: var(--primary-color-white);
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.common-plans svg{
    position: absolute;
    top:-10rem;
    left:-7rem;
    opacity: 0.3;
    height: 25rem;
    pointer-events: none;
}
#products-pricing svg *{
    stroke: var(--secondary-color-blue__dark);
    fill: var(--secondary-color-blue__dark);
}
.common-plans>h1{
    position: relative;
    z-index: 9;
}

.all-plans-container {
  
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
    min-height:26rem
   
    
    
    
}
.plan_card{
    border-radius: 10px;
    border:1px solid var(--secondary-color-blue__dark);
    background-color:var(--primary-color-blue__dark);
    text-align: center;
    height: 85% ;
    display: grid;
    align-items: center;
    
    position: relative;
    
}
.plan_card::before{
    content:"Sold Out";
    border-radius: 10px;
    position: absolute;
    background-color: rgba(0,0,0,0.6);
    font-size: 1.5rem;
    top:0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    color:rgb(234, 149, 149);
    z-index: 88;
    font-weight: 700;

}
.plan_card a{
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    pointer-events: none;
}
.plan_card:nth-child(2){
    height: 100%;
    box-shadow: 1px 2px 16px rgb(37, 37, 37);
    border: 3px solid var(--secondary-color-blue__dark);
}


.planHead {
    position: relative;
    height: fit-content;
    padding: 1rem 2rem;
    
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    
}

p.price span {
    font-size: 2.3rem;
    letter-spacing: 0;
    font-weight: 800;
    color: var(--secondary-color-purple);
}



.features{
    background-color: #141A54;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    padding: 1rem 0rem;
    font-size: small;
}
.featurelistitem i{
    color:var(--primary-color-green);
    font-size: 2rem;
    
}
.featurelistitem p{
    font-size: small!important;
    font-weight: 300;
}
p.price span.small-note{
    font-weight: 300;
    font-size: x-small;

}
.popup-notify{
    position: relative;
    cursor: pointer;
}
.popup-notify::after{
    content: '';
}
.popup-notify:hover::after{
    content:'The current product is in beta and available for research use only. This product is not intended for use in diagnosing diseases or other conditions;  determining the state of health;  or curing, mitigating, treating, or preventing disease.';
    position: absolute;
    width: 20vw;
    padding: 0.5rem;
    border: 1px solid black;
    top:1rem;
    left:-3rem;
    background-color: white;
    color:black;
    z-index: 99;
}

.take-quiz-container{
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 6rem;
    align-items: center;
}
.take-quiz-container img{
    width: 100%;
    height: 50vh;
    object-fit: cover;
}
#products-pricing{
    background: var(--primary-color-blue__dark);
    position: relative;
    color: var(--primary-color-white);
    padding: var(--padding-tb__desktop) 0;
    padding-bottom: 0;
}
#products-pricing>div{
    padding: 1rem var(--padding-lr__desktop) ;
}
#products-pricing svg{
    position: absolute;
    top:0;
    right: 0;
    height: 18rem;
    opacity: 0.3;
    z-index: 3;
}


.all-products-container{
    display: grid;
    background-color: white;
    grid-template-columns: 1fr 1fr;
    position: relative;
    padding: 3rem 12rem !important; 
    gap: 3rem;
    
    
}

.all-benefits>div{
    
    display: flex;
    padding: 3rem 0rem;
}
.one-included{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    text-align: center;
    
}
.one-included img{
    height: 4rem;
}
.each-product{
    display: grid;
    grid-template-columns: 4rem auto;
    gap: 2rem;
    border-radius: 15px;
    align-items: center;
    cursor: pointer;
    padding:3rem 2rem;

}

.each-product__img{
    height: 4rem;
}
.each-product:hover .each-product__img{
    transform: scale(1.1);
    transition: all 0.2s ease;
}

.each-product__desc{
    font-size: 2rem;
    color: white;
    font-family: var(--primary-typeface);
    font-weight: 600;
}
.each-product__read-more,.each-product__buynow{
    cursor: pointer;
    color: white;
    font-weight: 600;
    display: block;
}
.each-product__buynow{
   
    color: var(--primary-color-green);
    width: fit-content;
    border-radius: 5px;
    
}
.each-product__read-more:hover,.each-product__buynow:hover{
    color:var(--primary-color-green)
}

.buynow-links{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0.5rem;
    align-items: center;
    padding-top: 1rem;
}

@media screen and (max-width: 500px){
    
    .all-plans-container{
        
        width: 100%;
        grid-template-columns: 1fr;
        height: fit-content;
      
    }
    .plan_card{
        height: fit-content;
        position: relative;
    }
    .plantitle{
        font-weight: bold;
    }
    p.price span{
        display: block;
        font-size: 2rem;
    }
    p.price .cancelled-price{
        display: inline;
        font-size: small!important;
    }
    .plan_card a{
        position: absolute;
        top:0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
    }
    .plan_card a:hover{
        opacity: 0;
    }
    .small_note{
        font-size: small;
    }
    .planHead{
        display: flex;
        padding: 1rem;
        justify-content: space-between;
        align-items: center;
    }
    .features{
        display: none;
    }
    .plan_card{
        width: 100%;
    }
    .take-quiz-container{
   
        gap: 1rem;
        grid-template-columns: 45% 50%;
    }
    p.bestseller{
        font-weight: 500!important;
    color: black!important;
    position: absolute;
    top: 0;
    left: 0;
    padding: 0 1rem;
    border-top-left-radius: 8px;
    background: var(--primary-color-green);
    /* font-size: 1.5rem; */
    border-bottom-right-radius: 8px;
    }
    .all-products-container{
        
        display: grid;
        grid-template-columns: 1fr;
        position: relative;
        gap:1rem;
        padding: 2rem 1rem !important;
    
    }
    .each-product{
        padding:  1rem;
    }
    .each-product__img{
        height: 3rem;
    }
    .each-product__desc{
        font-size: 1.2rem;
    }
    #products-pricing svg {
        height: 10rem;
        
    }
    .all-benefits>div{
        flex-direction: column;
        gap: 1rem;
        padding: 1rem
        
    }
    .one-included{
        display: grid;
        grid-template-columns: 2rem auto;
        text-align: start;
        justify-content: start;
        
        align-items: center;
        
    }
    .one-included img{
        height: 2rem;
    }
}

.profileHead{
    font-weight: 400;
    padding-bottom: 0.5rem;
    color: #323248c7;
    text-transform: uppercase;
    font-size: small !important;
    letter-spacing: 1px;
  
}


.profileDetails{
    padding: 1rem;
    background-color: rgb(215 215 215 / 32%);
    margin-bottom: 1rem;
}
.user-profile-container{
    padding: 0;
    padding-top: 4rem;
    min-height: 100vh;
}
.user-profile-container>div{
    padding: 2rem 7rem;
}
.editprofile-form{
    position: absolute;
    background-color: lavender;
    top:10vh;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 400;
    overflow: hidden;
}

.editprofile-form img{
    position: absolute;
    border: -15rem;
    
    bottom: -38px;
    height: 24rem;
    left: 0;
}
.editProfile_labels{
    text-align: start;
    display: block!important;
    margin-bottom: 0px;
    letter-spacing: 1px;
    padding-left: 0.3rem;
}

.profileDetails p:not(.profileHead){
    font-weight: 500;
    padding-bottom: 0.5rem;
}
.onegoal i{
    font-size: 5px;
}
.allInputsContainer{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;
}
.allInputsContainer .input-field{
    width: 100%;
}
.allInputsContainer>div.fullColumn{
    grid-column: 1 / 3;
}
/* services */
.services-container{
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 1rem;
}
.services-container .service{
    background-color: rgba(255,255,255,0.8);
    padding: 1rem;
    box-shadow: 1px 2px 16px rgb(224 224 224 / 90%);
    border-radius: 8px;
    color: #0c0b20;
}
.service h5{
    font-weight: 900;
    text-transform: uppercase;
    font-size: 1rem;
    letter-spacing: 1px;
    line-height: 1.5rem;
}
.service.drug-interaction-checker{
    background-color: #dadaf8;
}
.service.pdf-analyzer{
    background-color: #f0d0b4;
}
.service p{
    font-size: small!important;
}
@media screen and (max-width:900px) {
    .user-profile-container>div{
        padding: 1rem;
    }
}
#website-footer{
    background-color: var(--primary-color-blue__dark);
    padding: 6rem var(--padding-lr__desktop);
    color:rgb(216, 215, 215);
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 1fr 1fr 1fr 1fr ;
    padding-bottom: 3rem;
    height: fit-content;
    font-family: var(--primary-typeface);
    
}
/* #website-footer>div:nth-child(2){
    margin-left: auto;
    display: grid;
    row-gap: 2rem;
} */
#website-footer a{
    color: rgb(216, 215, 215) !important;
    font-weight: 200;
}
.footer-section-title{
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: 900;
    color:rgb(216, 215, 215)
}
#footer-company-name img{
    height: 2rem;
    margin-bottom: 1rem;
}
#footer-company-address{
    
    letter-spacing: 1px;
}
.footer-section-list{
    display: grid;
    grid-auto-flow: row;
    grid-gap: 0.2rem;
    
    padding-top: 0.5rem;
    letter-spacing: 1px;
}
.footer-section-list a:hover{
    text-decoration: none;
    color:rgb(216, 215, 215)
}
#copyright-para{
    grid-row-start: 2 ;
    grid-column: 1 / 5;
    padding-top: 2rem;
}
#copyright-para p{
    font-size: small !important;
    padding-top: 4rem;
}
@media screen and (max-width:900px){
    #website-footer{
        padding: 2rem var(--padding-lr__mobile);
    }
}

@media screen and (max-width:500px) {
    #website-footer{
        grid-template-columns: 1fr;
        grid-auto-flow: row;
        padding: 2rem;
        grid-gap: 1rem;
    }
    #website-footer>div:nth-child(2){
        margin-left: unset;
    }
    .footer-section-list, #footer-company-address{
        grid-gap: 0.2rem;
        font-size:smaller;
    }
    #copyright-para{
        grid-row-start: 5 ;
        grid-column: 1 ;
        padding-top: 0;
    }
}
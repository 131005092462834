.up-hero{
    background-color: var(--secondary-color-purple__dark);
    color: var(--primary-color-white);
    display: grid;
    align-items: center;
    gap: 2rem;
    padding-bottom: 0;
    
}
.up-hero .big-header-h2{
    font-size: 2rem;
}
.up-introdesc{
    min-height: 70vh;
    background-color: var(--primary-color-blue__dark);
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
}
.up-introdesc .big-header-h1{
    color: var(--primary-color-green);
}
.up-introdesc img{
    max-width: 100%;
 
}
.up-short-desc{
    width: 70%;
    padding-top: 1rem;
    font-weight: 500;
    color: var(--primary-color-white);
}
.up-short-desc strong{
    color: white;
    font-weight: 700;
}
.up-steps{
    
    background-color: #F4F4F4;
    max-height: fit-content;
}
.up-testkit{
    background-color: var(--secondary-color-purple);
    color: white;
}
.up-testkit img{
    width: 100%;
    max-height: 70vh;
}
.up-each-step{
    display: grid;
    justify-items: center;
}
.up-steps{
    
    display: grid;
    
    
}
.up-steps-desc{
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    gap: 3rem;
    padding-top: 2rem;
}
.up-each-step .step-no{
    font-size:3rem;
    font-weight: 700;
    opacity: 0.5;
    color: var(--secondary-color-blue__dark);
}
.up-each-step .step-desc{
    font-size: 1.5rem;
    text-align: center;
    color: var(--primary-color-blue__dark);
}
.one-step .step-no{
    
    font-size: 2rem;
    font-weight: 700;
}
.one-step .step-desc{
    font-size: 1.5rem;
    
}
.up-meta-data{
    background-color: var(--primary-color-blue__dark);
    display: grid;
    grid-auto-flow: column;
    justify-content: space-between;
    gap: 2rem;
    color: var(--primary-color-white);
}
.up-meta-data__title{
    font-size: smaller;
    text-transform: uppercase;
    font-weight: 600;
}
.up-meta-data__desc{
    color: white;
}

@media screen and (max-width:450px) {
    .up-steps-container{
        grid-template-columns: 1fr;
    }

    .up-each-step .step-desc{
        font-size: 1.2rem;
    }
    .up-introdesc{
        
        grid-template-columns: 1fr;
        justify-content: center;
    }
    .up-introdesc img{
        order: -2;
        padding-bottom: 1rem;
    }
    .up-meta-data{
        grid-auto-flow: row;
    }
    .up-short-desc{
        width: 100%;}
        .up-steps{
            max-height: fit-content;
        }
        
        .up-steps-desc{

            display: grid;
            grid-template-columns: 1fr;
            width: 100%;
            min-height: 100vh;
            height: fit-content;
        }
}
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Sen:wght@400;700&display=swap');
#recipe-blog-container{
    background-color: white;
    height: fit-content;
    max-height: fit-content;
    padding: 0;
    padding-top: 0;
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    
    letter-spacing: 1px;
    
}
.head-4{
    padding-top: 1rem;
}
.ingredients{
    display: flex;
    flex-direction: column;
}
.section-ul{
    list-style: disc;
    padding-top: 0;
   
}
.section-li{
    display: list-item;
    
}
.privacy-heading{
    background-color : var(--primary-color-blue__dark);
    color:var(--primary-color-white);
    position: relative;
}
.privacy-heading svg{
    height: 100%;
    position: absolute;
    right:7rem;
    top:0rem;
    opacity: 0.2;
}


ul.information-ul{
    list-style:disc !important;
    
}
.part-heading{
    padding-top: 2rem;
}
.privacy-container{
    padding-top: 2rem;
    padding-bottom: 2rem;
}

#blog-domain-container{
    
    font-family: 'Metropolis';
  
}
#blog-domain-container h1,h2,h3,h4,h5{
    font-family: 'Manrope';
}



/* general */

.recipe-ad-conatiner{
    padding-top: 2rem;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    position: relative;
}
.recipe-ad-conatiner .blog_data{
    position: absolute;
    
    padding: 1rem;
   
    padding-bottom: 2rem;
    bottom: 0;
    left: 7rem;
    
}
.recipe-ad-conatiner img{
    height: 50vh;
    width: 100%;
    object-fit: cover;
}
.blog-header{
    color:var(--primary-color-blue__dark);
    padding-left: 0.5rem;
    border-left: 0.5rem solid var(--secondary-color-purple__light);
   
    
}

.categories-container{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    letter-spacing: 2px;
    font-weight: 900;
}
.categories-container img{
    height: 3rem;
    display: none;
    
}
.categories-container .category{
    padding: 0.5rem;
   
    margin-right: 1rem;
    margin-bottom: 1rem;
}
.categories-container h3{
    font-size: 20px;
    font-weight: 600;
    
    text-transform: uppercase;
    color: var(--primary-color-blue);
}
.blog-no{
    font-size: small;
    color: gray;
    margin-bottom: auto;
    padding-right: 1rem;
}

/* popular blogs */
.popular-blogs{
    padding: 0rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
    
}
.popular-blogs img{
 height: 18rem;
 width: 100%;
 object-fit:cover
}
.popular-blogs .blogs-container{
    display: grid;
    grid-gap: 2rem;
    grid-template-columns: 1fr 1fr 1fr;
}

.blogs-container > a:first-child img{
    max-height: 20rem;
    
}
.blog_image{
    overflow: hidden;
    
}
.blog-card:hover .blog_image img{
    transform: scale(1.1);
    transition: all 0.5s ease;
}

.recipe-hero-img{
    position:relative;
}
.recipe-ad-conatiner .blog_data{
    z-index:4;
    color:white;
    left:7rem;

}
.recipe-hero-img::after{
    content:'';
    position:absolute;
    top:0;
    bottom:0;
    left:0;
    right:0;
    background:linear-gradient(to bottom, transparent, black);
    z-index:2
}
.recipe-ad-conatiner{
    padding-left: 0rem!important;
    padding-right: 0rem!important;
    
}
.recipe-ad-conatiner .blog-headers{
    width: 60%;
    opacity: 0.7;
    padding-bottom: 1rem;
}
.recipe-ad-conatiner a{
    font-size: 1rem;
   
    opacity: 0.9;
    text-transform: uppercase;
    
}

a.category:hover h3{
    color:var(--primary-color-green)
}
section.all-categories{
    padding-bottom: 1rem;
}
@media screen and (max-width:500px) {
    
    
    .blog-headers{
        font-size: 3rem;
    }
    
    
    .popular-blogs .blogs-container{
        grid-template-columns: 1fr;
    }
    .popular-blogs img{
        width: 100%;
    }
    
    .recipe-ad-conatiner .blog_data{
        
        left:1rem;
        padding-bottom: 2rem;
    
    }
    .recipe-ad-conatiner .blog-headers{
        width: 100%;
        font-size: 2.2rem;
        opacity: 0.7;
        padding-bottom: 0.5rem;
    }
    .all-category-blogs{
        grid-gap: 1rem;
    }
    .blogs-container > a:first-child{
        grid-row: 1 / 1 !important;
        grid-column: 1 / 1 !important;
        height: 100%;
    }
    .all-category-blogs > a:first-child{
        grid-row: 1 / 1 !important;
        grid-column: 1 / 1 !important;
        height: 100%;
    }
    
}